import { isEmpty } from 'lodash';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { isUrl } from '../../../utils/utils';
import CustomisableButton from '../../CustomisableButton/CustomisableButton';
import Input from '../../Input/Input';
import Modal from '../../Modal/Modal';

interface AddImageModalProps {
  errorMessage: string | undefined;
  handleSubmitNewImage: () => void;
  imageUrl: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
  setImageUrl: React.Dispatch<React.SetStateAction<string>>;
  setIsModalShowing: React.Dispatch<React.SetStateAction<boolean>>;
}

const ImageUrlForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .button-group-wrapper {
    display: flex;
    margin-top: 15px;
  }
  .button-wrapper {
    margin-right: 20px;
  }
`;

const AddImageModal: FunctionComponent<AddImageModalProps> = ({
  errorMessage,
  handleSubmitNewImage,
  imageUrl,
  setErrorMessage,
  setImageUrl,
  setIsModalShowing,
}) => {
  const handleClose = () => {
    setIsModalShowing(false);
    setErrorMessage(undefined);
    setImageUrl('');
  };
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageUrl(event.target.value);
  };

  return (
    <Modal isOpen handleClose={handleClose}>
      <ImageUrlForm>
        <Input
          hasError={isUrl(imageUrl)}
          errorMessage={errorMessage}
          label="Enter an image URL:"
          type="text"
          id="image-input"
          onChange={handleOnChange}
          value={imageUrl}
        />
        <div className="button-group-wrapper">
          <div className="button-wrapper">
            <CustomisableButton isSecondary onClick={handleClose}>
              Cancel
            </CustomisableButton>
          </div>
          <CustomisableButton
            disabled={isEmpty(imageUrl)}
            onClick={handleSubmitNewImage}
          >
            Submit
          </CustomisableButton>
        </div>
      </ImageUrlForm>
    </Modal>
  );
};

export default AddImageModal;

import React, { useEffect, useState } from 'react';
import logoIsotype from '../../assets/trustflight-isotype.svg';
import logoText from '../../assets/trustflight-text.svg';
import iconExpand from '../../assets/icon-expand.svg';
import iconMenu from '../../assets/icon-menu.svg';
import iconClose from '../../assets/icon-close.svg';
import {
  ComponentWrapper,
  ContentWrapper,
  ExpandButton,
  ExpandIcon,
  ExpandWrapper,
  List,
  LogoWrapper,
  MobileExpandButton,
  MobileExpandIcon,
  MobileExpandWrapper,
  Nav,
  NavWrapper,
  TrustflightLogo,
  TrustflightText,
} from './SideNavStyles';
import Item from './Item';

export type MenuItem = {
  label: string;
  value: string;
  imgSrc?: string;
  items?: MenuItem[];
};
export interface SideNavProps {
  selected?: MenuItem;
  expanded?: boolean;
  items: MenuItem[];
  onSelect: (item: MenuItem) => void;
}

export const SideNav: React.FC<SideNavProps> = ({
  selected,
  items,
  onSelect,
  expanded = window.innerWidth > 815,
  children,
}) => {
  const [navIsExpanded, setNavExpanded] = useState<boolean>(expanded);
  const [itemsExpanded, setItemsExpanded] = useState<Set<MenuItem>>(new Set());
  const [itemSelected, setItemSelected] = useState<MenuItem>();
  const [mobile, setMobile] = useState<boolean>();

  useEffect(() => {
    setItemSelected(selected);
  }, [selected]);

  const isMobile = () => {
    setMobile(window.innerWidth < 815);
    return window.innerWidth < 815;
  };

  useEffect(() => {
    setMobile(isMobile());
    window.addEventListener('resize', isMobile);
    return function cleanup() {
      window.removeEventListener('resize', isMobile);
    };
  }, []);

  const selectItem = (item: MenuItem) => {
    if (!navIsExpanded) {
      setItemsExpanded(new Set());
    } else {
      setItemsExpanded(
        new Set(
          items.filter((parentItem: MenuItem) =>
            parentItem.items?.includes(item)
          )
        )
      );
    }
    if (mobile) {
      setNavExpanded(!navIsExpanded);
    }
    setItemSelected(item);
    onSelect(item);
  };

  const expandItemInExpandedNav = (item: MenuItem) => {
    if (itemsExpanded.size === 0) {
      setItemsExpanded(new Set([item])); // menu empty so just expand
    } else if (itemsExpanded.has(item) && itemsExpanded.size === 1) {
      setItemsExpanded(new Set()); // item is only thing in list so contract
    } else if (itemsExpanded.size === 1 && itemSelected) {
      const selectedItemsParent = items.find((parentItem: MenuItem) =>
        parentItem.items?.includes(itemSelected)
      );
      if (selectedItemsParent && itemsExpanded.has(selectedItemsParent)) {
        setItemsExpanded(new Set([selectedItemsParent, item]));
      } else {
        setItemsExpanded(new Set([item]));
      }
    } else if (itemsExpanded.size === 1 && !itemSelected) {
      // current expansion doesnt contain selected item so just swap
      setItemsExpanded(new Set([item]));
    } else if (itemsExpanded.size === 2 && itemsExpanded.has(item)) {
      // two things expanded including this, so just contract this
      const newItemsExpanded = Array.from(itemsExpanded).filter(
        (expandedItem) => expandedItem !== item
      );
      setItemsExpanded(new Set(newItemsExpanded));
    } else if (
      itemsExpanded.size === 2 &&
      !itemsExpanded.has(item) &&
      itemSelected
    ) {
      // something else and selection parent expanded
      const selectedItemsParent = items.filter((parentItem: MenuItem) =>
        parentItem.items?.includes(itemSelected)
      )[0];
      setItemsExpanded(new Set([selectedItemsParent, item]));
    }
  };

  const expandItemInCollapsedNav = (item: MenuItem) => {
    if (itemsExpanded.size === 0) {
      setItemsExpanded(new Set([item])); // menu empty so just expand
    } else if (itemsExpanded.has(item) && itemsExpanded.size === 1) {
      setItemsExpanded(new Set()); // item is only thing in list so contract
    } else {
      // current expansion doesnt contain selected item so just swap
      setItemsExpanded(new Set([item]));
    }
  };

  const onExpandButtonClick = () => {
    if (navIsExpanded && itemSelected) {
      // leave expanded only item that has the selected subitem
      setItemsExpanded(
        new Set(
          items.filter((item: MenuItem) => item.items?.includes(itemSelected))
        )
      );
    }

    if (navIsExpanded) {
      setItemsExpanded(new Set());
    }

    setNavExpanded(!navIsExpanded);
  };

  return (
    <ComponentWrapper
      onClick={() => {
        if (!navIsExpanded) {
          setItemsExpanded(new Set());
        }
      }}
    >
      <NavWrapper data-testid="navWrapper">
        <LogoWrapper expanded={navIsExpanded} data-testid="logoWrapper">
          <TrustflightLogo
            src={logoIsotype}
            alt="trustflight planes"
            expanded={navIsExpanded}
            data-testid="trustflightLogo"
          />
          <TrustflightText
            src={logoText}
            alt="trustflight logo"
            expanded={navIsExpanded}
            data-testid="trustflightText"
          />
          <MobileExpandWrapper data-testid="mobileExpandWrapper">
            <MobileExpandButton
              type="button"
              onClick={onExpandButtonClick}
              data-testid="mobileExpandButton"
            >
              <MobileExpandIcon
                expanded={navIsExpanded}
                src={navIsExpanded ? iconClose : iconMenu}
                alt="expand icon"
                data-testid="mobileExpandIcon"
              />
            </MobileExpandButton>
          </MobileExpandWrapper>
        </LogoWrapper>
        <Nav
          expanded={navIsExpanded}
          onClick={(e): void => e.stopPropagation()}
          data-testid="nav"
        >
          <List expanded={navIsExpanded} data-testid="list">
            {items.map((item: MenuItem) => (
              <Item
                key={`item-${item.value}`}
                item={item}
                navIsExpanded={navIsExpanded}
                itemsExpanded={itemsExpanded}
                itemSelected={itemSelected}
                selectItem={selectItem}
                expandItemInExpandedNav={expandItemInExpandedNav}
                expandItemInCollapsedNav={expandItemInCollapsedNav}
              />
            ))}
          </List>
        </Nav>
        <ExpandWrapper data-testid="expandWrapper" expanded={navIsExpanded}>
          <ExpandButton
            type="button"
            onClick={onExpandButtonClick}
            data-testid="expandButton"
          >
            <ExpandIcon
              expanded={navIsExpanded}
              src={iconExpand}
              alt="expand icon"
              data-testid="expandIcon"
            />
          </ExpandButton>
        </ExpandWrapper>
      </NavWrapper>
      <ContentWrapper expanded={navIsExpanded && mobile}>
        {children}
      </ContentWrapper>
    </ComponentWrapper>
  );
};

export default SideNav;

import _ from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Editor, Path, Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import activeIcon from '../../../assets/RichTeIcons/icon-add-image-active.svg';
import inactiveIcon from '../../../assets/RichTeIcons/icon-add-image.svg';
import { isUrl } from '../../../utils/utils';
import type { MenuItem } from '../../ThreeDotMenu/ThreeDotMenu';
import {
  ToolButton,
  ToolButtonIcon,
  ToolButtonWrapper,
} from '../ToolBarStyles';
import ToolTip from '../ToolTip';
import { useRTEContext } from '../RTEContext';
import type { ImageMetadata } from './Image';
import AddImageMenu from './AddImageMenu';
import AddImageModal from './AddImageModal';
import handlePortalMenuKeyboardNav from './handlePortalMenuKeyboardNav';
import imageExtensions from './imageExtensions.json';
import ImageMenuOption from './ImageMenuOption';

interface EmptyText {
  text: string;
}
export interface ImageElement {
  type: 'image';
  description?: string;
  alt?: string;
  src?: string;
  children: EmptyText[];
}
export interface ImageReferenceElement {
  type: 'image-reference';
  src: string;
  fileName: string;
  description?: string;
  children: EmptyText[];
}
export interface CreateImageNodeArgs {
  alt: string;
  description?: string;
  src: string;
  children: any;
}
interface AddImageButtonProps {
  images: ImageMetadata[] | undefined;
  withImageReferences: boolean;
}

export const isImageUrl = (url: string): boolean => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split('.').pop() as string;
  return imageExtensions.includes(ext);
};

export const emptyText = {
  text: '',
};

const AddImageButton = ({
  images,
  withImageReferences,
}: AddImageButtonProps): JSX.Element => {
  const [activeItem, setActiveItem] = useState<number | null | undefined>();
  const [tooltipIsVisible, setTooltipIsVisible] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [mouseIsInsideMenu, setMouseIsInsideMenu] = useState<boolean>(false);
  const [previousCursorPosition] = useState<Path | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<undefined | string>(
    undefined
  );
  const editor = useSlateStatic();
  const parentRef = useRef<HTMLButtonElement>(null);
  const { state: RTEState } = useRTEContext();
  const { userDisabled: editDisabled } = RTEState;

  const createImageNode = useCallback(
    ({
      alt = 'Image',
      description,
      src,
    }: CreateImageNodeArgs): ImageElement[] => [
      {
        alt,
        description,
        src,
        type: 'image',
        children: [emptyText],
      },
    ],
    []
  );

  interface InsertNodeArgs {
    description?: string;
    fileName: string;
    src: string;
  }
  const createImageReferenceNode = useCallback(
    ({
      description,
      fileName,
      src,
    }: InsertNodeArgs): ImageReferenceElement[] => [
      {
        src,
        fileName,
        type: 'image-reference',
        description,
        children: [
          {
            text: `Figure: ${_.truncate(fileName, {
              length: 15,
            })}`,
          },
        ],
      },
    ],
    []
  );

  const insertNode = useCallback(
    ({ description, fileName, src }: InsertNodeArgs) => {
      if (!src) return;

      const node = withImageReferences
        ? createImageReferenceNode({ description, fileName, src })
        : createImageNode({
            alt: description || `Image of: ${fileName}`,
            description,
            src,
            children: [{ text: '' }],
          });

      ReactEditor.focus(editor);

      if (previousCursorPosition) {
        const [, parentPath] = Editor.parent(editor, previousCursorPosition);

        Transforms.insertNodes(editor, node, {
          at: parentPath,
          select: true,
        });
      } else {
        // Insert the new node at the bottom of the Editor when
        // previousCursorPosition is falsy
        Transforms.insertNodes(editor, node, { select: true });
      }

      if (isMenuOpen) {
        setIsMenuOpen(!isMenuOpen);
      }
    },
    [
      createImageNode,
      createImageReferenceNode,
      editor,
      isMenuOpen,
      previousCursorPosition,
      withImageReferences,
    ]
  );

  const handleSubmitNewImage = () => {
    if (imageUrl && !isImageUrl(imageUrl)) {
      setErrorMessage('Please submit a valid image URL');
      return;
    }

    insertNode({ fileName: imageUrl, src: imageUrl });
    setIsModalShowing(false);
  };

  const imageOptions = (): MenuItem[] => {
    const imageMenuItems: MenuItem[] = images
      ? images.map((image) => {
          const { filename, src, description } = image;
          return {
            label: <ImageMenuOption fileName={filename} src={src} />,
            value: src,
            onSelect: () =>
              insertNode({
                src,
                description,
                fileName: filename,
              }),
          };
        })
      : [];

    return imageMenuItems;
  };

  const handleMouseEnter = () => {
    if (isMenuOpen) {
      setTooltipIsVisible(false);
    } else {
      setTooltipIsVisible(true);
    }
  };

  const handleMouseLeave = () => {
    setTooltipIsVisible(false);
  };

  const handleOpenMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setActiveItem(null);
  };

  const icon = isMenuOpen || isModalShowing ? activeIcon : inactiveIcon;
  const menuItems = imageOptions();

  return (
    <>
      <ToolButtonWrapper data-testid="AddImageToolWrapper">
        <ToolTip
          text={withImageReferences ? 'Add Image Reference' : 'Add image'}
          $isVisible={tooltipIsVisible}
        >
          <ToolButton
            data-testid="AddImageButton"
            type="button"
            onKeyDown={(event) =>
              handlePortalMenuKeyboardNav({
                activeItem,
                event,
                isMenuOpen,
                menuItems,
                setActiveItem,
                setIsMenuOpen,
              })
            }
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();

              if (images) {
                handleOpenMenu();
              } else {
                setIsModalShowing(!isModalShowing);
              }
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={parentRef}
            disabled={editDisabled}
            $isDisabled={editDisabled}
          >
            <ToolButtonIcon
              $isDisabled={editDisabled}
              $isActive={false}
              src={icon}
            />
          </ToolButton>
        </ToolTip>
        {isModalShowing && (
          <AddImageModal
            errorMessage={errorMessage}
            handleSubmitNewImage={handleSubmitNewImage}
            imageUrl={imageUrl}
            setErrorMessage={setErrorMessage}
            setImageUrl={setImageUrl}
            setIsModalShowing={setIsModalShowing}
          />
        )}

        {isMenuOpen && (
          <OutsideClickHandler
            onOutsideClick={() => {
              if (isMenuOpen && !mouseIsInsideMenu) {
                setIsMenuOpen(!isMenuOpen);
                setActiveItem(null);
              }
            }}
          >
            <AddImageMenu
              activeItem={activeItem}
              menuItems={menuItems}
              parentRef={parentRef}
              isMenuOpen={isMenuOpen}
              setActiveItem={setActiveItem}
              setIsMenuOpen={setIsMenuOpen}
              setMouseIsInsideMenu={setMouseIsInsideMenu}
            />
          </OutsideClickHandler>
        )}
      </ToolButtonWrapper>
    </>
  );
};

export default AddImageButton;

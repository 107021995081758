import React from 'react';
import styled from 'styled-components';
import DeleteIcon from '../../assets/icon-delete-white.svg';

const StyledDeleteButton = styled.button`
  height: 25px;
  width: 25px;
  border-radius: 5px;
  border: none;
  background: var(--red);
  cursor: pointer;
  text-align: center;
  img {
    vertical-align: bottom;
  }
  &:hover {
    background: var(--red-90);
  }
  &:active {
    background: var(--red-80);
  }
`;

type DeleteButtonProps = {
  onClick: () => void;
};

export default ({ onClick }: DeleteButtonProps): JSX.Element | null => (
  <StyledDeleteButton onClick={onClick} data-testid="DeleteButton">
    <img src={DeleteIcon} alt="delete button" />
  </StyledDeleteButton>
);

import React, { FunctionComponent, useState } from 'react';
import OptionButton from './OptionButton';
import { OptionButtonsWrapper } from './optionButtonsStyles';

export interface OptionType {
  label: string;
  value: string;
}

export interface OptionButtonsProps {
  parentStateValue?: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  options: OptionType[];
}

const OptionButtons: FunctionComponent<OptionButtonsProps> = ({
  parentStateValue,
  handleChange,
  name,
  options,
}) => {
  const [currentSelectedValue, setCurrentSelectedValue] = useState(
    parentStateValue || options[0].value
  );

  const isCurrentlySelected = (value: string) => {
    return parentStateValue
      ? parentStateValue === value
      : currentSelectedValue === value;
  };

  return (
    <OptionButtonsWrapper
      role="radiogroup"
      data-testid="OptionButtonsWrapper"
      aria-labelledby={`${name}_radio_group`}
    >
      {options.map((option, index) => {
        const { label, value } = option;
        const inputId = `${name}__${value}`;

        return (
          <OptionButton
            key={inputId}
            handleChange={handleChange}
            inputId={inputId}
            isCurrentlySelected={isCurrentlySelected(value)}
            label={label}
            name={name}
            setCurrentSelectedValue={setCurrentSelectedValue}
            value={value}
            options={options}
            index={index}
          />
        );
      })}
    </OptionButtonsWrapper>
  );
};

export default OptionButtons;

import React, { FunctionComponent, useEffect, useRef } from 'react';
import { usePrevious } from '../../utils/utils';
import type { OptionType } from './OptionButtons';
import { Input, OptionWrapper, VisibleOption } from './optionButtonsStyles';

type OptionButtonProps = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputId: string;
  isCurrentlySelected: boolean;
  label: string;
  name: string;
  setCurrentSelectedValue: React.Dispatch<React.SetStateAction<string>>;
  value: string;
  options: OptionType[];
  index: number;
};

const OptionButton: FunctionComponent<OptionButtonProps> = ({
  handleChange,
  inputId,
  isCurrentlySelected,
  label,
  name,
  setCurrentSelectedValue,
  value,
  options,
  index,
}) => {
  const ref = useRef<HTMLLabelElement>(null);
  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event);
    setCurrentSelectedValue(event.target.value);
    if (ref.current) {
      ref.current.blur();
    }
  };

  const prevIsCurrentlySelected = usePrevious(isCurrentlySelected);
  useEffect(() => {
    if (isCurrentlySelected && !prevIsCurrentlySelected && ref.current) {
      ref.current.focus();
    }
  });

  return (
    <OptionWrapper
      key={value}
      ref={ref}
      className={isCurrentlySelected ? 'checked' : undefined}
      htmlFor={inputId}
      aria-checked={isCurrentlySelected}
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === 'ArrowRight' || event.key === 'ArrowDown') {
          const newIndex = index < options.length - 1 ? index + 1 : 0;
          setCurrentSelectedValue(options[newIndex].value);
        } else if (event.key === 'ArrowLeft' || event.key === 'ArrowUp') {
          const newIndex = index === 0 ? options.length - 1 : index - 1;
          setCurrentSelectedValue(options[newIndex].value);
        }
      }}
      role="radio"
    >
      <Input
        id={inputId}
        data-testid={`${name}_${value}_OptionButton`}
        name={name}
        onChange={(event): void => handleSelect(event)}
        type="radio"
        value={`${value}`}
        checked={isCurrentlySelected}
      />
      <VisibleOption label={label} />
    </OptionWrapper>
  );
};

export default OptionButton;

import styled, { DefaultTheme, css } from 'styled-components';
import { DiffOperationType as DiffType } from '@trustflight/tf-slate';
import {
  getDiffStyles,
  DeleteInlineBlock,
  InsertInlineBlock,
  getElDeleteStyles,
  getElInsertStyles,
} from '../Diff/DiffElements';

type DiffOperationType = DiffType.Insert | DiffType.Delete;

export const StyledParagraph = styled.p<{
  $diffOperationType?: DiffOperationType;
}>`
  font-size: 0.95em;
  line-height: 1.45em;
  margin: 0 0 15px 0;
  width: fit-content;
  min-width: 1px;
  ${({ $diffOperationType, theme }) => getDiffStyles(theme, $diffOperationType)}
`;

// Define a function to get the cell styles based on the $diffOperationType
const getCellStyles = (
  theme: DefaultTheme,
  $diffOperationType?: DiffOperationType
) => {
  switch ($diffOperationType) {
    case DiffType.Delete:
      return getElDeleteStyles(theme);
    case DiffType.Insert:
      return getElInsertStyles(theme);
    default:
      return '';
  }
};

export const Row = styled.tr<{ $diffOperationType?: DiffOperationType }>`
  ${({ theme, $diffOperationType }) => css`
    & > td {
      ${getCellStyles(theme, $diffOperationType)}
    }
  `}
`;
export const Table = styled.table<{
  cols?: number;
  rows?: number;
  $diffOperationType?: DiffOperationType;
}>`
  border: 1px solid ${({ theme }) => theme.colors.black10Alpha};
  border-collapse: collapse;
  margin: 10px 0;
  > * {
    box-sizing: border-box;
  }
  ${({ theme, $diffOperationType }) => css`
    & > tbody > tr > td {
      ${getCellStyles(theme, $diffOperationType)}
    }
  `}
`;

const ListStyles = css<{ $diffOperationType?: DiffOperationType }>`
  margin-top: 0;
  ${({ $diffOperationType }) =>
    $diffOperationType === DiffType.Insert &&
    css`
      ${InsertInlineBlock}
    `}
  ${({ $diffOperationType }) =>
    $diffOperationType === DiffType.Delete &&
    css`
      ${DeleteInlineBlock}
    `};
`;

export const StyledListItem = styled.li<{
  $diffOperationType?: DiffOperationType;
}>`
  font-size: 0.95em;
  line-height: 1.45em;
  min-width: 1px;
  max-width: 100%;
  margin-bottom: 5px;
  width: fit-content;
  ${({ $diffOperationType, theme }) =>
    getDiffStyles(theme, $diffOperationType)}/* } */
`;

export const StyledNumberList = styled.ol<{
  $diffOperationType?: DiffOperationType;
}>`
  ${ListStyles}
`;

export const StyledBulletList = styled.ul<{
  $diffOperationType?: DiffOperationType;
}>`
  ${ListStyles}
`;

// Need to handle for all indent typs here.
const applyDiffStyles = css<{
  $diffOperationType?: DiffOperationType;
  theme: DefaultTheme;
}>`
  ${({ $diffOperationType, theme }) =>
    $diffOperationType && getDiffStyles(theme, $diffOperationType)}
`;

// Need to handle for all types that can be indented here, may need images in future.
export const Indentation = styled.div<{
  $diffOperationType?: DiffOperationType;
}>`
  display: block;
  padding-left: 25px;
  & > ${StyledParagraph}, & > ${StyledNumberList}, & > ${StyledBulletList} {
    ${({ theme, $diffOperationType }) =>
      getDiffStyles(theme, $diffOperationType)}
  }
  & > ${Table} {
    border-collapse: collapse;
    ${({ theme, $diffOperationType }) =>
      getCellStyles(theme, $diffOperationType)}
  }
  & > ${Table} > tbody > tr > td {
    ${({ theme, $diffOperationType }) =>
      getCellStyles(theme, $diffOperationType)}
  }
`;

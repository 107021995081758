import React, { memo, useState } from 'react';
import { Editor, Transforms, Element, Node } from 'slate';
import { useSlate } from 'slate-react';
import InlineSvg from 'react-inlinesvg';
import styled from 'styled-components';
import IndentationDecreaseIcon from '../../assets/icon-editor-indentation-decrease.svg';
import IndentationIncreaseIcon from '../../assets/icon-editor-indentation-increase.svg';
import { useRTEContext } from './RTEContext';
import { ToolButtonWrapper, ToolButton } from './ToolBarStyles';
import ToolTip from './ToolTip';
import { ensureSelection } from './utils/utils';

export interface IndentationButtonProps {
  icon: string;
  tooltip: string;
  'data-testid': string;
  $isDisabled?: boolean;
  onToggle: () => void;
}

const IndentationIcon = styled(InlineSvg)<{ $isDisabled: boolean }>`
  width: 26px;
  path {
    fill: ${({ $isDisabled, theme }): string =>
      $isDisabled ? theme.colors.black20Alpha : theme.colors.black};
  }
`;

const IndentationButton = memo(
  ({
    icon,
    tooltip,
    $isDisabled = false,
    onToggle,
    'data-testid': dataTestId,
  }: IndentationButtonProps) => {
    const [tooltipIsVisible, setTooltipIsVisible] = useState<boolean>(false);

    return (
      <ToolButtonWrapper>
        <ToolTip text={tooltip} $isVisible={tooltipIsVisible}>
          <ToolButton
            data-testid={dataTestId}
            onClick={onToggle}
            onMouseEnter={() => {
              setTooltipIsVisible(true);
            }}
            onMouseLeave={() => {
              setTooltipIsVisible(false);
            }}
            $isDisabled={$isDisabled}
            disabled={$isDisabled}
          >
            <IndentationIcon src={icon} $isDisabled={$isDisabled} />
          </ToolButton>
        </ToolTip>
      </ToolButtonWrapper>
    );
  }
);
const block = { type: 'indentation', children: [] };
export default (): JSX.Element => {
  const editor: Editor = useSlate();
  const { state: RTEState } = useRTEContext();
  const { userDisabled: editDisabled } = RTEState;

  const checkIfCanNotDecreaseIndentation = (): boolean => {
    const [match] = Editor.nodes(editor, {
      match: (n: Node) => ((n as Element).type as string) === 'indentation',
    });
    return !editor.selection || !match;
  };
  const checkIfCanNotIncreaseIndentation = (): boolean => {
    return !editor.selection;
  };

  return (
    <>
      <IndentationButton
        tooltip="Decrease Indentation"
        icon={IndentationDecreaseIcon}
        data-testid="IndentationDecreaseButton"
        $isDisabled={editDisabled || checkIfCanNotDecreaseIndentation()}
        onToggle={() => {
          ensureSelection(editor);
          Transforms.unwrapNodes(editor, {
            split: true,
            match: (n: Node) =>
              ((n as Element).type as string) === 'indentation',
          });
        }}
      />
      <IndentationButton
        tooltip="Increase Indentation"
        icon={IndentationIncreaseIcon}
        data-testid="IndentationIncreaseButton"
        $isDisabled={editDisabled || checkIfCanNotIncreaseIndentation()}
        onToggle={() => {
          ensureSelection(editor);

          if (editor.selection) {
            const [...cells] = Editor.nodes(editor, {
              at: editor.selection,
              match: (n: Node) => {
                const { type } = n as Element;
                return type === 'table-cell' || type === 'list-item';
              },
              mode: 'highest',
            });

            Transforms.wrapNodes(editor, block, {
              split: false,
              match: (n: Node, path: number[]) => {
                if (cells.length > 0) {
                  const [...allSelectedNodes] = Node.ancestors(editor, path);

                  const isNodeInTable = allSelectedNodes.some((node: Node) => {
                    const [el] = node;
                    const type = (el as Element).type as string;

                    return (
                      type === 'table' ||
                      type === 'bulleted-list' ||
                      type === 'numbered-list'
                    );
                  });

                  return !isNodeInTable;
                }

                if (
                  n.text ||
                  n.text === '' ||
                  // inline nodes
                  n.type === 'link' ||
                  n.type === 'indentation' ||
                  n.type === 'delete'
                ) {
                  return false;
                }
                return true;
              },
            });
          }
        }}
      />
    </>
  );
};

import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ isChecked: boolean }>`
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  background-color: ${({ isChecked, theme }) =>
    isChecked ? theme.colors.brandBlue : theme.colors.black10Alpha};
  border-radius: 34px;
`;

const RoundSlider = styled.span<{ isChecked: boolean }>`
  position: absolute;
  cursor: pointer;
  bottom: 1px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14);
  left: ${({ isChecked }) => (isChecked ? '15px;' : '2px;')};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: white;
`;

export type SwitchProps = {
  onChange: (isChecked: boolean) => void;
  initialIsChecked?: boolean;
};

export const Switch = ({
  onChange,
  initialIsChecked = false,
}: SwitchProps): JSX.Element => {
  const [isChecked, setIsChecked] = useState<boolean>(initialIsChecked);
  return (
    <Wrapper
      onClick={() => {
        onChange(!isChecked);
        setIsChecked(!isChecked);
      }}
      isChecked={isChecked}
      data-testid="Wrapper"
    >
      <RoundSlider isChecked={isChecked} data-testid="RoundSlider" />
    </Wrapper>
  );
};

export default Switch;

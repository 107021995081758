import styled from 'styled-components';

export const CautionBoxWrapper = styled.div<{ isCautionBox?: boolean }>`
  border-radius: 2px;
  box-shadow: 0 0 0 1px
    ${({ isCautionBox }) =>
      isCautionBox ? 'var(--caution-box-orange)' : 'var(--red)'};
  .content-wrapper {
    padding: 10px 15px;
  }
  .header-wrapper {
    background-color: ${({ isCautionBox }) =>
      isCautionBox ? 'var(--caution-box-orange)' : 'var(--red)'};
    color: var(--white);
    padding: 5px 15px;
    text-transform: uppercase;
  }
`;

export const CautionBoxOuterWrapper = styled.div`
  .unselectable {
    cursor: default;
    -webkit-user-select: none;
    -khtml-user-drag: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
  }
  .unselectable:focus {
    outline: none;
  }
`;

export const KeystrokeInstructions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 5px 0 15px;
  user-select: none;
  &:focus {
    outline: none;
  }
  .instruction {
    color: var(--black-50);
    font-size: 0.7rem;
    margin-right: 10px;
  }
  .instruction--emphasis {
    font-weight: 600;
  }
`;

/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import InlineSVG from 'react-inlinesvg';

export const ComponentWrapper = styled.div`
  width: 100%;
`;

export const SelectInputBox = styled.input`
  min-height: 32px;
  width: 100%;
  border: none;
  box-sizing: border-box; // Prevent padding effecting 100% width
  padding: 0 8px;
  background-color: ${({ theme }): string => theme.colors.white};
  font-size: 14px;
  color: ${({ theme }): string => theme.colors.black80Alpha};
  ::placeholder {
    color: var(--black-30);
    letter-spacing: 0.2px;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${({ theme }): string => theme.colors.brandBlue};
  }
`;

export const MenuSearchInputBox = styled.input`
  min-height: 32px;
  width: 100%;
  border: none;
  box-sizing: border-box; // Prevent padding effecting 100% width
  padding: 0 8px;
  background-color: ${({ theme }): string => theme.colors.white};
  font-size: 14px;
  color: ${({ theme }): string => theme.colors.black80Alpha};
  ::placeholder {
    color: var(--black-30);
    letter-spacing: 0.2px;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${({ theme }): string => theme.colors.brandBlue};
  }

  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.05);
  background-color: #f3f7fc;
`;

export const SelectBox = styled.div<{
  isSearchable?: boolean;
  itemIsSelected?: boolean;
  menuIsOpen: boolean;
}>`
  height: 32px;
  font-size: 14px;
  padding-left: ${({ isSearchable }) => (isSearchable ? '0' : '8px')};
  display: flex;
  position: relative;
  cursor: pointer;
  background-color: ${({ theme }): string => theme.colors.white};
  border-radius: 2px;
  border: solid 1px
    ${({ menuIsOpen, theme }): string =>
      menuIsOpen ? theme.colors.brandBlue : theme.colors.black10Alpha};
  color: ${({ itemIsSelected, theme }): string =>
    itemIsSelected ? theme.colors.black80Alpha : theme.colors.black30Alpha};
  &:hover {
    border: solid 1px
      ${({ menuIsOpen, theme }): string =>
        menuIsOpen ? theme.colors.brandBlue : theme.colors.brandBlue20Alpha};
  }
  &:focus {
    outline: none;
    border: solid 1px ${({ theme }): string => theme.colors.brandBlue};
  }
`;

export const MultiSelectBox = styled.div<{
  itemIsSelected?: boolean;
  menuIsOpen: boolean;
  hasError: boolean;
}>`
  background-color: ${({ theme }): string => theme.colors.white};
  border-radius: 2px;
  border: solid 1px
    ${({ menuIsOpen, theme, hasError }): string =>
      hasError
        ? theme.colors.errorRed
        : menuIsOpen
        ? theme.colors.brandBlue
        : theme.colors.black10Alpha};
  color: ${({ itemIsSelected, theme }): string =>
    itemIsSelected ? theme.colors.black80Alpha : theme.colors.black30Alpha};
  cursor: pointer;
  display: block;
  font-size: 14px;
  height: 100%;
  min-height: 32px;
  position: relative;
  width: 100%;
  &:hover {
    border: solid 1px
      ${({ menuIsOpen, theme }): string =>
        menuIsOpen ? theme.colors.brandBlue : theme.colors.brandBlue20Alpha};
  }
  &:focus {
    outline: none;
    border: solid 1px ${({ theme }): string => theme.colors.brandBlue};
  }
`;

export const MultiSelectBoxContainer = styled.ul`
  align-items: center;
  gap: 4px;
  display: inline-flex;
  flex-wrap: wrap;
  padding: 5px 8px;
  margin: 0;
`;

export const SelectMenuItemContainer = styled.div`
  padding: 8px 8px 12px 8px;
`;

export const MultiSelectPlaceholder = styled.div`
  align-items: center;
  display: flex;
  height: 32px;
  margin-left: 10px;
  > p {
    padding: 0;
    margin: 0;
  }
`;

export const MultiSelectChip = styled.li`
  padding: 3px 8px;
  color: #242d41;
  border-radius: 2px;
  border: solid 1px ${({ theme }): string => theme.colors.black10Alpha};
  background-color: ${({ theme }): string => theme.colors.zircon};
  font-size: 12px;
  line-height: 14px;
  display: grid;
  grid-template-columns: 1fr 16px;
  &:hover {
    background-color: ${({ theme }): string => theme.colors.white};
    border: solid 1px ${({ theme }): string => theme.colors.black10Alpha};
    background-color: ${({ theme }): string => theme.colors.softGray};
    > div {
      color: ${({ theme }): string => theme.colors.brandBlue};
    }
  }
`;

export const ChipIconContainer = styled.div`
  margin: 0 auto;
`;

export const ChipContent = styled.div``;

export const SelectedLabel = styled.div`
  padding-top: 9px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 20px;
`;

export const DropdownWrapper = styled.div<{ menuIsOpen: boolean }>`
  width: fit-content;
  position: absolute;
  cursor: pointer;
  left: calc(100% - 23px);
  top: 8px;
  background: transparent;
  border: none;
  ${({ menuIsOpen }): string =>
    menuIsOpen ? 'transform: rotate(180deg); top: 10px;' : ''};
`;

export const DropdownIcon = styled(InlineSVG)`
  width: 12px;
  path {
    fill: ${({ theme }): string => theme.colors.black70Alpha};
  }
`;

export const DeleteItemIcon = styled(InlineSVG)`
  width: 8px;
  height: 8px;
  margin-left: 8px;
`;

export const MenuOuterWrapper = styled.div<{ isHidden?: boolean }>`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  position: relative;
  width: 100%;
`;

export const MenuWrapper = styled.div<{ isOpen: boolean }>`
  position: absolute;
  background-color: ${({ theme }): string => theme.colors.white};
  max-height: 200px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  width: inherit;
  z-index: 999;
  ${({ isOpen }): string => (!isOpen ? 'display: none' : 'display: block')};
  margin-top: 3px;
  border-radius: 4px;
  border: solid 1px ${({ theme }): string => theme.colors.black10Alpha};
  &:focus {
    outline: none;
  }
  &:first-child {
    padding-top: 4px;
  }
  &:last-child {
    padding-bottom: 4px;
  }
  .loading-wrapper {
    padding: 10px;
  }
`;

export const MenuItem = styled.div<{
  isSelected?: boolean;
  isHighlighted?: boolean;
}>`
  font-size: 14px;
  font-weight: ${({ isSelected }) => (isSelected ? '500' : '400')};
  color: ${({ isSelected, isHighlighted }): string => {
    if ((isHighlighted && isSelected) || isHighlighted) {
      return 'var(--brand-blue)';
    }

    return 'var(--black-90)';
  }};
  cursor: pointer;
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  min-height: 16px;
  background-color: ${({ isHighlighted, isSelected }): string => {
    if (isSelected && isHighlighted) {
      return 'var(--brand-blue-10)';
    }
    if (isSelected) {
      return 'var(--black-10)';
    }
    return isHighlighted && !isSelected ? 'var(--brand-blue-10)' : 'none';
  }};
  &:hover {
    box-shadow: none;
    color: ${({ theme }): string => theme.colors.brandBlue};
    background-color: ${({ theme }): string => theme.colors.brandBlue05Alpha};
  }
`;

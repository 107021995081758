import React, {
  FunctionComponent,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { Transforms, Editor, Element } from 'slate';
import { useSlate, ReactEditor, useSelected } from 'slate-react';
import { DiffOperationType } from '@trustflight/tf-slate';
import { useRTEContext } from '../RTEContext';
import {
  UploadBox,
  ImageUploadLoaderDisplay,
  ImageUploadFailureContent,
} from '../../Dropzone/Dropzone';
import type { ImageMetadata, ImageUploadCompleteResponse } from './Image';

import { handleImageKeyDown } from './Image';

import {
  ImageWrapper,
  ImageCaption,
  ImageCaptionTextArea,
  EditorImage,
} from './imageStyles';

interface ImageReferenceProps {
  images: ImageMetadata[];
  children: JSX.Element | JSX.Element[];
  attributes: {
    'data-slate-node': 'element';
    'data-slate-inline'?: true | undefined;
    'data-slate-void'?: true | undefined;
    dir?: 'rtl' | undefined;
  };
  element: Element;
  imageUploadedData: ImageUploadCompleteResponse[];
  $diffOperationType?: DiffOperationType;
}

const ImageReferenceWithDisplay: FunctionComponent<ImageReferenceProps> = ({
  attributes,
  children,
  $diffOperationType,
  element,
  images,
  imageUploadedData = [],
}) => {
  const editor: Editor = useSlate();
  const {
    imageReferenceId,
    mountFunction,
    description = '',
    loadingReferenceId,
    loading = false,
  } = element;

  const image = images?.find(
    ({ id }: ImageMetadata) => id === imageReferenceId
  );
  const { src } = image || {};

  const relatedUploadData =
    imageUploadedData.find(
      (a: ImageUploadCompleteResponse) =>
        a.loadingReferenceId === element.loadingReferenceId
    ) || ({} as ImageUploadCompleteResponse);

  const { id: imageId = null, error: respError } = relatedUploadData;

  useEffect(() => {
    if (mountFunction && !imageReferenceId) {
      mountFunction();
    }
  }, [mountFunction, imageReferenceId]);

  useEffect(() => {
    const path = ReactEditor.findPath(editor, element);

    if (!image && imageId) {
      Transforms.setNodes(
        editor,
        {
          ...element,
          imageReferenceId: imageId,
          loading: false,
          error: respError,
        },
        {
          at: path,
        }
      );
    }
  }, [imageId, image, editor, element, respError]);

  const applyCaptionChange = useCallback(
    (captionInput) => {
      const imageNodeEntry = Editor.above(editor, {
        match: (n) => n.type === 'image-reference-with-display',
      });
      if (imageNodeEntry == null) {
        return;
      }

      Transforms.setNodes(
        editor,
        { description: captionInput },
        { at: imageNodeEntry[1] }
      );
    },
    [editor]
  );

  const onCaptionChange = useCallback(
    (e) => {
      applyCaptionChange(e?.target?.value);
    },
    [applyCaptionChange]
  );

  const imageNodeEntry = Editor.above(editor, {
    match: (n) => n.type === 'image-reference-with-display',
  }) || [0, [0]];
  const inputId = `${imageNodeEntry[1]}-image-ref-input`;
  const [isFocused, setIsFocused] = useState(false);
  const isSelected = useSelected();

  const { state: RTEState } = useRTEContext();
  const { userDisabled: editDisabled } = RTEState;

  return (
    <span
      data-attr-type="image-reference-with-display"
      data-attr-description={description || ''}
      data-attr-src={src || ''}
      data-attr-image-reference-id={imageReferenceId || ''}
      data-attr-file-name={loadingReferenceId || ''}
    >
      {/* handle intentional errors but also handle errors when image can't be found on attachments/images arr */}
      {(respError || (!respError && !loading && !image)) && (
        <UploadBox
          {...attributes}
          tabIndex={-1}
          contentEditable={false}
          $error
          $fileIsUploading={false}
          style={{ width: '400px', height: '180px', margin: '8px 0' }}
          $isSelected={isFocused && document?.activeElement?.id !== inputId}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          onKeyDown={(e: React.KeyboardEvent) => {
            handleImageKeyDown(e, editor, isSelected);
          }}
        >
          <ImageUploadFailureContent
            message={
              !image ? 'There was an error displaying this image' : undefined
            }
          />
          {children}
        </UploadBox>
      )}

      {!respError && loading && (
        <UploadBox
          {...attributes}
          tabIndex={-1}
          contentEditable={false}
          $error={false}
          $fileIsUploading
          style={{ width: '400px', height: '180px', margin: '8px 0' }}
          $isSelected={isFocused && document?.activeElement?.id !== inputId}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          onKeyDown={(e: React.KeyboardEvent) => {
            handleImageKeyDown(e, editor, isSelected);
          }}
        >
          <ImageUploadLoaderDisplay
            dataTestId="ImageUploadLoading"
            uploadingFileName={loadingReferenceId}
          />
          {children}
        </UploadBox>
      )}
      {!respError && !loading && image && (
        <ImageWrapper
          {...attributes}
          contentEditable={false}
          $diffOperationType={$diffOperationType}
        >
          <EditorImage
            tabIndex={-1}
            src={src}
            alt={description}
            $isSelected={isFocused && document?.activeElement?.id !== inputId}
            onKeyDown={(e: React.KeyboardEvent) => {
              handleImageKeyDown(e, editor, isSelected);
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
          />
          <ImageCaption>
            {/* TODO FIX height of text on typing break to new lines, load, etc. Currently somewhat buggy */}
            <ImageCaptionTextArea
              disabled={editDisabled}
              id={inputId}
              data-testid="ImageReferenceCaptionTextArea"
              defaultValue={description}
              placeholder="Enter Image Caption"
              onChange={onCaptionChange}
              onKeyDown={(e) => {
                // TODO need to handle for arrow down and up out of caption box.
                // if (isHotkey('down', e)) {
                //   console.log('dog');
                // }
                // if (isHotkey('Control+S', e)) {
                //   console.log('dogS');
                // }
              }}
              rows={1}
              style={{
                height: `${
                  description
                    ? `${16 * description.split(/\r\n|\r|\n/).length}px`
                    : `16px`
                } `,
              }}
            />
          </ImageCaption>
          {children}
        </ImageWrapper>
      )}
    </span>
  );
};

export default ImageReferenceWithDisplay;

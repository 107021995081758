import styled, { css } from 'styled-components';

interface CustomisableButtonProps {
  isSecondary?: boolean;
  ariaDisabled?: boolean;
}

const CustomisableButton = styled.button.attrs<CustomisableButtonProps>(
  ({ ariaDisabled }) => ({
    'aria-disabled': ariaDisabled ? 'true' : undefined,
  })
)<CustomisableButtonProps>`
  background: ${({ isSecondary }) =>
    isSecondary ? 'var(--white)' : 'var(--brand-blue)'};
  border-radius: 15px;
  border: none;
  box-shadow: ${({ isSecondary }) =>
    `0 0 0 1px ${isSecondary ? 'var(--brand-blue-20)' : 'var(--brand-blue)'}`};
  color: ${({ isSecondary }) =>
    isSecondary ? 'var(--brand-blue)' : 'var(--white)'};
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 15px;
  transition: background 150ms ease-in-out, border 150ms ease-in-out,
    transform 100ms ease;
  &:hover {
    background: ${({ isSecondary }) =>
      isSecondary ? 'var(--white)' : 'var(--cobalt);'};
    box-shadow: ${({ isSecondary }) =>
      `0 0 0 1px ${isSecondary ? 'var(--brand-blue-40)' : 'var(--cobalt)'}`};
  }
  &:active {
    background: var(--brand-blue-80);
    box-shadow: 0 0 0 1px var(--brand-blue-80);
  }
  &:focus {
    box-shadow: 0 0 0 1px var(--jordy);
    outline: none;
  }
  &:disabled {
    background-color: var(--brand-blue-40);
    box-shadow: 0 0 0 1px var(--brand-blue-40);
    cursor: not-allowed;
  }
  ${(props: { ariaDisabled?: boolean; isSecondary?: boolean }) => {
    if (props.ariaDisabled) {
      return css`
        cursor: not-allowed;
        background-color: var(--brand-blue-40);
        box-shadow: 0 0 0 1px var(--brand-blue-40);
        &:hover,
          background-color: var(--brand-blue-40);
          box-shadow: 0 0 0 1px var(--brand-blue-40);
        }
        &:active {
          background-color: var(--brand-blue-60);
          box-shadow: 0 0 0 1px var(--brand-blue-40);
        }
      `;
    }
    return null;
  }}
`;

export default CustomisableButton;

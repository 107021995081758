import type { Node } from 'slate';

const isObject = (a: unknown) => {
  return !!a && a.constructor === Object;
};

// Consider adding some helpers here to show where in the object structure the error is (depth, index)
export function verifySlateDocShape(
  slateObj: Node[] | Node,
  index: (string | number)[] = []
): Error | void {
  if (Array.isArray(slateObj)) {
    if (slateObj.length === 0) {
      throw new Error(`Slate object is malformed at ${index.join('.')}`);
    }

    if (slateObj.some((child) => !isObject(child))) {
      throw new Error(`Slate object is malformed at ${index.join('.')}`);
    }

    slateObj.forEach((child: unknown, i: number) => {
      verifySlateDocShape(child as Node, [...index, i]);
    });
  }

  // Dec 6, 2023. Consider validating for the various types of nodes that require more of
  // whatever type. Image-refs, links, etc. Could also validate table, list shapes.
  // Extremely basic Slate validation for now.
  Object.keys(slateObj).forEach((key) => {
    const value = slateObj[key as keyof Node];

    if (key === 'text') {
      if (typeof value !== 'string') {
        throw new Error(
          `Slate object is malformed at ${[...index, key].join('.')}`
        );
      }
    }

    if (key === 'children') {
      if (!Array.isArray(value)) {
        throw new Error(
          `Slate object is malformed at ${[...index, key].join('.')}`
        );
      }
    }

    if (Array.isArray(value)) {
      verifySlateDocShape(value, [...index, key]);
    }
    if (isObject(value)) {
      verifySlateDocShape(value, [...index, key]);
    }
  });
}

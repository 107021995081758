import React from 'react';
import styled from 'styled-components';
import DownloadIconSvg from '../../assets/icon-download-white.svg';

const StyledDownloadButton = styled.button`
  background: var(--brand-blue);
  border: none;
  padding-bottom: 2px;
  cursor: pointer;
  height: 24px;
  width: 25px;
  border-radius: 5px;
  margin-right: 10px;
  img {
    vertical-align: bottom;
  }
  &:hover {
    background: var(--cobalt);
  }
  &:active {
    background: var(--brand-blue-80);
  }
`;

type DownloadButtonProps = {
  onClick: () => void;
};

export default ({ onClick }: DownloadButtonProps): JSX.Element => (
  <StyledDownloadButton onClick={onClick} data-testid="DownloadButton">
    <img src={DownloadIconSvg} alt="download button" />
  </StyledDownloadButton>
);

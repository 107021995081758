import { AnimatePresence } from 'framer-motion';
import React, { FunctionComponent, useState } from 'react';
import deleteIcon from '../../assets/delete-icon-x.svg';
import {
  CloseModalBtn,
  CloseModalIcon,
  ModalCard,
  ModalCardVariants,
  ModalContent,
  ModalWrapper,
  ModalWrapperVariants,
} from './modalStyles';

export type ModalProps = {
  children?: React.ReactNode;
  handleClose?: () => void;
  isOpen: boolean;
  shouldCloseOnOuterClick?: boolean;
  width?: number;
};
const Modal: FunctionComponent<ModalProps> = ({
  children,
  shouldCloseOnOuterClick = true,
  handleClose,
  isOpen,
  width = 600,
}) => {
  const [isModalShowing, setIsModalShowing] = useState(isOpen);

  const handleModalClose = (): void => {
    if (handleClose) {
      setIsModalShowing(false);
      handleClose();
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLButtonElement>
  ): void => {
    if (event.key === 'Enter') {
      handleModalClose();
    }
  };

  return (
    <AnimatePresence>
      {(isModalShowing || isOpen) && (
        <ModalWrapper
          data-testid="ModalWrapper"
          variants={ModalWrapperVariants}
          initial="initial"
          animate="enter"
          exit="exit"
          $isModalOpen={isOpen}
          onMouseDown={
            shouldCloseOnOuterClick && handleClose
              ? handleModalClose
              : undefined
          }
        >
          <ModalCard
            data-testid="ModalCard"
            animate="enter"
            exit="exit"
            initial="initial"
            onClick={(e): void => e.stopPropagation()}
            onMouseDown={(e): void => e.stopPropagation()}
            variants={ModalCardVariants}
            $width={width}
          >
            <ModalContent data-testid="ModalContent">
              {handleClose && (
                <CloseModalBtn
                  data-testid="CloseModalBtn"
                  onClick={handleModalClose}
                  onKeyDown={handleKeyDown}
                  tabIndex={0}
                >
                  <CloseModalIcon src={deleteIcon} />
                </CloseModalBtn>
              )}
              {children}
            </ModalContent>
          </ModalCard>
        </ModalWrapper>
      )}
    </AnimatePresence>
  );
};

export default Modal;

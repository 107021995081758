import React, { memo, useCallback, useState } from 'react';
import { useSlate } from 'slate-react';
import { Editor, Transforms } from 'slate';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';
import {
  ActiveColorIcon,
  ToolButton,
  ToolButtonWrapper,
  MenuDropdown,
  MenuDropdownWrapper,
  PopUpMenuTopRow,
  PopUpMenuBottomRow,
  PopUpMenuLabel,
} from '../ToolBarStyles';
import ToolTip from '../ToolTip';
import { useRTEContext } from '../RTEContext';

type ColorPaletteItemProps = {
  color: string;
  $isActive: boolean;
};

export const TextColorPicker = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColorPaletteItem = styled.button<ColorPaletteItemProps>`
  margin: 3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid var(--white);
  position: relative;
  cursor: pointer;
  background-color: ${({ color }) => color};
  box-shadow: ${({ $isActive, theme }) =>
    $isActive ? ` 0 0 0 1px ${theme.colors.brandBlue}` : 'none;'};
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.brandBlue10Alpha};
  }
`;

export const RemoveColor = styled.img`
  width: 16px;
  height: 16px;
  top: -2px;
  left: -2px;
  position: absolute;
`;

interface ColorMenuProps {
  children: JSX.Element | JSX.Element[];
  showMenu: boolean;
  menuToggle(fn: (menuState: boolean) => boolean): void;
  toggleOtherMenus: {
    siblingColourMenu: (arg0: boolean) => void;
    tableMenu: (arg0: boolean) => void;
  };
  otherMenuStates: boolean[];
  icon: string;
  label: string;
  type: 'textColor' | 'highlightColor';
}

export default memo(
  ({
    children,
    showMenu,
    menuToggle,
    icon,
    label,
    type,
    toggleOtherMenus: { siblingColourMenu, tableMenu },
    otherMenuStates,
  }: ColorMenuProps) => {
    const editor: Editor = useSlate();
    const marks = Editor.marks(editor);
    const [tooltipIsVisible, setTooltipIsVisible] = useState<boolean>(false);
    const { state: RTEState } = useRTEContext();
    const { userDisabled: editDisabled } = RTEState;

    const handleMenuToggle = useCallback(() => {
      if (otherMenuStates.includes(true)) {
        siblingColourMenu(false);
        tableMenu(false);
      }
      if (!editor.selection) {
        Transforms.select(editor, {
          anchor: { path: [0, 0], offset: 0 },
          focus: { path: [0, 0], offset: 0 },
        });
      }
      menuToggle((menuState) => !menuState);
    }, [otherMenuStates, editor, menuToggle, siblingColourMenu, tableMenu]);

    const defaultColor =
      type === 'highlightColor' ? 'var(--black-10)' : 'black';

    return (
      <>
        <ToolButtonWrapper>
          <ToolTip
            text={`${type.replace(/([A-Z])/g, ' $1').trim()}`}
            $isVisible={tooltipIsVisible && !showMenu}
          >
            <ToolButton
              disabled={editDisabled}
              $isDisabled={editDisabled}
              type="button"
              onClick={handleMenuToggle}
              onMouseEnter={() => {
                setTooltipIsVisible(true);
              }}
              onMouseLeave={() => {
                setTooltipIsVisible(false);
              }}
            >
              <ActiveColorIcon
                src={icon}
                $isDisabled={editDisabled}
                color={
                  marks && marks[type] ? (marks[type] as string) : defaultColor
                }
              />
            </ToolButton>
          </ToolTip>
        </ToolButtonWrapper>
        {showMenu && (
          <OutsideClickHandler
            onOutsideClick={() => {
              handleMenuToggle();
            }}
          >
            <MenuDropdownWrapper>
              <MenuDropdown>
                <PopUpMenuTopRow>
                  <TextColorPicker>
                    <img src={icon} alt="icon" />
                  </TextColorPicker>
                  <PopUpMenuLabel>{label}</PopUpMenuLabel>
                </PopUpMenuTopRow>
                <PopUpMenuBottomRow onClick={handleMenuToggle}>
                  {children}
                </PopUpMenuBottomRow>
              </MenuDropdown>
            </MenuDropdownWrapper>
          </OutsideClickHandler>
        )}
      </>
    );
  }
);

import React, { FunctionComponent } from 'react';
import icon from '../../../assets/RichTeIcons/icon-add-image.svg';
import PortalMenu from '../../ThreeDotMenu/PortalMenu';
import type { MenuItem } from '../../ThreeDotMenu/ThreeDotMenu';
import handlePortalMenuKeyboardNav from './handlePortalMenuKeyboardNav';
import { AddImageMenuWrapper } from './imageStyles';

interface AddImageMenuProps {
  activeItem: number | null | undefined;
  // initialActiveItem?: MenuItem | undefined;
  isMenuOpen: boolean;
  menuItems: MenuItem[];
  parentRef: React.RefObject<HTMLButtonElement>;
  setActiveItem: React.Dispatch<
    React.SetStateAction<number | null | undefined>
  >;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMouseIsInsideMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddImageMenu: FunctionComponent<AddImageMenuProps> = ({
  activeItem,
  isMenuOpen,
  menuItems,
  parentRef,
  setActiveItem,
  setIsMenuOpen,
  setMouseIsInsideMenu,
}) => {
  return (
    <AddImageMenuWrapper
      onKeyDown={(event) =>
        handlePortalMenuKeyboardNav({
          activeItem,
          event,
          isMenuOpen,
          menuItems,
          setActiveItem,
          setIsMenuOpen,
        })
      }
    >
      <PortalMenu
        activeItem={activeItem}
        menuHeader={{
          icon,
          title: 'Images',
        }}
        menuItems={menuItems}
        parentRef={parentRef}
        setActiveItem={setActiveItem}
        setMouseIsInsideMenu={setMouseIsInsideMenu}
        setIsMenuOpen={setIsMenuOpen}
      />
    </AddImageMenuWrapper>
  );
};

export default AddImageMenu;

import React, { ReactElement, useState } from 'react';
import type { Element } from 'slate';
import { Editor, Transforms } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';

import ToolTip from '../../ToolTip';

import { paragraphNode } from '../../utils/utils';

import {
  ToolButton,
  ToolButtonWrapper,
  ToolButtonIconNoSVGStyles,
  ToolButtonIconImgStyles,
} from '../../ToolBarStyles';

import {
  CellActionButtonTypes,
  InsertRowDirection,
  InsertColDirection,
  insertRowOnExistingTable,
  buildNewTableWithColInsert,
  buildNewTableWithColRemoved,
} from './utils';

interface CellMenuButtonInterface {
  element: Element;
  colMenuButtonType: CellActionButtonTypes;
  toolTipText: string;
  buttonIcon: string;
  handleCellActionClick: (cellPos: number[]) => void;
}

const handleTableCellMenuClick = (
  editor: Editor,
  element: Element,
  colMenuButtonType: CellActionButtonTypes,
  handleCellActionClick: any
) => {
  ReactEditor.focus(editor);
  const cellPath = ReactEditor.findPath(editor, element);
  // Table Slate structure MUST BE -> [num:0-9  (table), num:0-9 (row), num:0-9 (col)]:
  // so: [0, 0, 0], [0, 1, 3], [3, 4, 2], etc.
  // everything here will break if we change the table structure/data contract
  const entireTablePath = cellPath.slice(0, -2);

  // this will be set depending on what button col type is doing
  // ie: go to next col cell or next row cell, etc.
  // TODO - Consider rebuild this to a series of functiosn that return necessary value;
  let nextColPath = null;

  function handleDeleteEntireTable() {
    const fullTablePath = [...cellPath].slice(0, -2);

    handleCellActionClick([]);

    Transforms.removeNodes(editor, {
      at: fullTablePath,
    });

    Transforms.insertNodes(editor, paragraphNode, {
      at: fullTablePath,
    });

    nextColPath = [...fullTablePath, 0];
  }

  if (colMenuButtonType === CellActionButtonTypes.DeleteCol) {
    const newTable = buildNewTableWithColRemoved(editor, element);
    const cellToSelectFullPath = [...cellPath];
    const newCellPath = cellToSelectFullPath[cellToSelectFullPath.length - 1];

    // if they are deleting the last column, we need to delete, handle for deleting entire table
    if (newTable?.children[0]?.children?.length === 0) {
      handleDeleteEntireTable();
    } else {
      const cellToSelectRowFullPath = [...cellToSelectFullPath].slice(0, -1);
      const cellToSelectRow = Editor.node(editor, cellToSelectRowFullPath)[0];

      // Last col handling
      if (newCellPath + 1 === cellToSelectRow?.children?.length) {
        cellToSelectFullPath.splice(-1, 1, newCellPath - 1);
        nextColPath = [...cellToSelectFullPath, 0, 0];
      } else {
        nextColPath = [...cellToSelectFullPath, 0, 0];
      }

      handleCellActionClick(cellToSelectFullPath);

      Transforms.removeNodes(editor, {
        at: entireTablePath,
      });

      Transforms.insertNodes(editor, newTable, {
        at: entireTablePath,
      });
    }
  }

  if (colMenuButtonType === CellActionButtonTypes.InsertColLeft) {
    const newTable = buildNewTableWithColInsert(
      editor,
      element,
      InsertColDirection.Left
    );

    nextColPath = [...cellPath, 0, 0];

    handleCellActionClick(cellPath);

    Transforms.removeNodes(editor, {
      at: entireTablePath,
    });

    Transforms.insertNodes(editor, newTable, {
      at: entireTablePath,
    });
  }

  if (colMenuButtonType === CellActionButtonTypes.InsertColRight) {
    const newTable = buildNewTableWithColInsert(
      editor,
      element,
      InsertColDirection.Right
    );

    const cellToSelectFullPath = cellPath.map((c: number, i: number) => {
      if (i === cellPath.length - 1) {
        return c + 1;
      }

      return c;
    });

    nextColPath = [...cellToSelectFullPath, 0, 0];

    handleCellActionClick(cellToSelectFullPath);

    Transforms.removeNodes(editor, {
      at: entireTablePath,
    });

    Transforms.insertNodes(editor, newTable, {
      at: entireTablePath,
    });
  }

  if (colMenuButtonType === CellActionButtonTypes.DeleteRow) {
    const tableDocBeforeDelete = Editor.node(editor, entireTablePath)[0];

    // if user is deleting the last row, we need to really delete the entire table, set selection better.
    if (tableDocBeforeDelete.children.length === 1) {
      handleDeleteEntireTable();
    } else {
      Transforms.removeNodes(editor, {
        at: cellPath.slice(0, -1),
      });

      const cellToSelectFullPath = [...cellPath];
      const tableDoc = Editor.node(editor, entireTablePath)[0];

      const cellToSelectRowPath =
        cellToSelectFullPath[cellToSelectFullPath.length - 2];

      // handle last row
      if (tableDoc?.children?.length === cellToSelectRowPath) {
        cellToSelectFullPath.splice(-2, 1, cellToSelectRowPath - 1);
        nextColPath = [...cellToSelectFullPath, 0, 0];
      } else {
        nextColPath = [...cellToSelectFullPath, 0, 0];
      }

      handleCellActionClick([cellToSelectFullPath]);

      Transforms.setNodes(
        editor,
        {
          rows: tableDoc.rows - 1,
        },
        {
          at: entireTablePath,
        }
      );
    }
  }

  if (colMenuButtonType === CellActionButtonTypes.InsertRowAbove) {
    insertRowOnExistingTable(editor, element, InsertRowDirection.Above);
    const cellToSelectFullPath = [...cellPath];

    handleCellActionClick([...cellToSelectFullPath]);

    nextColPath = [...cellToSelectFullPath, 0, 0];
  }

  if (colMenuButtonType === CellActionButtonTypes.InsertRowBelow) {
    insertRowOnExistingTable(editor, element, InsertRowDirection.Below);

    const cellToSelectFullPath = cellPath.map((c: number, i: number) => {
      if (i === cellPath.length - 2) {
        return c + 1;
      }

      return c;
    });

    handleCellActionClick(cellToSelectFullPath);

    nextColPath = [...cellToSelectFullPath, 0, 0];
  }

  if (colMenuButtonType === CellActionButtonTypes.DeleteTable) {
    handleDeleteEntireTable();
  }

  if (nextColPath) {
    Transforms.select(editor, {
      anchor: {
        offset: 0,
        path: nextColPath,
      },
      focus: {
        offset: 0,
        path: nextColPath,
      },
    });
  }
};

const CellMenuButton = ({
  element,
  colMenuButtonType,
  toolTipText,
  buttonIcon,
  handleCellActionClick,
}: CellMenuButtonInterface): ReactElement => {
  const [isToolTipVisible, setUsToolTipVisible] = useState(false);
  const editor = useSlate();
  const cellPath = ReactEditor.findPath(editor, element);
  const cellPathString = cellPath.join('');

  return (
    <ToolButtonWrapper>
      <ToolTip text={toolTipText} $isVisible={isToolTipVisible}>
        <ToolButton
          data-testid={`Table-Cell-${cellPathString}-Button-${colMenuButtonType}`}
          onClick={(e) => {
            e.stopPropagation();
            handleTableCellMenuClick(
              editor,
              element,
              colMenuButtonType,
              handleCellActionClick
            );
          }}
          onMouseEnter={() => {
            setUsToolTipVisible(true);
          }}
          onMouseLeave={() => {
            setUsToolTipVisible(false);
          }}
        >
          <ToolButtonIconImgStyles $isActive={false} src={buttonIcon} />
        </ToolButton>
      </ToolTip>
    </ToolButtonWrapper>
  );
};

export default CellMenuButton;

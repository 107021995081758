import React, { useState, useRef, useEffect } from 'react';
import SearchSvg from '../../assets/search.svg';
import ClearSvg from '../../assets/icon-clear.svg';
import {
  SearchWrapper,
  SearchInput,
  SearchIcon,
  ClearSearchButton,
} from './SearchStyles';

export type SearchProps = {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onClear: () => void;
  placeholder?: string;
  reset?: boolean;
};

export const Search: React.FC<SearchProps> = ({
  onChange,
  placeholder = 'Search...',
  onClear,
  reset,
}) => {
  const [showClearButton, setShowClearButton] = useState<boolean>(false);
  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (reset && searchRef.current) {
      searchRef.current.value = '';
    }
  }, [reset]);

  return (
    <SearchWrapper data-testid="searchWrapper">
      <SearchInput
        onChange={(event) => {
          if (!showClearButton && event.target.value.length > 0) {
            setShowClearButton(true);
          } else if (showClearButton && event.target.value.length === 0) {
            setShowClearButton(false);
          }
          onChange(event);
        }}
        placeholder={placeholder}
        ref={searchRef}
        data-testid="searchInput"
      />
      <SearchIcon src={SearchSvg} data-testid="searchIcon" />
      {showClearButton ? (
        <ClearSearchButton
          data-testid="clearSearchButton"
          src={ClearSvg}
          onClick={() => {
            if (searchRef.current) {
              searchRef.current.value = '';
            }
            setShowClearButton(false);
            onClear();
          }}
        />
      ) : null}
    </SearchWrapper>
  );
};

export default Search;

import React, { memo, useEffect, useState } from 'react';
import SanitiseIcon from '../../assets/RichTeIcons/sanitise-inactive.svg';
import { useRTEContext } from './RTEContext';
import {
  SanitiseButtonIcon,
  ToolButton,
  ToolButtonWrapper,
} from './ToolBarStyles';
import ToolTip from './ToolTip';

interface SanitiseProps {
  onChange: (value: boolean) => void;
}

export default memo(({ onChange }: SanitiseProps) => {
  const [sanitiseState, updateSanitiseState] = useState(false);
  const [tooltipIsVisible, setTooltipIsVisible] = useState<boolean>(false);
  const { state: RTEState } = useRTEContext();
  const { userDisabled: editDisabled } = RTEState;

  const toggleSanitise = (): void => {
    updateSanitiseState(!sanitiseState);
  };

  useEffect(() => {
    onChange(sanitiseState);
  }, [onChange, sanitiseState]);

  return (
    <ToolButtonWrapper>
      <ToolTip text="sanitise" $isVisible={tooltipIsVisible}>
        <ToolButton
          $isDisabled={editDisabled}
          disabled={editDisabled}
          type="button"
          onClick={toggleSanitise}
          onMouseEnter={() => {
            setTooltipIsVisible(true);
          }}
          onMouseLeave={() => {
            setTooltipIsVisible(false);
          }}
        >
          <SanitiseButtonIcon
            src={SanitiseIcon}
            $isActive={sanitiseState}
            $isDisabled={editDisabled}
          />
        </ToolButton>
      </ToolTip>
    </ToolButtonWrapper>
  );
});

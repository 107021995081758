import { ReactEditor } from 'slate-react';
import type { Element, Node, CustomTypes } from 'slate';
import { Editor, Transforms } from 'slate';
import { cloneDeep } from 'lodash';

import { insertIf, paragraphNode } from '../../utils/utils';

export enum InsertColDirection {
  Left = 'left',
  Right = 'right',
}

export enum InsertRowDirection {
  Above = 'above',
  Below = 'below',
}

export enum CellActionButtonTypes {
  DeleteTable = 'deleteTable',

  DeleteCol = 'deleteCol',
  InsertColLeft = 'insertColLeft',
  InsertColRight = 'insertColRight',

  DeleteRow = 'deleteRow',
  InsertRowAbove = 'insertRowAbove',
  InsertRowBelow = 'insertRowBelow',
}

const defaultCell = {
  type: 'table-cell',
  children: [paragraphNode],
};

export function insertRowOnExistingTable(
  editor: Editor,
  element: Element,
  rowInsertDirection: InsertRowDirection
): void {
  const cellPath = ReactEditor.findPath(editor, element);
  const tablePath = [...cellPath].slice(0, -2);
  const currentRowFullPath = [...cellPath].slice(0, -1);

  const tableDoc = Editor.node(editor, tablePath)[0];

  const newRow = {
    type: 'table-row',
    children: [...Array(tableDoc.cols)].map(() => {
      return cloneDeep(defaultCell);
    }),
  };

  Transforms.setNodes(
    editor,
    {
      rows: tableDoc.rows + 1,
    },
    {
      at: tablePath,
    }
  );

  Transforms.insertNodes(editor, newRow, {
    at:
      rowInsertDirection === InsertRowDirection.Above
        ? currentRowFullPath
        : currentRowFullPath.map((pos, i) => {
            if (i === currentRowFullPath.length - 1) {
              return pos + 1;
            }

            return pos;
          }),
  });
}

export function buildNewTableWithColRemoved(
  editor: Editor,
  element: Element
): CustomTypes['TableNode'] {
  const cellPath = ReactEditor.findPath(editor, element);
  const tablePath = [...cellPath].slice(0, -2);
  const tableDoc = Editor.node(editor, tablePath)[0];
  const currentCellPosition = [...cellPath].slice(-1)[0];

  const newTable = {
    ...tableDoc,
    cols: tableDoc.cols - 1,
    children: tableDoc.children.map((row: Node) => {
      const { children: cells } = row;

      return {
        ...row,
        children: cells.reduce(
          (newCells: Node[], cell: Node, index: number) => {
            if (index === currentCellPosition) {
              return newCells;
            }

            return [...newCells, cell];
          },
          []
        ),
      };
    }),
  };

  return newTable;
}

export function buildNewTableWithColInsert(
  editor: Editor,
  element: Element,
  insertDir: InsertColDirection
): CustomTypes['TableNode'][] {
  const cellPath = ReactEditor.findPath(editor, element);
  const tablePath = [...cellPath].slice(0, -2);
  const tableDoc = Editor.node(editor, tablePath)[0];
  const currentCellPosition = [...cellPath].slice(-1)[0];

  const newTable = {
    ...tableDoc,
    cols: tableDoc.cols + 1,
    children: tableDoc.children.map((row: Node) => {
      const { children: cells } = row;

      return {
        ...row,
        children: cells.reduce(
          (newCells: Node[], cell: Node, index: number) => {
            if (index === currentCellPosition) {
              return [
                ...newCells,
                ...insertIf(
                  insertDir === InsertColDirection.Left,
                  cloneDeep(defaultCell)
                ),
                cell,
                ...insertIf(
                  insertDir === InsertColDirection.Right,
                  cloneDeep(defaultCell)
                ),
              ];
            }

            return [...newCells, cell];
          },
          []
        ),
      };
    }),
  };

  return newTable;
}

import styled from 'styled-components';

export const SearchWrapper = styled.div`
  position: relative;
`;

export const SearchInput = styled.input`
  height: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 120px;
  border-radius: 16px;
  border: solid 1px ${({ theme }): string => theme.colors.black20Alpha};
  padding-left: 35px;
  padding-right: 32px;
  color: ${({ theme }): string => theme.colors.black60Alpha};
  font-size: 14px;
  &:hover {
    border-color: ${({ theme }): string => theme.colors.brandBlue20Alpha};
  }
  &:focus {
    outline: none;
    border-color: ${({ theme }): string => theme.colors.brandBlue};
  }
  ::placeholder {
    color: ${({ theme }): string => theme.colors.black40Alpha};
  }
`;

export const SearchIcon = styled.img`
  height: 16px;
  position: absolute;
  left: 13px;
  margin-top: 7px;
`;

export const ClearSearchButton = styled.img`
  height: 16px;
  position: absolute;
  left: 160px;
  margin-top: 7px;
  &:hover {
    cursor: pointer;
  }
`;

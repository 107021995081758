import styled from 'styled-components';
import { DiffOperationType } from '@trustflight/tf-slate';

export const RichTextCell = styled.td`
  position: relative;
  height: 36px;
  min-height: 36px;
  min-width: 135px;
  padding: 0;
`;

export const RichTextCellStyleContainer = styled.div<{
  $diffOperationType?: DiffOperationType;
}>`
  border: 1px solid
    ${({ theme, $diffOperationType }): string => {
      if ($diffOperationType === DiffOperationType.Insert) {
        return theme.colors.green80Alpha;
      }
      if ($diffOperationType === DiffOperationType.Delete) {
        return theme.colors.red80Alpha;
      }
      return theme.colors.black10Alpha;
    }};
  background-color: ${({ theme, $diffOperationType }) => {
    if ($diffOperationType === DiffOperationType.Insert) {
      return theme.colors.green20Alpha;
    }
    if ($diffOperationType === DiffOperationType.Delete) {
      return theme.colors.red20Alpha;
    }
    return 'transparent';
  }};
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 6px 12px;
  &.hover {
    background-color: ${({ theme, $diffOperationType }): string | undefined => {
      if (
        $diffOperationType !== DiffOperationType.Insert &&
        $diffOperationType !== DiffOperationType.Delete
      ) {
        return theme.colors.black05Alpha;
      }
      return '';
    }};
  }
  &.selected {
    border: 1px solid ${({ theme }): string => theme.colors.brandBlue};
  }
`;

export const CellMenu = styled.div`
  position: absolute;
  height: 55px;
  top: -42px;
  left: -2px;
  margin-bottom: 100px;
`;

export const CellMenuButtonContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.black05Alpha};
  border-radius: 4px;
  height: 28px;
  background-color: ${({ theme }) => theme.colors.white};
  min-width: 252px;
  display: flex;
  box-shadow: 0 0 5px 2px ${({ theme }) => theme.colors.black10Alpha};
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 4px;
`;

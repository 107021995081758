import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styled, { Interpolation } from 'styled-components';
import { Button } from '../Button/Button';
import dropdownIcon from '../../assets/icon-dropdown-arrowhead.svg';
import DropdownMenu, { DropdownMenuItemDefinition } from './DropdownMenu';
import {
  ButtonDropdownIcon,
  ButtonMenuWrapper,
  ComponentWrapper,
} from './DropdownStyles';
import subMenuVariants from './subMenuVariants';

export type DropdownMenuWithButtonProps = {
  buttonWidth?: string;
  buttonPadding?: string;
  closeButtonText?: string;
  customMenuHeight?: string;
  initialActiveItem?: DropdownMenuItemDefinition | undefined;
  $isBelow?: boolean;
  isRightAligned?: boolean;
  menuItems: DropdownMenuItemDefinition[];
  onSelect?: (menuItem: DropdownMenuItemDefinition) => void;
  openButtonText?: string;
  sx?: Interpolation<React.CSSProperties>;
};

const ButtonMenuWrapperWithSX = styled(ButtonMenuWrapper)`
  ${({ styleOverrides }) => styleOverrides}
`;

const DropdownMenuWithButton: React.FC<DropdownMenuWithButtonProps> = ({
  buttonWidth,
  customMenuHeight,
  initialActiveItem,
  $isBelow = false,
  openButtonText = 'Open',
  closeButtonText = 'Close',
  isRightAligned = false,
  buttonPadding,
  menuItems,
  onSelect,
  sx,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOnButton, setIsOnButton] = useState(false);

  return (
    <ButtonMenuWrapperWithSX
      $buttonWidth={buttonWidth}
      data-testid="Button__Wrapper"
      isRightAligned={isRightAligned}
      styleOverrides={sx}
    >
      <div className="button-wrapper">
        <Button
          onClick={(): void => {
            setIsOpen(!isOpen);
          }}
          onMouseEnter={() => {
            setIsOnButton(true);
          }}
          onMouseLeave={() => {
            setIsOnButton(false);
          }}
          padding={buttonPadding}
        >
          <div className="button-content-wrapper">
            <span>{isOpen ? closeButtonText : openButtonText}</span>
            <ButtonDropdownIcon src={dropdownIcon} alt="" isOpen={isOpen} />
          </div>
        </Button>
      </div>

      <AnimatePresence exitBeforeEnter>
        {isOpen && (
          <OutsideClickHandler
            onOutsideClick={() => {
              if (isOpen && !isOnButton) {
                setIsOpen(false);
              }
            }}
          >
            <ComponentWrapper
              data-testid="DropdownMenuWithButton__Wrapper"
              variants={subMenuVariants}
              initial="initial"
              $isBelow={$isBelow}
              animate="enter"
              exit="exit"
            >
              <DropdownMenu
                customMenuHeight={customMenuHeight}
                menuItems={menuItems}
                initialActiveItem={initialActiveItem}
                isRightAligned={isRightAligned}
                onSelect={(item) => {
                  if (onSelect) {
                    onSelect(item);
                  }
                  setIsOpen(false);
                }}
                $isBelow={$isBelow}
              />
            </ComponentWrapper>
          </OutsideClickHandler>
        )}
      </AnimatePresence>
    </ButtonMenuWrapperWithSX>
  );
};

export default DropdownMenuWithButton;

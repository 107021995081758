/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useState, useEffect } from 'react';
import type { Row, TableInstance } from 'react-table';
import SortingArrowSvg from '../../assets/icon-sort.svg';
import ArrowSvg from '../../assets/arrow.svg';
import {
  ColumnHead,
  HeadRow,
  Table,
  TableHead,
  TableBody,
  TableRow,
  RowCell,
  SorterArrow,
  PaginationWrapper,
  PaginationButton,
  PageNumberInput,
  PageCountText,
  PageNavigationWrapper,
  BackPaginationArrow,
  ForwardPaginationArrow,
  TableComponentWrapper,
  PaginationDivider,
} from './TableStyling';
import PaginationDropdown from './PaginationDropdown';

// eslint-disable-next-line @typescript-eslint/ban-types
export interface TableProps<T extends object = {}> {
  tableInstance: TableInstance<T>;
  handleRowClick?: (row: Row<T>) => void;
  handlePaginationChange?: (currentPage: number, pageSize: number) => void;
  total?: number;
  passedPageSize?: number;
  resetToOne?: boolean;
  handleSortChange?: (sortBy: any) => void;
  'data-testid'?: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const TFTable = <T extends object = {}>({
  tableInstance,
  handleRowClick,
  total,
  handlePaginationChange,
  passedPageSize,
  resetToOne,
  handleSortChange,
  'data-testid': dataTestId = 'Table',
}: TableProps<T>): ReactElement => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    pageCount,
    rows,
    state: { pageIndex, pageSize, sortBy },
    gotoPage,
    setPageSize,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
  } = tableInstance;
  const [currentPage, setCurrentPage] = useState<null | number>(pageIndex + 1);

  useEffect(() => {
    if (currentPage) {
      gotoPage(currentPage - 1);
    }
  }, [currentPage, gotoPage]);

  useEffect(() => {
    if (resetToOne) setCurrentPage(1);
  }, [resetToOne]);

  useEffect(() => {
    if (handleSortChange) {
      handleSortChange(sortBy);
    }
  }, [handleSortChange, sortBy]);

  useEffect(() => {
    if (handlePaginationChange && currentPage !== null) {
      handlePaginationChange(currentPage, pageSize);
    }
  }, [
    pageIndex,
    pageCount,
    pageSize,
    currentPage,
    rows.length,
    handlePaginationChange,
  ]);

  return (
    <TableComponentWrapper>
      <Table
        data-testid={`${dataTestId}`}
        {...getTableProps()}
        autoResetHiddenColumns={false}
      >
        <TableHead data-testid={`${dataTestId}_tableHead`}>
          {headerGroups.map((headerGroup, i) => (
            <HeadRow
              {...headerGroup.getHeaderGroupProps()}
              data-testid={`${dataTestId}_headRow${i}`}
            >
              {headerGroup.headers.map((column, j) => (
                <ColumnHead
                  width={column.width}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  data-testid={`${dataTestId}_headRow${i}-column${j}`}
                  active={column.isSorted}
                >
                  {column.render('Header')}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <SorterArrow src={SortingArrowSvg} alt="arrow" up />
                    ) : (
                      <SorterArrow src={SortingArrowSvg} alt="arrow" />
                    )
                  ) : null}
                </ColumnHead>
              ))}
            </HeadRow>
          ))}
        </TableHead>
        <TableBody
          {...getTableBodyProps()}
          data-testid={`${dataTestId}_tableBody`}
        >
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow
                hasRowActions={Boolean(handleRowClick)}
                tabIndex={0}
                onClick={() => handleRowClick && handleRowClick(row)}
                {...row.getRowProps()}
                data-testid={`${dataTestId}_tableRow${i}`}
              >
                {row.cells.map((cell, index) => {
                  return (
                    <RowCell
                      data-testid={`${dataTestId}_tableRow${i}_tableColumn${index}`}
                      colSpan={cell.value && cell.value.colSpan}
                      isHidden={cell.value && cell.value.isHidden}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </RowCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {(total || rows.length) > 10 && (
        <>
          <PaginationDivider />
          <PaginationWrapper data-testid={`${dataTestId}_paginationWrapper`}>
            {window.innerWidth > 770 ? <div /> : null}
            <div>
              <PaginationDropdown
                pageSize={passedPageSize || pageSize}
                setPageSize={(e) => {
                  setPageSize(e);
                  setCurrentPage(1);
                }}
              />
            </div>
            <div>
              <PageNavigationWrapper
                data-testid={`${dataTestId}_pageNavigationWrapper`}
              >
                <PaginationButton
                  onClick={() => {
                    if (currentPage) {
                      setCurrentPage(currentPage - 1);
                      previousPage();
                    } else {
                      // page index is offset by 1 so we just pass it along as is
                      setCurrentPage(pageIndex);
                    }
                  }}
                  disabled={!canPreviousPage}
                  data-testid={`${dataTestId}_backPaginationButton`}
                >
                  <BackPaginationArrow src={ArrowSvg} />
                </PaginationButton>
                <PageNumberInput
                  value={currentPage === null ? '' : currentPage}
                  onKeyPress={(event): void => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  disabled={!canNextPage}
                  onChange={(event): void => {
                    if (event.target.value === '') {
                      setCurrentPage(null);
                      return;
                    }
                    if (parseInt(event.target.value, 10) > pageCount) {
                      setCurrentPage(pageCount);
                    } else {
                      setCurrentPage(parseInt(event.target.value, 10));
                    }
                  }}
                  data-testid={`${dataTestId}_pageNumberInput`}
                />
                <PageCountText data-testid={`${dataTestId}_pageCountText`}>
                  of{' '}
                  {total
                    ? Math.ceil(total / (passedPageSize || 10))
                    : pageOptions.length}
                </PageCountText>
                <PaginationButton
                  onClick={() => {
                    if (currentPage) {
                      setCurrentPage(currentPage + 1);
                      nextPage();
                    } else {
                      // want to rely on pageIndex which is offset by 1 so + 2
                      setCurrentPage(pageIndex + 2);
                    }
                  }}
                  disabled={!canNextPage}
                  data-testid={`${dataTestId}_forwardPaginationButton`}
                >
                  <ForwardPaginationArrow src={ArrowSvg} />
                </PaginationButton>
              </PageNavigationWrapper>
            </div>
          </PaginationWrapper>
        </>
      )}
    </TableComponentWrapper>
  );
};

export default TFTable;

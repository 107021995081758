import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const ComponentWrapper = styled.div``;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 4px;
  color: ${({ theme }): string => theme.colors.black};
`;

const InputBox = styled.input<{ hasError: boolean }>`
  height: 34px;
  width: 100%;
  border-radius: 2px;
  box-sizing: border-box; // Prevent padding effecting 100% width
  padding: 0 8px;
  border: solid 1px
    ${({ hasError, theme }): string =>
      hasError ? theme.colors.red : theme.colors.black10Alpha};
  background-color: ${({ theme }): string => theme.colors.white};
  font-size: 14px;
  color: ${({ theme }): string => theme.colors.black80Alpha};
  ::placeholder {
    color: ${({ theme }): string => theme.colors.black30Alpha};
  }
  &:hover {
    border: solid 1px
      ${({ hasError, theme }): string =>
        hasError ? theme.colors.red : theme.colors.brandBlue20Alpha};
  }
  &:focus {
    outline: none;
    border: solid 1px
      ${({ hasError, theme }): string =>
        hasError ? theme.colors.red : theme.colors.brandBlue};
  }
`;

const ErrorMessage = styled.div`
  color: ${({ theme }): string => theme.colors.red};
  font-size: 12px;
`;

export interface InputProps extends React.HTMLProps<HTMLInputElement> {
  hasError?: boolean;
  errorMessage?: string;
  label?: string;
  value?: string;
  dataTestId?: string;
}

export const Input = ({
  hasError = false,
  errorMessage,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  onKeyUp,
  onKeyPress,
  placeholder,
  label,
  maxLength,
  value: initialValue = '',
  dataTestId = 'InputBox',
}: InputProps): JSX.Element => {
  const [value, setValue] = useState<string>(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  return (
    <ComponentWrapper>
      {label && <Label data-testid="Label">{label}</Label>}
      <InputBox
        hasError={hasError}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={(event) => {
          setValue(event.target.value);
          if (onChange) {
            onChange(event);
          }
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onKeyPress={onKeyPress}
        data-testid={dataTestId}
        value={value}
      />
      {errorMessage && (
        <ErrorMessage data-testid="ErrorMessage">{errorMessage}</ErrorMessage>
      )}
    </ComponentWrapper>
  );
};

export default Input;

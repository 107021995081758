/* eslint-disable max-len */
import pipe from 'lodash/fp/pipe';
import React, {
  KeyboardEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  createEditor,
  Descendant,
  Editor,
  Node as SlateNode,
  Transforms,
} from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';
import { computeDiff } from '@trustflight/tf-slate';
import styled from 'styled-components';
import { defaultValue } from '../../utils/utils';
import TrustFlightThemeProvider from '../../utils/trustflightThemeProvider';
import { Notification } from '../Notification/Notification';
import { Loading } from '../Loading/Loading';
import { removeTableNodes } from './utils/insert-utils';
import {
  cleanUpNodesBeforeSave,
  handleBackspaceInsideAlertBox,
  handleBackspaceInsideList,
  handleBackspaceInTableCell,
  handleDeleteInsideImageReference,
  handleKeyStrokesInAlertBox,
  handleKeyInsideImageRef,
  isInsideEmptyAlertBox,
  isInsideEmptyListItem,
  isInsideEmptyTableCell,
  isInsideNode,
  sanitiseText,
  handleArrowKeysInTableCell,
  withListNormalization,
} from './utils/utils';
import { verifySlateDocShape } from './utils/verify-slate-shape';
import type {
  ImageMetadata,
  FileToAddInformation,
  ImageUploadCompleteResponse,
  FailedImageUploadResponse,
} from './AddImage/Image';
import type { LinkMenuItem } from './Links/LinkMenu';
import Element from './Element';
import Leaf from './Leaf';
import withLinks from './Links/withLinks';
import withImageReferencesPlugin from './plugins/withImageReferences';
import withImages from './plugins/withImages';
import ToolBar from './ToolBar';
import { useDragAndDrop } from './DragAndDropTool';
import { RTEContextProvider } from './RTEContext';

export type RichTextEditorProps = {
  allowUploadImages?: boolean;
  controlled?: boolean;
  fixedToolbar?: boolean;
  defaultErrorValue?: string;
  editor?: Editor;
  editorId?: string;
  images?: ImageMetadata[];
  initHeight?: string;
  maxHeight?: string;
  handleImageUpload?: (file: FileToAddInformation) => void;
  imageUploadedData?: Array<
    ImageUploadCompleteResponse | FailedImageUploadResponse
  >;
  initialValue?: SlateNode[];
  isDisabled?: boolean;
  name: string;
  onChange?: (newValue: SlateNode[], callback?: unknown) => void;
  onBlur?: (name: string, value: SlateNode[]) => void;
  placeholder?: string;
  previousDocument?: SlateNode[];
  withImageReferences?: boolean;
  insertableLinks?: LinkMenuItem[];
  // Diff
  showDiffButton?: boolean;
  showDiff?: boolean;
  allowDragAndDrop?: boolean;
  loading?: boolean;
};

const OuterWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const Wrapper = styled.div<
  Pick<
    RichTextEditorProps,
    'initHeight' | 'maxHeight' | 'isDisabled' | 'loading'
  >
>`
  background-color: var(--white);
  border: solid 1px ${({ theme }): string => theme.colors.black10Alpha};
  border-radius: 2px;
  max-width: 100%;
  min-height: 300px;
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}` : 'none')};
  height: ${({ initHeight }) => (initHeight ? `${initHeight}` : 'auto')};
  overflow-y: scroll;
  padding: ${({ isDisabled }) => (isDisabled ? '10px' : '0 10px 10px')};
  resize: both;
  :focus-within {
    border-color: ${({ theme }): string => theme.colors.brandBlue} !important;
  }
  :hover {
    border-color: ${({ theme }): string => theme.colors.brandBlue20Alpha};
  }
  position: relative;
  overflow: ${({ loading }) => (loading ? 'hidden' : 'auto')};
`;

const StyledEditable = styled(Editable)`
  min-height: 250px !important;
  &:focus {
    outline: none;
  }
`;

const LoadingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  margin: 0;
  padding: 0;
`;

export const withPlugins = pipe(
  withReact,
  withHistory,
  withImages,
  withImageReferencesPlugin,
  withLinks,
  withListNormalization
);

const defaultErrorContent =
  'An error occurred while attempting to save the content.';

const handleSetDiffValue = (
  initialValue: SlateNode = defaultValue,
  previousDocument: SlateNode = undefined,
  editor: Editor,
  isDiffDisplayed: boolean
) => {
  // From MEL, empty arrays are default value on new RTE fields for reasons I am 100% not certain of.
  const curValueCheck = initialValue?.length > 0 ? initialValue : defaultValue;

  if (!isDiffDisplayed || !previousDocument) {
    return curValueCheck;
  }

  const preValueCheck =
    previousDocument?.length > 0 ? previousDocument : defaultValue;

  const diffValue = computeDiff(preValueCheck, curValueCheck, {
    isInline: editor.isInline,
    ignoreProps: ['rows', 'cols'],
  });

  return diffValue;
};

const RichTextEditor = ({
  allowUploadImages = false,
  editor: mockEditor,
  editorId,
  fixedToolbar = false,
  handleImageUpload,
  images,
  imageUploadedData,
  initialValue = defaultValue,
  isDisabled = false,
  initHeight,
  insertableLinks,
  maxHeight,
  name,
  onChange,
  onBlur,
  placeholder = '...',
  previousDocument,
  showDiffButton = false,
  showDiff = false,
  withImageReferences = false,
  defaultErrorValue = defaultErrorContent,
  allowDragAndDrop = false,
}: RichTextEditorProps): JSX.Element => {
  const editor = useMemo(() => {
    return withPlugins(!mockEditor ? createEditor() : mockEditor);
  }, [mockEditor]);
  const [resetEditor, setResetEditor] = useState(false);
  const [value, setValue] = useState<Descendant[]>(() => {
    return handleSetDiffValue(initialValue, previousDocument, editor, showDiff);
  });
  const [resetMenus] = useState(false);
  const [statefulError, setStatefulError] = useState<boolean>(false);
  const allowSave = useRef<boolean>(false);
  const [isDiffDisplayed, setIsDiffDisplayed] = useState(showDiff);
  const [activeCell, setActiveCell] = useState([]);
  const [enableDragAndDrop, setEnableDragAndDrop] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);

  const renderElement = useCallback(
    (props) => (
      <Element
        setActiveCell={setActiveCell}
        activeCell={activeCell}
        imageUploadedData={imageUploadedData}
        images={images}
        insertableLinks={insertableLinks}
        {...props}
      />
    ),
    [activeCell, images, imageUploadedData, insertableLinks]
  );
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const [autoSanitiseIsEnabled, setAutoSanitiseIsEnabled] =
    useState<boolean>(false);

  const handleOnBlur = (overrideFocus: boolean): void => {
    if (onBlur && (overrideFocus || allowSave.current) && !isDiffDisplayed) {
      try {
        verifySlateDocShape(value);
        if (onBlur) {
          onBlur(name, value);
        }
      } catch (err) {
        console.error(err);
        setStatefulError(true);
      }
    }
  };

  const handleDiffDisplaySwitch = () => {
    const reset = () => {
      // loop delete all
      editor.children.forEach(() => {
        Transforms.delete(editor, { at: [0] });
      });

      editor.children = defaultValue;
    };

    reset();

    const newValue = handleSetDiffValue(
      initialValue,
      previousDocument,
      editor,
      !isDiffDisplayed
    );

    setValue(newValue);

    // This is a major hack. We should insert these nodes programmatically with the transforms api.
    setResetEditor(!resetEditor);
    setIsDiffDisplayed(!isDiffDisplayed);
  };

  const handleEditableKeyDown = (
    event: KeyboardEvent<HTMLDivElement>
  ): void => {
    if (
      isInsideEmptyListItem(editor) &&
      (event.key === 'Backspace' || event.key === 'Enter')
    ) {
      handleBackspaceInsideList(editor, event);
    } else if (isInsideNode(editor, 'image-reference')) {
      switch (event.key) {
        case 'Backspace':
        case 'Delete':
          handleDeleteInsideImageReference({
            editor,
          });
          break;
        default:
          if (
            !event.metaKey &&
            (event.key.length === 1 || event.key === 'Enter')
          ) {
            handleKeyInsideImageRef(editor, event);
          }
      }
    } else if (isInsideEmptyTableCell(editor) && event.key === 'Backspace') {
      handleBackspaceInTableCell(editor, event);
    } else if (isInsideEmptyAlertBox(editor) && event.key === 'Backspace') {
      handleBackspaceInsideAlertBox(editor);
    } else if (event.key === 'Enter') {
      handleKeyStrokesInAlertBox({ editor, event });
    } else if (event.shiftKey && event.key === 'ArrowDown') {
      handleKeyStrokesInAlertBox({ editor, event, isExitingBox: true });
    } else if (isInsideNode(editor, 'table-cell')) {
      if (
        event.key === 'ArrowUp' ||
        event.key === 'ArrowDown' ||
        event.key === 'ArrowLeft' ||
        event.key === 'ArrowRight'
      ) {
        event.preventDefault();
        handleArrowKeysInTableCell(editor, event.key);
      }
    }
  };

  useDragAndDrop(editor, enableDragAndDrop, setIsImageUploading, onChange);

  const handleToggleDragAndDrop = () => {
    setEnableDragAndDrop(!enableDragAndDrop);
  };

  const checkIfDisabled = isDiffDisplayed || isDisabled || isImageUploading;

  return (
    <TrustFlightThemeProvider>
      {statefulError && (
        <div style={{ marginBottom: '10px' }}>
          <Notification type="error">{defaultErrorValue}</Notification>
        </div>
      )}
      <RTEContextProvider isDisabled={checkIfDisabled}>
        <OuterWrapper>
          {isImageUploading && (
            <LoadingOverlay>
              <Loading loading contain hasTransparentBackground />
            </LoadingOverlay>
          )}
          <Wrapper
            key={`${resetEditor}`}
            data-testid="RichTextEditor"
            initHeight={initHeight}
            loading={isImageUploading}
            maxHeight={maxHeight}
            isDisabled={isDisabled}
          >
            <Slate
              editor={editor}
              initialValue={value}
              onChange={(newValue: SlateNode) => {
                setValue(newValue);

                try {
                  const cleanedValueToSave = cleanUpNodesBeforeSave(newValue);
                  verifySlateDocShape(cleanedValueToSave);

                  if (onChange) {
                    if (isDiffDisplayed) {
                      // edit with diff is not supported yet. Requires integrating transforms API for adding insert/del nodes.
                      return;
                    }
                    onChange(cleanedValueToSave);
                  }
                } catch (err) {
                  console.error(err);
                  setStatefulError(true);
                }
              }}
            >
              {!isDisabled && (
                <ToolBar
                  allowUploadImages={allowUploadImages}
                  editor={editor}
                  fixedToolbar={fixedToolbar}
                  onBlur={handleOnBlur}
                  handleDiffDisplaySwitch={handleDiffDisplaySwitch}
                  handleImageUpload={handleImageUpload}
                  images={images as Descendant[]}
                  insertableLinks={insertableLinks}
                  resetMenus={resetMenus}
                  value={value as Descendant[]}
                  onSanitiseChange={(sanitiseValue) => {
                    setAutoSanitiseIsEnabled(sanitiseValue);
                  }}
                  withImageReferences={withImageReferences}
                  showDiffButton={showDiffButton}
                  showDiff={isDiffDisplayed}
                  enableDragAndDrop={enableDragAndDrop}
                  allowDragAndDrop={allowDragAndDrop}
                  handleToggleDragAndDrop={handleToggleDragAndDrop}
                />
              )}
              <StyledEditable
                id={editorId}
                data-testid={editorId || 'slate-editor-test-id'}
                readOnly={checkIfDisabled}
                onKeyDown={(event) => handleEditableKeyDown(event)}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder={placeholder}
                onBlur={() => {
                  handleOnBlur(true);
                }}
                disabled={isDisabled || isImageUploading}
                onPaste={(event) => {
                  const { clipboardData } = event;

                  if (autoSanitiseIsEnabled) {
                    event.preventDefault();
                    const paste = sanitiseText(clipboardData.getData('text'));

                    Editor.insertText(editor, paste);
                    return;
                  }

                  if (
                    clipboardData.types.includes(
                      'application/x-slate-fragment'
                    ) &&
                    clipboardData.getData('application/x-slate-fragment')
                  ) {
                    event.preventDefault();
                    const decoded = decodeURIComponent(
                      window.atob(
                        clipboardData.getData('application/x-slate-fragment')
                      )
                    );

                    const parsed = JSON.parse(decoded) as Node[];
                    const isInsideTable = isInsideNode(editor, 'table');

                    if (isInsideTable) {
                      const removedTableNodes = removeTableNodes(parsed);

                      Editor.insertFragment(editor, removedTableNodes);
                      return;
                    }

                    Editor.insertFragment(editor, parsed);
                  }

                  // Turn this on as starter code for half implemented functionality needed
                  // for deseralization of pasted html
                  // handlePasteHTMLDeserialization(clipboardData, editor);
                }}
              />
            </Slate>
          </Wrapper>
        </OuterWrapper>
      </RTEContextProvider>
    </TrustFlightThemeProvider>
  );
};

export default RichTextEditor;

import React from 'react';
import styled from 'styled-components';

export type BadgeProps = {
  color: string;
};

type GradientType = {
  from: string;
  to: string;
};
const Oval = styled.div<{ fromColor: string; toColor: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-image: ${({ fromColor, toColor }): string =>
    `linear-gradient(to bottom, ${fromColor}, ${toColor});`};
`;

export const Badge: React.FC<BadgeProps> = ({ color }) => {
  const fillColor = (): GradientType => {
    if (color === 'amber') return { from: '#fad288', to: '#f2a650' };
    if (color === 'blue') return { from: '#81BFFF', to: '#57AAFF' };
    if (color === 'grey') return { from: '#d2d2d2', to: '#e0e0e0' };
    if (color === 'red') return { from: '#ff747d', to: '#ff4040' };
    return { from: '#4aa91c', to: '#7bc88d' };
  };

  const gradient = fillColor();

  return (
    <Oval data-testid="Badge" fromColor={gradient.from} toColor={gradient.to} />
  );
};

export default Badge;

/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import UploadImageError from '../../assets/empty-state-attachments.svg';
import { DropAnimation } from './DropAnimation';
import HoverAnimation from './HoverAnimation';
import { LoadingAnimation } from './LoadingAnimation';
import UnsupportedAnimation from './UnsupportedAnimation';

export const UploadBox = styled.span<{
  $fileIsUploading: boolean;
  $error: boolean;
  $isSelected?: boolean;
}>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  border: dashed 1px
    ${({ theme, $error }): string =>
      !$error ? theme.colors.brandBlue : theme.colors.errorRed};
  background: ${({ theme }): string => theme.colors.white};
  text-align: center;
  box-shadow: ${({ $isSelected }): string =>
    $isSelected ? '0 0 0 3px #B4D5FF' : 'none'};
  &:hover {
    ${({ $fileIsUploading }): string =>
      $fileIsUploading ? 'cursor: wait;' : 'cursor: pointer;'};
  }
`;

export const DropzoneContentWrapper = styled.span`
  width: 100%;
`;

const FileInput = styled.input``;

const FileTypes = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }): string => theme.colors.black90Alpha};
`;

export const Instructions = styled.span`
  font-size: 14px;
  padding-top: 4px;
  color: ${({ theme }): string => theme.colors.black50Alpha};
  padding-left: 20px;
  padding-right: 20px;
`;

export const FailureMessageContainer = styled.span`
  padding-top: 4px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const FailureMessageTitle = styled.strong`
  color: ${({ theme }): string => theme.colors.errorRed};
  font-size: 16px;
  font-weight: 600;
`;

export const FailureMessageContent = styled.span`
  display: block;
  color: ${({ theme }): string => theme.colors.black90Alpha};
  font-size: 14px;
  margin: 0;
`;

const Browser = styled.span`
  color: ${({ theme }): string => theme.colors.brandBlue};
`;

export type DropzoneProps = {
  onDrop: (acceptedFiles: File[]) => void;
  accept?: string;
  'data-testid'?: string;
  activeUploadFilename?: string;
};

export const ImageUploadLoaderDisplay = ({
  uploadingFileName = '',
  dataTestId = 'UpLoadingImageLoader',
}: {
  uploadingFileName: string;
  dataTestId?: string;
}): React.ReactElement => {
  return (
    <DropzoneContentWrapper>
      <LoadingAnimation />
      <Instructions data-testid={`${dataTestId}_CurrentlyUploadingText`}>
        Uploading {uploadingFileName}
      </Instructions>
    </DropzoneContentWrapper>
  );
};

export const ImageUploadFailureContent = ({
  dataTestId = 'UpLoadingImageFailure',
  message = 'There was an error uploading your image',
}: {
  dataTestId?: string;
  message?: string;
}): React.ReactElement => {
  return (
    <DropzoneContentWrapper>
      <img src={UploadImageError} alt="Image Upload Failed Icon" />
      <FailureMessageContainer data-testid={`${dataTestId}_UploadFailed`}>
        <FailureMessageContent>
          <FailureMessageTitle>Upload failed</FailureMessageTitle> <br />
          {message}
        </FailureMessageContent>
      </FailureMessageContainer>
    </DropzoneContentWrapper>
  );
};

export const Dropzone: React.FC<DropzoneProps> = ({
  onDrop: handleDrop,
  accept = 'image/jpeg, .png, application/pdf',
  'data-testid': dataTestId = 'Dropzone',
  activeUploadFilename,
}) => {
  const validFileTypes = accept
    .split(',')
    .map((acceptedType) => {
      const trimmedType = acceptedType.trim();
      if (trimmedType.includes('.')) {
        // just assume it's a .* ext provided
        return trimmedType.toUpperCase();
      }
      if (trimmedType.includes('/')) {
        // assuming it's a mimetype like image/jpeg
        return `.${trimmedType.split('/')[1].toUpperCase()}`;
      }
      return '';
    })
    .join(' ');

  const [fileHasBeenDraggedIn, setFileHasBeenDraggedIn] =
    useState<boolean>(false);
  const [uploadingFileName, setUploadingFileName] = useState<
    string | undefined
  >(activeUploadFilename);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setFileHasBeenDraggedIn(false); // reset flag for animation
      await handleDrop(acceptedFiles);
      setUploadingFileName(undefined);
    },
    [handleDrop]
  );

  const onDragEnter = useCallback(() => {
    if (!fileHasBeenDraggedIn) {
      setFileHasBeenDraggedIn(true);
    }
  }, [fileHasBeenDraggedIn]);

  const onDragLeave = useCallback(() => {
    if (fileHasBeenDraggedIn) {
      setFileHasBeenDraggedIn(false);
    }
  }, [fileHasBeenDraggedIn]);

  const [invalidFileDropped, setInvalidFileDropped] = useState<boolean>(false);

  const onDropRejected = useCallback(() => {
    setFileHasBeenDraggedIn(false);
    setInvalidFileDropped(true);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDropAccepted: onDrop,
    onDragEnter,
    onDragLeave,
    onDropRejected,
  });
  const [playHoverAnimation, setPlayHoverAnimation] = useState<boolean>(false);

  useEffect(() => {
    setUploadingFileName(activeUploadFilename);
  }, [activeUploadFilename]);
  return (
    <UploadBox
      {...getRootProps()}
      $fileIsUploading={Boolean(uploadingFileName)}
      $error={invalidFileDropped}
      data-testid={dataTestId}
      onMouseEnter={() => {
        setPlayHoverAnimation(true);
      }}
      onMouseLeave={() => {
        setPlayHoverAnimation(false);
      }}
    >
      {uploadingFileName ? (
        <ImageUploadLoaderDisplay
          dataTestId={dataTestId}
          uploadingFileName={uploadingFileName}
        />
      ) : (
        <DropzoneContentWrapper>
          <FileInput
            {...getInputProps()}
            data-testid={`${dataTestId}_FileInput`}
          />
          {fileHasBeenDraggedIn && <DropAnimation />}
          {!fileHasBeenDraggedIn && !invalidFileDropped && (
            <HoverAnimation play={playHoverAnimation} />
          )}
          {invalidFileDropped && !fileHasBeenDraggedIn && (
            <UnsupportedAnimation
              onAnimationEnd={() => {
                setInvalidFileDropped(false);
              }}
            />
          )}

          <FileTypes data-testid={`${dataTestId}_FileTypes`}>
            {validFileTypes}
          </FileTypes>
          <Instructions data-testid={`${dataTestId}_UploadInstruction`}>
            Drag and drop your file here or <Browser>browse</Browser>
          </Instructions>
        </DropzoneContentWrapper>
      )}
    </UploadBox>
  );
};

export default Dropzone;

/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import styled from 'styled-components';
import Unsupported from './unsupported.json';

const Wrapper = styled.div`
  background: transparent;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const Animation = styled(Player)<{ size: number }>`
  height: 95px;
  width: 100px;
  padding-bottom: 5px;
`;

export type UnsupportedAnimationProps = {
  onAnimationEnd: () => void;
};

export const UnsupportedAnimation: React.FC<UnsupportedAnimationProps> = ({
  onAnimationEnd,
}) => {
  return (
    <Wrapper data-testid="UnsupportedAnimation">
      <LottieWrapper>
        <Animation
          src={Unsupported}
          autoplay
          size={95} // Assuming size is the height in pixels, as per the styled component
          onEvent={(event: string) => {
            if (event === 'complete') {
              onAnimationEnd();
            }
          }}
        />
      </LottieWrapper>
    </Wrapper>
  );
};

export default UnsupportedAnimation;

import React, { useState } from 'react';
import ShowHistoryDiffIconSvg from '../../../assets/RichTeIcons/icon-show-history-diff.svg';
import {
  ToolButton,
  ToolButtonWrapper,
  ShowDiffButtonIcon,
} from '../ToolBarStyles';
import ToolTip from '../ToolTip';
import { useRTEContext } from '../RTEContext';

interface ShowDiffButtonProps {
  isDiffDisplayed: boolean;
  switchDisplayHandler: () => void;
}

export default ({
  isDiffDisplayed = false,
  switchDisplayHandler,
}: ShowDiffButtonProps): JSX.Element => {
  const [tooltipIsVisible, setTooltipIsVisible] = useState<boolean>(false);
  const { dispatch } = useRTEContext();

  return (
    <ToolButtonWrapper>
      <ToolTip
        text="Track changes and show markups for edits in this field."
        $isVisible={tooltipIsVisible}
      >
        <ToolButton
          role="button"
          aria-pressed={isDiffDisplayed}
          aria-label="Toggle showing differences between last and current document"
          data-testid="ShowDiffButton"
          onClick={() => {
            dispatch({ type: 'toggle-user-disabled' });
            switchDisplayHandler();
          }}
          onMouseEnter={() => {
            setTooltipIsVisible(true);
          }}
          onMouseLeave={() => {
            setTooltipIsVisible(false);
          }}
        >
          <ShowDiffButtonIcon
            src={ShowHistoryDiffIconSvg}
            $isActive={isDiffDisplayed}
          />
        </ToolButton>
      </ToolTip>
    </ToolButtonWrapper>
  );
};

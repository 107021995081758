/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import styled from 'styled-components';
import Hover from './hover.json';

const Wrapper = styled.div`
  background: transparent;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const Animation = styled(Player)<{ size: number }>`
  height: 95px;
  width: 100px;
  padding-bottom: 5px;
`;

type HoverAnimationProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  play: boolean;
};

interface LottieInstance {
  play: () => void;
  setSeeker: (frame: number, play: boolean) => void;
}

export const HoverAnimation: React.FC<HoverAnimationProps> = ({ play }) => {
  const lottieRef = useRef<LottieInstance>();
  useEffect(() => {
    if (play && lottieRef.current) {
      lottieRef.current.play();
    } else if (!play && lottieRef.current) {
      lottieRef.current.setSeeker(0, false);
    }
  });
  return (
    <Wrapper data-testid="HoverAnimation">
      <LottieWrapper>
        <Animation src={Hover} loop ref={lottieRef} size={100} />
      </LottieWrapper>
    </Wrapper>
  );
};

export default HoverAnimation;

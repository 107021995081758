import React, { useState } from 'react';
import DropdownMenuItem from './DropdownMenuItem';
import { ComponentWrapper, MenuWrapper } from './DropdownStyles';

export type DropdownMenuItemDefinition = {
  label: string | React.ReactElement;
  subItems?: DropdownMenuItemDefinition[];
  hasWarningStyle?: boolean;
  onSelect?: () => void;
  value?: string;
};

export type DropdownMenuProps = {
  customMenuHeight?: string;
  initialActiveItem?: DropdownMenuItemDefinition | undefined;
  $isBelow?: boolean;
  isRightAligned?: boolean;
  menuItems: DropdownMenuItemDefinition[];
  onSelect?: (menuItem: DropdownMenuItemDefinition) => void;
};

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  customMenuHeight,
  initialActiveItem,
  $isBelow = false,
  isRightAligned = false,
  menuItems,
  onSelect: onMenuItemSelect,
}) => {
  const [activeItem, setActiveItem] = useState<
    DropdownMenuItemDefinition | undefined
  >(initialActiveItem);
  return (
    <ComponentWrapper
      $isBelow={$isBelow}
      data-testid="Dropdown__Wrapper"
      onMouseLeave={() => {
        setActiveItem(undefined);
      }}
    >
      <MenuWrapper
        $customMenuHeight={customMenuHeight}
        isRightAligned={isRightAligned}
      >
        {menuItems.map((menuItem, index) => {
          return (
            <DropdownMenuItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              activeItem={activeItem}
              index={index.toString()}
              isRightAligned={isRightAligned}
              menuItem={menuItem}
              setActiveItem={setActiveItem}
              onMenuItemSelect={onMenuItemSelect}
            />
          );
        })}
      </MenuWrapper>
    </ComponentWrapper>
  );
};

export default DropdownMenu;

/* eslint-disable no-param-reassign */
import type { Editor } from 'slate';

export default (editor: Editor): Editor => {
  const { isInline, isElementReadOnly } = editor;
  editor.isInline = (element) => {
    return element.type === 'link' ? true : isInline(element);
  };

  editor.isElementReadOnly = (element) => {
    return element.type === 'link' ? true : isElementReadOnly(element);
  };

  return editor;
};

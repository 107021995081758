/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';

export const ToolTipHover = styled.div<{
  $isVisible: boolean;
  $controlTipWidth?: number;
  $topOffset: number;
}>`
  font-size: 12px;
  white-space: ${({ $controlTipWidth }): string =>
    $controlTipWidth ? `${$controlTipWidth}px;` : 'nowrap;'};
  position: absolute;
  top: ${({ $topOffset }): string => `${$topOffset}px`};
  min-inline-size: ${({ $controlTipWidth }): string =>
    $controlTipWidth ? `${$controlTipWidth}px;` : 'unset;'};
  overflow-wrap: break-word;
  background-color: ${({ theme }): string => theme.colors.navyDark};
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 2px;
  padding: 5px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  display: ${({ $isVisible }): string => ($isVisible ? 'block;' : 'none;')};
  @media only screen and (max-width: 1050px) {
    display: none;
  }
  &::after {
    content: ' ';
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent
      ${({ theme }): string => theme.colors.navyDark} transparent;
  }
`;

type ToolTipProps = {
  children: JSX.Element | JSX.Element[];
  text: string;
  $isVisible: boolean;
  $controlTipWidth?: number;
  $topOffset?: number;
};

export default ({
  children,
  text,
  $isVisible,
  $controlTipWidth = 0,
  $topOffset = 35,
}: ToolTipProps): JSX.Element => {
  const capitalizeFirstLetter = text.replace(
    text.charAt(0),
    text.charAt(0).toUpperCase()
  );

  return (
    <>
      <ToolTipHover
        $controlTipWidth={$controlTipWidth}
        $topOffset={$topOffset}
        $isVisible={$isVisible}
      >
        {capitalizeFirstLetter}
      </ToolTipHover>
      {children}
    </>
  );
};

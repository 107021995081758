import type { MenuItem } from '../../ThreeDotMenu/ThreeDotMenu';

interface HandlePortalMenuKeyboardNavArgs {
  activeItem: number | null | undefined;
  event:
    | React.KeyboardEvent<HTMLDivElement>
    | React.KeyboardEvent<HTMLButtonElement>;
  isMenuOpen: boolean;
  menuItems: MenuItem[];
  setActiveItem: React.Dispatch<
    React.SetStateAction<number | null | undefined>
  >;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default ({
  activeItem,
  event,
  isMenuOpen,
  menuItems,
  setActiveItem,
  setIsMenuOpen,
}: HandlePortalMenuKeyboardNavArgs): void => {
  // function for keyboard accessibility on menu
  if (!isMenuOpen) {
    if (event.key === 'ArrowDown') {
      setIsMenuOpen(true);
    }
    return;
  }

  switch (event.key) {
    case 'ArrowDown':
    case 'Tab': {
      event.preventDefault();
      if (!activeItem && activeItem !== 0) {
        // no active item so just go with hte first
        setActiveItem(0);
      } else if (
        (activeItem || activeItem === 0) &&
        menuItems.length > activeItem + 1
      ) {
        // not on the last item so go to next
        setActiveItem(activeItem + 1);
      } else {
        // only other scenario is it is last, so lets loop back
        setActiveItem(0);
      }
      break;
    }
    case 'ArrowUp':
      event.preventDefault();
      if (!activeItem || activeItem === 0) {
        // nothing active or at the end
        setActiveItem(menuItems.length - 1);
      } else if (activeItem) {
        // go to the previous
        setActiveItem(activeItem - 1);
      }
      break;
    case 'Enter' || 'Space':
      event.preventDefault();
      if (activeItem || activeItem === 0) {
        // select active item
        setIsMenuOpen(false);
        menuItems[activeItem].onSelect(event);
        setActiveItem(null);
      }
      break;
    case 'Escape':
      event.preventDefault();
      setIsMenuOpen(false);
      setActiveItem(null);
      break;
    default:
  }
};

/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-expressions */
import { AnimatePresence } from 'framer-motion';
import { some } from 'lodash';
import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import iconChevron from '../../assets/icon-chevron.svg';
import TruncateString from '../../utils/TruncateString';
import type { DropdownMenuItemDefinition } from './DropdownMenu';
import {
  ItemChevron,
  MenuItemWrapper,
  SubItemMenu,
  SubItemsWrapper,
  SubMenuWrapper,
} from './DropdownStyles';
import subMenuVariants from './subMenuVariants';

type DropdownSubmenuProps = {
  subItems: DropdownMenuItemDefinition[];
  onMenuItemSelect?: (menuItem: DropdownMenuItemDefinition) => void;
  isRightAligned: boolean;
  indexPrefix: string;
  hasWarningStyle?: boolean;
};

const DropdownSubmenu: React.FC<DropdownSubmenuProps> = ({
  isRightAligned,
  indexPrefix,
  onMenuItemSelect,
  subItems,
  hasWarningStyle = false,
}) => {
  const [activeItem, setActiveItem] = useState<
    DropdownMenuItemDefinition | undefined
  >();
  return (
    <SubItemsWrapper isRightAligned={isRightAligned}>
      <SubItemMenu isRightAligned={isRightAligned} className="sub-item-menu">
        {subItems.map((subItem, index) => {
          const subMenuKey = `${indexPrefix}${index}`;

          return (
            <DropdownMenuItem
              key={subMenuKey}
              activeItem={activeItem}
              menuItem={subItem}
              setActiveItem={setActiveItem}
              onMenuItemSelect={onMenuItemSelect}
              isRightAligned={isRightAligned}
              index={subMenuKey}
              parentHasWarningStyle={hasWarningStyle}
            />
          );
        })}
      </SubItemMenu>
    </SubItemsWrapper>
  );
};

type DropdownMenuItemProps = {
  activeItem?: DropdownMenuItemDefinition;
  menuItem: DropdownMenuItemDefinition;
  setActiveItem: (item: DropdownMenuItemDefinition | undefined) => void;
  onMenuItemSelect?: (menuItem: DropdownMenuItemDefinition) => void;
  isRightAligned?: boolean;
  index: string;
  parentHasWarningStyle?: boolean;
};

const DropdownMenuItem: React.FC<DropdownMenuItemProps> = ({
  activeItem,
  setActiveItem,
  menuItem,
  isRightAligned = false,
  index,
  onMenuItemSelect,
  parentHasWarningStyle = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (activeItem === menuItem) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [activeItem, menuItem]);

  const { label, subItems, hasWarningStyle } = menuItem;

  const hasSubItems = subItems && some(subItems);
  const isStringLabel = typeof label === 'string';
  const uniqueTestId = isStringLabel ? label : index;

  const handleSelect = () => {
    if (!hasSubItems) {
      const { onSelect } = menuItem;
      if (onSelect) {
        onSelect();
      }
      if (onMenuItemSelect) {
        onMenuItemSelect(menuItem);
      }
      setActiveItem(undefined);
      setIsExpanded(false);
    }
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (isExpanded) {
          setIsExpanded(false);
        }
      }}
    >
      <MenuItemWrapper
        data-testid={`MenuItemWrapper__${uniqueTestId}`}
        tabIndex={0}
        key={`MenuItem__${uniqueTestId}`}
        $hasWarningStyle={parentHasWarningStyle || hasWarningStyle}
        isRightAligned={isRightAligned}
        isStringLabel={isStringLabel}
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
          const { key } = event;
          if (!hasSubItems) {
            if (key === 'Enter') {
              handleSelect();
            }
          }
          if (hasSubItems) {
            if (key === 'ArrowRight' || key === 'Enter')
              setActiveItem(menuItem);
          }
        }}
        onMouseEnter={() => {
          setActiveItem(menuItem);
        }}
        onClick={() => {
          handleSelect();
        }}
      >
        <span>
          <TruncateString
            className="dropdown-menu-item-truncate"
            maxWidth={isStringLabel ? '225px' : '270px'}
          >
            {label}
          </TruncateString>
        </span>
        {hasSubItems && (
          <ItemChevron
            src={iconChevron}
            $hasWarningStyle={parentHasWarningStyle || hasWarningStyle}
            $isRightAligned={isRightAligned}
          />
        )}
      </MenuItemWrapper>
      <AnimatePresence exitBeforeEnter>
        {subItems && isExpanded && (
          <SubMenuWrapper
            variants={subMenuVariants}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            <DropdownSubmenu
              subItems={subItems}
              onMenuItemSelect={onMenuItemSelect}
              isRightAligned={isRightAligned}
              hasWarningStyle={parentHasWarningStyle || hasWarningStyle}
              indexPrefix={`${index}-`}
            />
          </SubMenuWrapper>
        )}
      </AnimatePresence>
    </OutsideClickHandler>
  );
};

export default DropdownMenuItem;

import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import type { Editor } from 'slate';
import OutsideClickHandler from 'react-outside-click-handler';
import icon from '../../../assets/RichTeIcons/icon-table.svg';
import EditIcon from '../../../assets/RichTeIcons/icon-edit.svg';
import { getTableDimension } from '../utils/utils';
import NumberStepper from '../../NumberStepper/NumberStepper';
import Portal from '../../../utils/Portal';
import { MenuHeader } from '../../ThreeDotMenu/PortalMenu';
import { MenuPopUpOuterWrapper, MenuPopUpWrapper } from '../ToolBarStyles';
import TablePopupButtons from './TablePopUpButtons';

const EditTableText = styled.span`
  font-weight: 500;
  line-height: normal;
  color: ${({ theme }) => theme.colors.brandBlue};
  display: flex;
  align-items: center;
  padding: 15px 0 20px 0;
`;

const TitleRow = styled.span`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
`;

const InsertTableText = styled.span`
  font-weight: 500;
  line-height: normal;
  color: ${({ theme }) => theme.colors.black};
`;

const CounterWrapper = styled.div`
  display: flex;
  padding-bottom: 10px;
`;

const StepperWrapper = styled.div`
  width: 80px;
`;

const CounterLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  padding-top: 12px;
`;

type TablePopupMenuProps = {
  editor: Editor;
  tableInEditMode: boolean;
  menuToggle: Dispatch<SetStateAction<boolean>>;
  onBlur: (overrideFocus: boolean) => void;
};

export default ({
  editor,
  tableInEditMode,
  menuToggle,
  onBlur,
}: TablePopupMenuProps): JSX.Element => {
  const parentRef = useRef<HTMLDivElement>(null);
  const [left, setLeft] = useState<number>(0);
  const [top, setTop] = useState<number>(0);
  useEffect(() => {
    if (parentRef.current) {
      const rect = parentRef.current.getBoundingClientRect();
      setTop(rect.top + window.scrollY + rect.height);
      setLeft(rect.left + window.scrollX);
    }
  }, []);
  let defaultTableDimensions: Record<string, number> = { rows: 6, cols: 4 };

  if (tableInEditMode) {
    defaultTableDimensions = getTableDimension(editor, 'both');
  }

  const [numOfColumns, updateNumOfColumns] = useState<number | null>(
    defaultTableDimensions.cols || null
  );
  const [numOfRows, updateNumOfRows] = useState<number | null>(
    defaultTableDimensions.rows || null
  );

  return (
    <MenuPopUpOuterWrapper ref={parentRef}>
      <Portal>
        <OutsideClickHandler
          onOutsideClick={() => {
            menuToggle(false);
          }}
        >
          <MenuPopUpWrapper top={top} left={left}>
            {tableInEditMode ? (
              <MenuHeader title="Edit Table" icon={EditIcon} />
            ) : (
              <MenuHeader title="Table" icon={icon} />
            )}
            <div
              style={{
                paddingLeft: '10px',
              }}
            >
              <CounterWrapper>
                <StepperWrapper>
                  <NumberStepper
                    count={numOfColumns}
                    min={1}
                    onChange={updateNumOfColumns}
                  />
                </StepperWrapper>
                <CounterLabel>Columns</CounterLabel>
              </CounterWrapper>
              <CounterWrapper>
                <StepperWrapper>
                  <NumberStepper
                    count={numOfRows}
                    min={1}
                    onChange={updateNumOfRows}
                  />
                </StepperWrapper>
                <CounterLabel>Rows</CounterLabel>
              </CounterWrapper>

              {tableInEditMode ? (
                <TablePopupButtons
                  menuToggle={menuToggle}
                  numOfColumns={numOfColumns}
                  numOfRows={numOfRows}
                  buttonLabels={{
                    primary: 'Apply Changes',
                    secondary: 'Delete',
                  }}
                  onBlur={onBlur}
                  type="editButtons"
                  deleteTable
                  editor={editor}
                />
              ) : (
                <TablePopupButtons
                  menuToggle={menuToggle}
                  numOfColumns={numOfColumns}
                  numOfRows={numOfRows}
                  onBlur={onBlur}
                  buttonLabels={{ primary: 'Insert', secondary: 'Cancel' }}
                  type="insertButtons"
                  editor={editor}
                  deleteTable={false}
                />
              )}
            </div>
          </MenuPopUpWrapper>
        </OutsideClickHandler>
      </Portal>
    </MenuPopUpOuterWrapper>
  );
};

import React, { useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import moreOptions from '../../assets/more-options.svg';
import PortalMenu from './PortalMenu';
import {
  ComponentWrapper,
  MoreOptionsButton,
  MoreOptionsIcon,
} from './ThreeDotMenuStyles';

export type MenuItem = {
  icon?: string;
  label: string | JSX.Element;
  value?: string;
  destructive?: boolean;
  onSelect: (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.KeyboardEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLButtonElement>
  ) => void;
};

export interface ThreeDotMenuProps {
  hasRoundedStyle?: boolean;
  menuItems: MenuItem[];
  tableVariant?: boolean;
  'data-testid'?: string;
}

export const ThreeDotMenu: React.FC<ThreeDotMenuProps> = ({
  hasRoundedStyle = false,
  menuItems,
  tableVariant = false,
  'data-testid': dataTestId = '0',
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<number | null>();
  const [mouseIsInsideMenu, setMouseIsInsideMenu] = useState<boolean>(false);
  const parentRef = useRef<HTMLButtonElement>(null);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (isMenuOpen && !mouseIsInsideMenu) {
          setIsMenuOpen(false);
          setActiveItem(null);
        }
      }}
    >
      <ComponentWrapper
        onKeyDown={(event) => {
          // function for keyboard accessibility on menu
          if (!isMenuOpen) {
            if (event.key === 'ArrowDown') {
              setIsMenuOpen(true);
            }
            return;
          }

          switch (event.key) {
            case 'ArrowDown':
            case 'Tab': {
              event.preventDefault();
              if (!activeItem && activeItem !== 0) {
                // no active item so just go with hte first
                setActiveItem(0);
              } else if (
                (activeItem || activeItem === 0) &&
                menuItems.length > activeItem + 1
              ) {
                // not on the last item so go to next
                setActiveItem(activeItem + 1);
              } else {
                // only other scenario is it is last, so lets loop back
                setActiveItem(0);
              }
              break;
            }
            case 'ArrowUp':
              event.preventDefault();
              if (!activeItem || activeItem === 0) {
                // nothing active or at the end
                setActiveItem(menuItems.length - 1);
              } else if (activeItem) {
                // go to the previous
                setActiveItem(activeItem - 1);
              }
              break;
            case 'Enter' || 'Space':
              event.preventDefault();
              if (activeItem || activeItem === 0) {
                // select active item
                setIsMenuOpen(false);
                menuItems[activeItem].onSelect(event);
                setActiveItem(null);
              }
              break;
            case 'Escape':
              event.preventDefault();
              setIsMenuOpen(false);
              setActiveItem(null);
              break;
            default:
          }
        }}
        data-testid={`componentWrapper-${dataTestId}`}
      >
        <MoreOptionsButton
          isMenuOpen={isMenuOpen}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setIsMenuOpen(!isMenuOpen);
          }}
          data-testid={`moreOptionsButton-${dataTestId}`}
          ref={parentRef}
        >
          <MoreOptionsIcon
            src={moreOptions}
            data-testid={`moreOptionsIcon-${dataTestId}`}
          />
        </MoreOptionsButton>
        {isMenuOpen && (
          <PortalMenu
            hasRoundedStyle={hasRoundedStyle}
            menuItems={menuItems}
            parentRef={parentRef}
            tableVariant={tableVariant}
            setMouseIsInsideMenu={setMouseIsInsideMenu}
            setIsMenuOpen={setIsMenuOpen}
            setActiveItem={setActiveItem}
            activeItem={activeItem}
          />
        )}
      </ComponentWrapper>
    </OutsideClickHandler>
  );
};

export default ThreeDotMenu;

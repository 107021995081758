import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import type { Editor } from 'slate';
import { useSlate } from 'slate-react';
import activeIcon from '../../assets/RichTeIcons/icon-table-active.svg';
import inactiveIcon from '../../assets/RichTeIcons/icon-table.svg';
import TablePopupMenu from './Table/TablePopupMenu';
import {
  ToolButton,
  ToolButtonIcon,
  ToolButtonWrapper,
  ToolButtonIconNoSVGStyles,
} from './ToolBarStyles';
import ToolTip from './ToolTip';
import { isBlockActive } from './utils/utils';
import { useRTEContext } from './RTEContext';

type TableButtonProps = {
  menuToggle: Dispatch<SetStateAction<boolean>>;
  showMenu: boolean;
  onBlur: (overrideFocus: boolean) => void;
  toggleTextMenu: (toggle: boolean) => void;
  toggleHighlightMenu: (toggle: boolean) => void;
  otherMenuStates: boolean[];
};

export default ({
  menuToggle,
  showMenu,
  onBlur,
  toggleTextMenu,
  toggleHighlightMenu,
  otherMenuStates,
}: TableButtonProps): JSX.Element => {
  const editor: Editor = useSlate();
  const [tooltipIsVisible, setTooltipIsVisible] = useState<boolean>(false);
  const { state: RTEState } = useRTEContext();
  const { userDisabled: editDisabled } = RTEState;

  const handleMenuToggle = useCallback(() => {
    if (otherMenuStates.includes(true)) {
      toggleTextMenu(false);
      toggleHighlightMenu(false);
    }
    menuToggle((menuState) => !menuState);
  }, [otherMenuStates, menuToggle, toggleHighlightMenu, toggleTextMenu]);

  const tableInEditMode = isBlockActive(editor, 'table-row');
  const icon = tableInEditMode ? activeIcon : inactiveIcon;

  return (
    <>
      <ToolButtonWrapper>
        <ToolTip text="table" $isVisible={tooltipIsVisible && !showMenu}>
          <ToolButton
            data-testid="TablePopupTriggerButton"
            disabled={editDisabled}
            $isDisabled={editDisabled}
            onMouseDown={handleMenuToggle}
            onMouseEnter={() => {
              setTooltipIsVisible(true);
            }}
            onMouseLeave={() => {
              setTooltipIsVisible(false);
            }}
            type="button"
          >
            <ToolButtonIcon
              $isActive={tableInEditMode}
              $isDisabled={editDisabled}
              src={icon}
            />
          </ToolButton>
        </ToolTip>
      </ToolButtonWrapper>
      {showMenu && (
        <TablePopupMenu
          editor={editor}
          menuToggle={menuToggle}
          tableInEditMode={tableInEditMode}
          onBlur={onBlur}
        />
      )}
    </>
  );
};

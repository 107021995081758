import React from 'react';

export const EmptySearchResults = (): JSX.Element => {
  return (
    <div style={{ textAlign: 'center' }}>
      <strong style={{ display: 'block', marginBottom: '5px' }}>
        No results found
      </strong>
      <small>Clear your search and try again.</small>
    </div>
  );
};

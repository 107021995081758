import React from 'react';
import styled from 'styled-components';
import type { MenuItem } from './PageTabs';

const TabButton = styled.div<{ isSelected: boolean }>`
  width: fit-content;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px ${({ theme }): string => theme.colors.white};
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  margin-right: 4px;
  background-color: ${({ theme }): string => theme.colors.zircon};
  font-size: 14px;
  color: ${({ isSelected, theme }): string =>
    isSelected ? theme.colors.brandBlue : theme.colors.black70Alpha};
  height: 46px;
  padding: 0 22px;
  cursor: pointer;
  border-bottom-color: ${({ isSelected, theme }): string =>
    isSelected ? theme.colors.brandBlue : theme.colors.white};
`;

const CountDisplay = styled.div<{ isSelected: boolean }>`
  width: fit-content;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  border-radius: 11px;
  flex-shrink: 0;
  background-color: ${({ isSelected, theme }): string =>
    isSelected ? theme.colors.brandBlue : theme.colors.black20Alpha};
  margin-left: 8px;
  color: ${({ theme }): string => theme.colors.white};
  padding: 0 8px;
`;

export type TabProps = {
  item: MenuItem;
  isSelected: boolean;
  onClick: () => void;
};

export const Tab: React.FC<TabProps> = ({ item, isSelected, onClick }) => {
  return (
    <TabButton
      isSelected={isSelected}
      onClick={() => {
        onClick();
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onClick();
        }
      }}
      tabIndex={0}
    >
      {item.label}
      {item.count ? (
        <CountDisplay isSelected={isSelected}>{item.count}</CountDisplay>
      ) : null}
    </TabButton>
  );
};

export default Tab;

import React, { useState } from 'react';
import styled from 'styled-components';
import iconCheckSvg from '../../assets/icon-check.svg';

const CheckboxButton = styled.button<{ isChecked: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 2px;
  padding-left: 2px;
  padding-top: 0px;
  border: solid 1px
    ${({ isChecked, theme }): string =>
      isChecked ? theme.colors.brandBlue : theme.colors.black10Alpha};
  background-color: ${({ isChecked, theme }): string =>
    isChecked ? theme.colors.brandBlue : 'white;'};
  cursor: pointer;
  transition: background-color 200ms;
  &:hover {
    border: solid 1px ${({ theme }): string => theme.colors.brandBlue20Alpha};
  }
  &:focus {
    outline: none;
    border: solid 1px ${({ theme }): string => theme.colors.brandBlue};
  }
`;

const CheckIcon = styled.img`
  position: relative;
  height: 8px;
  margin-top: -5px;
`;

export type CheckboxProps = {
  isChecked?: boolean;
  onChange: (checked: boolean) => void;
};

export const Checkbox = ({
  isChecked: initialIsChecked = false,
  onChange,
}: CheckboxProps): JSX.Element => {
  const [isChecked, setIsChecked] = useState<boolean>(initialIsChecked);
  return (
    <CheckboxButton
      isChecked={isChecked}
      onClick={() => {
        onChange(!isChecked);
        setIsChecked(!isChecked);
      }}
      tabIndex={0}
      data-testid="CheckboxButton"
    >
      <CheckIcon src={iconCheckSvg} data-testid="CheckIcon" />
    </CheckboxButton>
  );
};

export default Checkbox;

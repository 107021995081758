import styled, { DefaultTheme } from 'styled-components';
import InlineSVG from 'react-inlinesvg';

export const ToolBarWrapper = styled.div<{
  $fixedToolbar: boolean;
}>`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 10px;
  background-color: ${({ theme }): string => theme.colors.white};
  padding-top: 10px;
  position: ${({ $fixedToolbar }) => ($fixedToolbar ? `sticky` : 'relative')};
  top: 0;
  z-index: 1;
`;

export const Divider = styled.span`
  width: 1px;
  height: 16px;
  background-color: ${({ theme }): string => theme.colors.frostedSteel};
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
`;

export const UndoRedoWrapper = styled.div`
  display: flex;
  flex: 1 0 10%;
  justify-content: flex-end;
`;

export const AutoSaveToolTipContainer = styled.div`
  position: relative;
  margin-left: 5px;
`;

export const NoAutoSaveContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-left: ${({ theme }): string => theme.spacing.space8};
  padding: 0 2px;
  top: -4px;
`;

export const NoAutoSavetext = styled.small`
  color: ${({ theme }): string => theme.colors.warningYellowText};
  font-size: 75%;
`;

export const MenuPopUpOuterWrapper = styled.div`
  position: relative;
`;

export const MenuPopUpWrapper = styled.div<{ top: number; left: number }>`
  position: absolute;
  left: ${({ left }) => `${left - 40}px`};
  top: ${({ top }) => `${top + 15}px`};
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px 0 ${({ theme }) => theme.colors.black10Alpha};
  border-radius: 4px;
  background-color: white;
  /* min-height: 219px; */
  max-height: 250px;
  min-width: 223px;
  z-index: 2;
  overflow: hidden;
`;

export const ToolButtonWrapper = styled.div`
  position: relative;
`;

export const MenuDropdownWrapper = styled.div`
  position: relative;
`;

export const MenuDropdown = styled.div`
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0 0 10px 0 ${({ theme }) => theme.colors.black10Alpha};
  display: flex;
  flex-direction: column;
  left: -22px;
  min-height: 81px;
  padding: 5px;
  padding-bottom: 10px;
  position: absolute;
  top: 15px;
  width: 172px;
  z-index: 5;
`;

export const PopUpMenuTopRow = styled.div`
  display: flex;
  height: 40px;
  padding: 5px;
`;

export const PopUpMenuBottomRow = styled.div`
  display: flex;
  padding-left: 8px;
  flex-wrap: wrap;
`;

export const PopUpMenuLabel = styled.p`
  height: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: ${({ theme }) => theme.colors.black};
  margin-top: 2px;
`;

function determineIconColor(
  isDisabled: boolean,
  $isActive: boolean,
  theme: DefaultTheme
): string {
  if (isDisabled) {
    return `${theme.colors.black20Alpha};`;
  }

  if ($isActive && !isDisabled) {
    return `${theme.colors.brandBlue};`;
  }

  return `${theme.colors.black90Alpha};`;
}

export const ActiveColorIcon = styled(InlineSVG)<{
  color: string;
  $isDisabled: boolean;
}>`
  path {
    fill: ${({ $isDisabled, theme }) =>
      !$isDisabled
        ? `${theme.colors.black90Alpha};`
        : `${theme.colors.black20Alpha};`};
  }
  polygon {
    fill: ${({ $isDisabled, theme }) =>
      !$isDisabled
        ? `${theme.colors.black90Alpha};`
        : `${theme.colors.black20Alpha};`};
  }
  /* HI-LIGHT BAR IN ICON IS A RECT */
  rect {
    fill: ${({ color, $isDisabled, theme }) =>
      !$isDisabled ? color : `${theme.colors.black20Alpha};`};
    stroke: ${({ color, $isDisabled, theme }) =>
      !$isDisabled ? color : `${theme.colors.black20Alpha};`};
  }
`;

export const ToolButtonIcon = styled(InlineSVG)<{
  $isActive: boolean;
  $isDisabled: boolean;
}>`
  width: 26px;
  height: 26px;
  background-color: ${({ $isActive, theme }) =>
    $isActive ? `${theme.colors.brandBlue05Alpha}` : 'transparent;'};
  polygon {
    fill: ${({ $isActive, $isDisabled, theme }) =>
      determineIconColor($isDisabled, $isActive, theme)};
  }
  path {
    fill: ${({ $isActive, $isDisabled, theme }) =>
      determineIconColor($isDisabled, $isActive, theme)};
  }
  rect {
    fill: ${({ $isActive, $isDisabled, theme }) =>
      determineIconColor($isDisabled, $isActive, theme)};
  }
`;

export const ToolButtonIconNoSVGStyles = styled(InlineSVG)<{
  $isActive: boolean;
}>`
  width: 26px;
  height: 26px;
`;

export const ToolButtonIconImgStyles = styled.img<{
  $isActive: boolean;
}>`
  width: 26px;
  height: 26px;
`;

export const SanitiseButtonIcon = styled(InlineSVG)<{
  $isActive: boolean;
  $isDisabled: boolean;
}>`
  width: 26px;
  height: 26px;
  background-color: ${({ $isActive, theme }) =>
    $isActive ? `${theme.colors.brandBlue05Alpha}` : 'transparent;'};
  polygon {
    fill: ${({ $isActive, $isDisabled, theme }) =>
      determineIconColor($isDisabled, $isActive, theme)};
  }
  path {
    fill: ${({ $isActive, $isDisabled, theme }) =>
      determineIconColor($isDisabled, $isActive, theme)};
  }
`;

export const ToolButton = styled.button<{
  $isDisabled?: boolean;
  $isActive?: boolean;
}>`
  margin: 2px;
  border: none;
  background: transparent;
  width: 26px;
  height: 26px;
  padding: 0;
  .add-image {
    width: 26px;
    height: auto;
  }
  &:hover {
    cursor: ${({ $isDisabled }) => (!$isDisabled ? 'pointer;' : 'default;')};
    img,
    ${ToolButtonIcon},
      ${ActiveColorIcon},
      ${SanitiseButtonIcon},
      ${ToolButtonIconNoSVGStyles} {
      ${({ $isDisabled, theme }) =>
        !$isDisabled
          ? `background-color: ${theme.colors.brandBlue05Alpha};`
          : ''};
    }
  }
  &:focus {
    outline: none;
  }
  polygon {
    fill: ${({ $isActive, $isDisabled, theme }) =>
      determineIconColor(!!$isDisabled, !!$isActive, theme)};
  }
  path {
    fill: ${({ $isActive, $isDisabled, theme }) =>
      determineIconColor(!!$isDisabled, !!$isActive, theme)};
  }
`;

export const toolBarColors: Record<string, Record<string, string>> = {
  textColor: {
    white: 'var(--fog)',
    black: 'var(--rich-text-black)',
    grey: 'var(--black-70)',
    lightGrey: 'var(--black-40)',
    blue: 'var(--rich-text-blue)',
    lightBlue: 'var(--jordy)',
    green: 'var(--rich-text-green)',
    purple: 'var(--rich-text-pink)',
    red: 'var(--red)',
    orange: 'var(--caution-box-orange)',
    lightOrange: 'var(--rich-text-yellow)',
    yellow: 'var(--rich-text-fluorescent-yellow)',
  },
  highlightColor: {
    black: 'var(--rich-text-black)',
    grey: 'var(--black-70)',
    lightGrey: 'var(--black-40)',
    lightBlue: 'var(--jordy)',
    green: 'var(--rich-text-green)',
    pink: 'var(--rich-text-pink)',
    red: 'var(--red)',
    orange: 'var(--caution-box-orange)',
    lightOrange: 'var(--rich-text-yellow)',
    flYellow: 'var(--rich-text-fluorescent-yellow)',
  },
};

export const HistoryButtonIcon = styled.img<{ disabled: boolean }>`
  opacity: ${({ disabled }) => (disabled ? '20%' : '100%')};
`;

export const ShowDiffButtonIcon = styled(InlineSVG)<{ $isActive: boolean }>`
  width: 22px;
  height: 22px;
  background-color: var(--brand-blue-5);
  padding: 1px;
  border-radius: 2px;
  background-color: ${({ $isActive }) =>
    $isActive ? 'var(--brand-blue-5)' : 'transparent;'};
  polygon {
    fill: ${({ $isActive }) => ($isActive ? 'var(--brand-blue)' : 'black;')};
  }
  path {
    fill: ${({ $isActive }) => ($isActive ? 'var(--brand-blue)' : 'black;')};
  }
`;

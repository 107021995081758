import React, { ReactNode } from 'react';
import styled from 'styled-components';
import type { Element } from 'slate';
import { DiffOperationType } from '@trustflight/tf-slate';

import { DeleteInlineBlock, InsertInlineBlock } from '../Diff/DiffElements';
import LinkIconActive from '../../../assets/RichTeIcons/icon-link-active.svg';
import LinkIconBroken from '../../../assets/RichTeIcons/icon-link-broken.svg';
import type { LinkMenuItem } from './LinkMenu';

const InternalLinkContainer = styled.span<{
  $brokenLink: boolean;
  $$diffOperationType?: DiffOperationType;
}>`
  display: inline-flex;
  justify-content: space-between; /* Distribute columns equally */
  align-items: center;
  cursor: default;
  word-break: break-word;
  padding: 2px 3px 2px 2px;
  border-radius: 2px;
  border: solid 1px ${({ theme }): string => theme.colors.black10Alpha};
  background-color: var(--white);
  color: ${({ theme, $brokenLink = false }): string =>
    !$brokenLink ? theme.colors.brandBlue : theme.colors.errorRed};
  top: 2px;
  position: relative;
  margin: -3px 2px 0 2px;
  opacity: ${({ $$diffOperationType }) =>
    $$diffOperationType === DiffOperationType.Insert ||
    $$diffOperationType === DiffOperationType.Delete
      ? 0.8
      : 1};
  > span {
    display: flex;
    align-items: center;
    line-height: 0.95em;
  }
`;

export const InternalLink = ({
  element,
  insertableLinks,
  children,
  $diffOperationType,
}: {
  $diffOperationType?: DiffOperationType;
  element: Element;
  insertableLinks: LinkMenuItem[];
  children: ReactNode;
}): JSX.Element => {
  const item = insertableLinks.find((l) => l.linkId === element.linkId);

  const linkContent = (
    <InternalLinkContainer
      $brokenLink={!item}
      contentEditable={false}
      data-attr-type="link"
      data-attr-link-id={element.linkId}
      $$diffOperationType={$diffOperationType}
    >
      {/* TODO - Need aria labels here */}
      <span
        style={{
          marginRight: '4px',
        }}
      >
        <img
          style={{
            width: '16px',
            height: '16px',
          }}
          src={item ? LinkIconActive : LinkIconBroken}
          alt="link-icon"
          aria-hidden="true"
        />
      </span>
      <span>
        {item ? <>{item.text}</> : <>Link does not exist</>}
        {children}
      </span>
    </InternalLinkContainer>
  );

  if ($diffOperationType) {
    switch ($diffOperationType) {
      case DiffOperationType.Insert:
        return <InsertInlineBlock>{linkContent}</InsertInlineBlock>;
      case DiffOperationType.Delete:
        return <DeleteInlineBlock>{linkContent}</DeleteInlineBlock>;
      default:
        return linkContent;
    }
  }

  return linkContent;
};

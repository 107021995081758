/* eslint-disable import/prefer-default-export */
import React, { FC, useMemo, useState, KeyboardEvent, useEffect } from 'react';
import { useCombobox, useMultipleSelection } from 'downshift';
import { isEqual } from 'lodash';
import dropdownIconSvg from '../../assets/dropdown-icon.svg';
import closeIconSvg from '../../assets/icon-action-close-blue.svg';
import type { MenuItemDefinition } from './Select';

import {
  ChipContent,
  ChipIconContainer,
  DeleteItemIcon,
  DropdownIcon,
  DropdownWrapper,
  MenuItem,
  MenuOuterWrapper,
  MenuWrapper,
  MenuSearchInputBox,
  MultiSelectBox,
  MultiSelectChip,
  MultiSelectBoxContainer,
  MultiSelectPlaceholder,
  SelectMenuItemContainer,
} from './selectStyles';

interface MultiSelectProps {
  'data-testid'?: string;
  hasError?: boolean;
  items: MenuItemDefinition[];
  onSelect?: (item: MenuItemDefinition[]) => void | null;
  placeholder?: string;
  searchPlaceholder?: string;
  values: MenuItemDefinition[] | undefined;
  controlled?: boolean;
}

function getFilteredItems(
  selectedItems: MenuItemDefinition[],
  inputValue: string,
  initialItems: MenuItemDefinition[]
) {
  const lowerCasedInputValue = inputValue.toLowerCase();

  return initialItems.filter((item) => {
    return (
      !selectedItems.some(
        (selecteditem) => item?.value === selecteditem?.value
      ) && item.label.toLowerCase().includes(lowerCasedInputValue)
    );
  });
}

export const MultiSelect: FC<MultiSelectProps> = ({
  'data-testid': dataTestId = 'Multi-Select',
  hasError = false,
  items: initialItems,
  onSelect,
  placeholder,
  searchPlaceholder,
  values: initialSelectedItems = [],
  controlled = false,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [initItems, setInitItems] = useState(initialItems);
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems);
  const items = useMemo(() => {
    if (!isEqual(initialItems, initItems)) {
      setInitItems(initialItems);
      setSelectedItems([]);
    }

    return getFilteredItems(selectedItems, inputValue, initialItems);
  }, [selectedItems, inputValue, initialItems, initItems]);

  useEffect(() => {
    if (!isEqual(initialSelectedItems, selectedItems) && controlled) {
      setSelectedItems(initialSelectedItems);
    }
  }, [initialSelectedItems, selectedItems, controlled]);

  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } =
    useMultipleSelection({
      selectedItems,
      onStateChange({ selectedItems: newSelectedItems, type }) {
        switch (type) {
          case useMultipleSelection.stateChangeTypes
            .SelectedItemKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
          case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
            if (newSelectedItems) {
              setSelectedItems(newSelectedItems);

              if (onSelect) {
                onSelect(newSelectedItems);
              }
            }

            break;
          default:
            break;
        }
      },
    });

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    openMenu,
  } = useCombobox({
    items,
    itemToString(item) {
      return item ? item.label : '';
    },
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    inputValue,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick: {
          const selectedIndex = items?.findIndex(
            (item) => item?.value === changes?.selectedItem?.value
          );

          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
            highlightedIndex: selectedIndex - 1,
          };
        }
        case useCombobox.stateChangeTypes.InputClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open as it is nested in menu.
          };

        case useCombobox.stateChangeTypes.InputBlur:
          setInputValue('');

          return {
            ...changes,
            isOpen: false,
          };

        default:
          return changes;
      }
    },
    onStateChange({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem,
    }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (newSelectedItem) {
            const newItems = [...selectedItems, newSelectedItem];
            setSelectedItems(newItems);
            if (onSelect) {
              onSelect(newItems);
            }
          }
          break;

        case useCombobox.stateChangeTypes.InputChange:
          if (newInputValue || newInputValue === '') {
            setInputValue(newInputValue);
          }
          break;

        default:
          break;
      }
    },
  });

  return (
    <>
      <MultiSelectBox
        {...getToggleButtonProps()}
        data-testid={`${dataTestId}_SelectBox`}
        menuIsOpen={isOpen}
        placeholder={placeholder}
        hasError={hasError}
        tabIndex={0}
        // TODO Consider rebuilding the JSX/HTML strcuture to follow downshift combox box defaults and ARIA 1.2.
        // https://www.downshift-js.com/use-combobox#props-used-in-examples
        onKeyDown={(e: KeyboardEvent) => {
          if (
            (e.nativeEvent.keyCode === 13 || e.nativeEvent.keyCode === 40) &&
            !isOpen
          ) {
            openMenu();
          }
        }}
      >
        {selectedItems.length === 0 && (
          <MultiSelectPlaceholder
            data-testid={`${dataTestId}_Empty_PlaceholderContainer`}
          >
            <p>{placeholder}</p>
          </MultiSelectPlaceholder>
        )}
        {selectedItems.length > 0 && (
          <MultiSelectBoxContainer>
            {selectedItems?.map((selectedItemForRender, index) => {
              return (
                <MultiSelectChip
                  key={`selected-item-${selectedItemForRender.value}`}
                  data-testid={`${dataTestId}_SelectedChip-${index}`}
                  {...getSelectedItemProps({
                    selectedItem: selectedItemForRender,
                    index,
                  })}
                  onClick={(e) => {
                    e.stopPropagation();
                    removeSelectedItem(selectedItemForRender);
                  }}
                >
                  <ChipContent>{selectedItemForRender.label}</ChipContent>
                  <ChipIconContainer>
                    <DeleteItemIcon src={closeIconSvg} />
                  </ChipIconContainer>
                </MultiSelectChip>
              );
            })}
          </MultiSelectBoxContainer>
        )}
        <DropdownWrapper
          menuIsOpen={isOpen}
          data-testid={`${dataTestId}_DropdownWrapper`}
        >
          <DropdownIcon src={dropdownIconSvg} />
        </DropdownWrapper>
      </MultiSelectBox>

      <MenuOuterWrapper>
        <MenuWrapper
          {...getMenuProps()}
          isOpen={isOpen}
          data-testid={`${dataTestId}_MenuWrapper`}
        >
          <SelectMenuItemContainer>
            <MenuSearchInputBox
              data-testid={`${dataTestId}_Search_Input`}
              placeholder={searchPlaceholder}
              {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
            />
          </SelectMenuItemContainer>
          <ul style={{ padding: 0, margin: 0 }} {...getMenuProps()}>
            {items?.map((item: MenuItemDefinition, index: number) => (
              <MenuItem
                key={`${item.value}`}
                {...getItemProps({ item, index })}
                isHighlighted={highlightedIndex === index}
                data-testid={`${dataTestId}_MenuItem-${index}`}
              >
                {item.label}
              </MenuItem>
            ))}
          </ul>
        </MenuWrapper>
      </MenuOuterWrapper>
    </>
  );
};

import React from 'react';
import type { RenderElementProps } from 'slate-react';
import { DiffOperationType } from '@trustflight/tf-slate';

import type {
  ImageMetadata,
  ImageUploadCompleteResponse,
} from './AddImage/Image';
import Image from './AddImage/Image';
import ImageReference from './AddImage/ImageReference';
import ImageReferenceWithDisplay from './AddImage/ImageReferenceWithDisplay';
import AlertBox from './AlertBox';

import type { LinkMenuItem } from './Links/LinkMenu';
import TableCell from './Table/Cell/Cell';
import { InternalLink } from './Links/Link';
import {
  Indentation,
  Table,
  Row,
  StyledParagraph,
  StyledNumberList,
  StyledBulletList,
  StyledListItem,
} from './EditorComponents/Elements';

interface ImagesMetadata {
  images: ImageMetadata[];
  imageUploadedData: ImageUploadCompleteResponse[];
}

const Element = ({
  attributes,
  activeCell,
  setActiveCell,
  children,
  element,
  images,
  imageUploadedData,
  insertableLinks,
}: RenderElementProps &
  ImagesMetadata & {
    insertableLinks: LinkMenuItem[];
    activeCell: number[];
    setActiveCell: () => void;
  }): JSX.Element => {
  const { diffOperation = {} } = element;
  const { type: $diffOperationType = null } = diffOperation;

  const modifiedAttributes = {
    ...attributes,
    $diffOperationType,
  };

  switch (element.type) {
    case 'caution-box':
      return (
        <AlertBox isCautionBox attributes={attributes}>
          {children}
        </AlertBox>
      );
    case 'warning-box':
      return <AlertBox attributes={attributes}>{children}</AlertBox>;
    case 'bulleted-list':
      return (
        <StyledBulletList {...modifiedAttributes}>{children}</StyledBulletList>
      );
    case 'numbered-list':
      return (
        <StyledNumberList {...modifiedAttributes}>{children}</StyledNumberList>
      );
    case 'list-item':
      return (
        <StyledListItem {...modifiedAttributes}>{children}</StyledListItem>
      );
    case 'table': {
      // TODO diff rows/cols changes cause entire table to insert/delete in diff if content changes BUG.
      const rows = typeof element.rows === 'number' ? element.rows : undefined;
      const cols = typeof element.cols === 'number' ? element.cols : undefined;

      return (
        <Table {...modifiedAttributes} rows={rows} cols={cols}>
          <tbody>{children}</tbody>
        </Table>
      );
    }
    case 'table-row':
      return (
        <Row {...attributes} {...modifiedAttributes}>
          {children}
        </Row>
      );
    case 'table-cell':
      return (
        <TableCell
          $diffOperationType={$diffOperationType}
          activeCell={activeCell}
          element={element}
          setActiveCell={setActiveCell}
        >
          {children}
        </TableCell>
      );
    case 'image':
      return (
        <Image attributes={attributes} element={element}>
          {children}
        </Image>
      );
    case 'image-reference':
      return <ImageReference>{children}</ImageReference>;
    case 'image-reference-with-display':
      return (
        <ImageReferenceWithDisplay
          $diffOperationType={$diffOperationType}
          images={images}
          attributes={attributes}
          element={element}
          imageUploadedData={imageUploadedData}
        >
          {children}
        </ImageReferenceWithDisplay>
      );
    case 'link': {
      if ($diffOperationType === DiffOperationType.Update) {
        return (
          <>
            <InternalLink
              $diffOperationType={DiffOperationType.Delete}
              insertableLinks={insertableLinks}
              element={{
                ...element,
                linkId: diffOperation?.properties?.linkId,
              }}
            >
              {children}
            </InternalLink>
            <InternalLink
              $diffOperationType={DiffOperationType.Insert}
              insertableLinks={insertableLinks}
              element={{
                ...element,
                linkId: diffOperation?.newProperties?.linkId,
              }}
            >
              {children}
            </InternalLink>
          </>
        );
      }

      return (
        <InternalLink
          $diffOperationType={$diffOperationType}
          insertableLinks={insertableLinks}
          element={element}
        >
          {children}
        </InternalLink>
      );
    }

    case 'indentation': {
      return <Indentation {...modifiedAttributes}>{children}</Indentation>;
    }

    case 'paragraph':
    default: {
      return (
        <StyledParagraph {...modifiedAttributes}>{children}</StyledParagraph>
      );
    }
  }
};

export default Element;

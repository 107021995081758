import React, { useRef } from 'react';
import { ButtonDropdownIcon } from '../DropdownMenu/DropdownStyles';
import dropdownIcon from '../../assets/icon-dropdown-arrowhead-dark.svg';
import ChapterButton from './ChapterButton';
import { SectionTitle, ChapterButtonsWrapper } from './ContentsMenuStyles';

export type ContentMenuItem = {
  title: string;
};

export type ContentSection = {
  title: string;
  items: ContentMenuItem[];
};

type SectionMenuProps = {
  content: ContentSection;
  handleSectionClick: (content: ContentSection) => void;
  hasDropdownChevron: boolean;
  itemSelected: ContentMenuItem | undefined;
  isExpanded: boolean;
  subItemSelected: boolean;
  setItemSelected: (content: ContentMenuItem) => void;
  onSelect: (content: ContentMenuItem) => void;
};

const SectionMenu = ({
  content,
  handleSectionClick,
  hasDropdownChevron,
  itemSelected,
  isExpanded,
  subItemSelected,
  setItemSelected,
  onSelect,
}: SectionMenuProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <>
      <SectionTitle
        data-testid={`SectionTitle-${content.title}`}
        onClick={() => {
          handleSectionClick(content);
          if (ref && ref.current) {
            ref.current.blur();
          }
        }}
        $isExpanded={isExpanded}
        subItemSelected={subItemSelected}
        tabIndex={0}
        ref={ref}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleSectionClick(content);
          }
        }}
      >
        {content.title}{' '}
        {hasDropdownChevron && (
          <ButtonDropdownIcon src={dropdownIcon} isOpen={isExpanded} />
        )}
      </SectionTitle>
      {content.items && (
        <ChapterButtonsWrapper
          data-testid={`ChapterButtonsWrapper-${content.title}`}
          $isExpanded={isExpanded}
          itemCount={content.items.length}
        >
          {content.items.map((item, i) => {
            return (
              <ChapterButton
                content={content}
                item={item}
                isActive={itemSelected === item}
                onClick={() => {
                  setItemSelected(item);
                  onSelect(item);
                }}
                // eslint-disable-next-line react/no-array-index-key
                key={`${content.title}-chapterbutton-${item.title}-${i}`}
                data-testid={`ChapterButton-${item.title}`}
              />
            );
          })}
        </ChapterButtonsWrapper>
      )}
    </>
  );
};

export default SectionMenu;

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styled from 'styled-components';
import InlineSvg from 'react-inlinesvg';
import plusSvg from '../../assets/plus.svg';
import minusSvg from '../../assets/minus.svg';
import ChildFilterButton from './ChildFilterButton';
import type { FilterDefinition, ChildFilterDefinition } from './FilterSideBar';

type FilterStyledProps = {
  checked: boolean;
  count?: number;
};

const OpenSvg = styled(InlineSvg)`
  width: 8px;
  height: 8px;
  margin-left: 2px;
  color: ${({ theme }) => theme.colors.brandBlue};
`;

const FilterRow = styled.div`
  margin: 0.2rem 0;
  display: flex;
  align-items: center;
  width: 100%;
`;

const Label = styled.span<FilterStyledProps>`
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
`;

const LabelWrapper = styled.label<FilterStyledProps>`
  display: flex;
  align-items: center;
  padding-left: 26px;
  border-radius: 1.5rem;
  width: 180px;
  svg {
    flex-shrink: 0;
  }
  color: ${({ theme }) => theme.colors.black70Alpha};
  cursor: pointer;
  transition: all 400ms;
  }
  .badge-wrapper {
    margin-right: 8px;
  }
`;

type Tuple<T> = Array<FilterDefinition<T> | ChildFilterDefinition>;

type FilterButtonWithChildrenProps<T> = {
  filter: FilterDefinition<T>;
  updateFilterState: (options: {
    filter: FilterDefinition<T>;
    filterState: boolean;
  }) => void;
  filterStates: Tuple<T>;
  onFailedParentToggle: (() => void) | undefined;
};

const FilterButtonWithChildren = <T,>({
  filter,
  filterStates,
  updateFilterState,
  onFailedParentToggle,
}: FilterButtonWithChildrenProps<T>): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const { label, count } = filter;
  const svgIcon = isOpen ? minusSvg : plusSvg;

  const hasSelectedChildren = !!filter.childFilters?.find((childFilter) => {
    return filterStates.includes(childFilter);
  });

  const toggleOpen = () => {
    if (isOpen && hasSelectedChildren && onFailedParentToggle) {
      onFailedParentToggle();
    } else {
      setIsOpen(!isOpen);
    }
  };

  return (
    <>
      <FilterRow data-testid="filterRow" onClick={toggleOpen}>
        <LabelWrapper
          htmlFor={`${label}-filter`}
          checked={isOpen}
          count={count}
          data-testid="labelWrapper"
        >
          <Label checked={isOpen} data-testid="label">
            {label} <OpenSvg src={svgIcon} fill="currentColor" />
          </Label>
        </LabelWrapper>
      </FilterRow>
      {isOpen &&
        filter.childFilters?.map((childFilter) => {
          return (
            <ChildFilterButton
              childFilter={childFilter}
              key={childFilter.value}
              updateFilterState={updateFilterState}
            />
          );
        })}
    </>
  );
};

export default FilterButtonWithChildren;

/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import styled from 'styled-components';
import LoadingDark from './loadingDark.json';
import LoadingLight from './loadingLight.json';

const Wrapper = styled.div<{ dynamicBackground: string }>`
  ${({ dynamicBackground }): string => `background: ${dynamicBackground}`};
`;

export interface LoadingProps {
  size?: number;
  loading?: boolean;
  // theme?: string;
  contain?: boolean;
  darkMode?: boolean;
  hasTransparentBackground?: boolean;
}

interface Contained {
  contain?: boolean;
  darkMode?: boolean;
  isLoaded: boolean;
}

const LottieWrapper = styled.div<Contained>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: ${({ isLoaded }): string => (isLoaded ? '1;' : '0;')};
  transition: opacity 0.5s;
  ${({ contain }): string => (contain ? 'height: 100%' : 'height: 100vh')};
`;

const LoadingAnimation = styled(Player)<{ size: number }>`
  height: ${({ size }): string => `${size}px;`};
  width: ${({ size }): string => `${size}px`};
`;

export const Loading: React.FC<LoadingProps> = ({
  size = 80,
  loading = true,
  children,
  darkMode = false,
  contain = false,
  hasTransparentBackground = false,
}) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const dynamicBackground = (): string => {
    if (hasTransparentBackground) return 'transparent';
    if (darkMode) return 'rgba(0, 30, 61, 0.35)';
    return 'rgba(255, 255, 255, 0.9)';
  };

  return (
    <>
      <Wrapper dynamicBackground={dynamicBackground()}>
        {loading && (
          <LottieWrapper contain={contain} isLoaded={isLoaded}>
            <LoadingAnimation
              onEvent={(event: string) => {
                if (event === 'load') {
                  setIsLoaded(true);
                }
              }}
              autoplay
              loop
              src={darkMode ? LoadingLight : LoadingDark}
              size={size}
            />
          </LottieWrapper>
        )}
      </Wrapper>
      {children && !loading && children}
    </>
  );
};

import React from 'react';
import { Element as CustomElement, Editor, Path, Transforms } from 'slate';
import { useSelected, useSlateStatic } from 'slate-react';
import { EditorImage, ImageWrapper, ImageCaption } from './imageStyles';

export interface ImageMetadata {
  id: string;
  description?: string;
  filename: string;
  src: string;
}

export type FileToAddInformation = { file: File; loadingReferenceId: string };

export type ImageUploadCompleteResponse = {
  id: string;
  loadingReferenceId: string;
  contentType?: string;
  createdAt: Date;
  description?: string;
  fileName: string;
  thumbnailUrl?: null;
  updatedAt: Date;
  url: string;
  error: boolean;
};

export type FailedImageUploadResponse = RequireOnly<
  ImageUploadCompleteResponse,
  'error' | 'loadingReferenceId'
>;

export const handleImageKeyDown = (
  event: React.KeyboardEvent,
  editor: Editor,
  isSelected: boolean
): void => {
  event.preventDefault();
  const { key } = event;
  const shouldDeleteImage = key === 'Backspace' || key === 'Delete';
  const { selection } = editor;
  const shouldCreateNewNode =
    key === 'Enter' || key === 'ArrowRight' || key === 'ArrowDown';

  if (isSelected && selection) {
    const [, parentPath] = Editor.parent(editor, selection?.focus?.path);

    if (shouldDeleteImage) {
      Transforms.removeNodes(editor, { at: parentPath });
    }

    if (shouldCreateNewNode) {
      Transforms.insertNodes(
        editor,
        [{ type: 'paragraph', children: [{ text: '' }] }],
        {
          at: Path.next(parentPath),
          select: true,
        }
      );
    }
  }
};

interface ImageProps {
  attributes: {
    'data-slate-node': 'element';
    'data-slate-inline'?: true | undefined;
    'data-slate-void'?: true | undefined;
    dir?: 'rtl' | undefined;
  };
  children: JSX.Element | JSX.Element[];
  element: CustomElement;
}

const Image = ({
  attributes,
  children,
  element,
}: ImageProps): React.ReactElement => {
  const editor = useSlateStatic();
  const isSelected = useSelected();
  const { alt, description, src } = element;

  return (
    <ImageWrapper
      contentEditable={false}
      {...attributes}
      data-attr-type="image"
      data-attr-description={description || ''}
      data-attr-src={src || ''}
    >
      <EditorImage
        tabIndex={0}
        src={src}
        alt={alt}
        $isSelected={isSelected}
        onKeyDown={(e: React.KeyboardEvent) => {
          handleImageKeyDown(e, editor, isSelected);
        }}
      />
      {description && <ImageCaption>{description}</ImageCaption>}
      {children}
    </ImageWrapper>
  );
};

export default Image;

import { jsx } from 'slate-hyperscript';
import type { Descendant, Editor, Node as SlateNode } from 'slate';
import { Transforms } from 'slate';
import { toolBarColors } from '../ToolBarStyles';
import { isInsideNode, CustomSlateNodeTypes } from './utils';

export function removeTableNodes(node: SlateNode | SlateNode[]): SlateNode[] {
  if (Array.isArray(node)) {
    return node.flatMap(removeTableNodes);
  }

  if (typeof node === 'object' && node !== null) {
    if (['table', 'table-row', 'table-cell'].includes(node.type)) {
      // If the node is a table, table-row, or table-cell, return its children
      return removeTableNodes(node.children);
    }

    // If the node is not a table, table-row, or table-cell, process its children
    return {
      ...node,
      ...(node.children && { children: removeTableNodes(node.children) }),
    };
  }

  // If the node is not an object (i.e., it's a text node), return it as is
  return node;
}

export const deserializeOnPaste = (
  el: SlateNode,
  editor: Editor,
  markAttributes?: Descendant,
  index?: number
): SlateNode => {
  const isInsideTable = isInsideNode(editor, 'table');

  const { nodeName, nodeType, style: elStyle } = el;

  if (nodeType === Node.TEXT_NODE) {
    // eslint-disable-next-line no-control-regex
    const textContent = el.textContent.replace(/[^\x00-\xFF]/g, '');

    // Ignore sequences of whitespace characters that don't contain any visible text
    if (!/\S/.test(textContent)) {
      return jsx('text', markAttributes, '');
    }

    return jsx('text', markAttributes, textContent);
  }

  const nodeAttributes = { ...markAttributes };

  if (nodeName === 'STRONG' || nodeName === 'B') {
    nodeAttributes.bold = true;
  }
  if (nodeName === 'EM') {
    nodeAttributes.italic = true;
  }
  if (nodeName === 'U') {
    nodeAttributes.underline = true;
  }

  const textColorsArr = Object.keys(toolBarColors.textColor).map(
    (key) => toolBarColors.textColor[key]
  );
  const textBGArr = Object.keys(toolBarColors.highlightColor).map(
    (key) => toolBarColors.highlightColor[key]
  );

  if (nodeName === 'SPAN') {
    if (
      elStyle?.color &&
      elStyle?.color !== '' &&
      textColorsArr.includes(elStyle?.color)
    ) {
      nodeAttributes.textColor = el.style?.color;
    }
    if (
      el.style?.backgroundColor &&
      el.style?.backgroundColor !== '' &&
      textBGArr.includes(el.style?.backgroundColor)
    ) {
      nodeAttributes.highlightColor = el.style?.backgroundColor;
    }
  }

  // eslint-disable-next-line no-use-before-define
  const children = buildPastedHTMLtoSlateNodes(el, editor, nodeAttributes);

  const spandDataAttrType = el?.getAttribute('data-attr-type');
  const linkId = el?.getAttribute('data-attr-link-id');
  const fileName = el?.getAttribute('data-attr-file-name') || '';
  const src = el?.getAttribute('data-attr-src') || '';
  const description = el?.getAttribute('data-attr-description') || '';
  const imageReferenceId =
    el?.getAttribute('data-attr-image-reference-id') || '';

  switch (el.nodeName) {
    // Multiple types potentially use SPAN in our jsx/html structure.
    case 'SPAN':
      switch (spandDataAttrType) {
        case 'link': {
          return [
            jsx(
              'element',
              { type: 'link', linkId, ...nodeAttributes },
              children
            ),
          ];
        }

        case 'indentation':
          return [
            jsx(
              'element',
              { type: 'indentation', ...nodeAttributes },
              children
            ),
          ];

        case 'image':
          return jsx(
            'element',
            {
              type: 'image',
              imageReferenceId,
              src,
              fileName,
              description,
              ...nodeAttributes,
            },
            children
          );
        case 'image-reference':
          return jsx(
            'element',
            {
              type: 'image-reference',
              src,
              fileName,
              description,
              ...nodeAttributes,
            },
            children
          );
        case 'image-reference-with-display':
          return [
            jsx(
              'element',
              {
                imageReferenceId,
                type: 'image-reference-with-display',
                src,
                fileName,
                description,
                ...nodeAttributes,
              },
              children
            ),
          ];
        default:
          return children;
      }
    case 'OL':
      return jsx('element', { type: 'numbered-list' }, children);
    case 'UL':
      return jsx('element', { type: 'bulleted-list' }, children);
    case 'LI':
      return jsx('element', { type: 'list-item' }, children);
    case 'TABLE':
      if (isInsideTable) {
        return children;
      }

      return jsx(
        'element',
        {
          ...nodeAttributes,
          type: 'table',
          rows: children?.length || 0,
          cols: children[0].children?.length || 0,
        },
        children
      );
    case 'TR':
      if (isInsideTable) {
        return children;
      }

      return jsx(
        'element',
        { type: 'table-row', id: index, ...nodeAttributes },
        children
      );
    case 'TD':
      if (isInsideTable) {
        return children;
      }

      return jsx(
        'element',
        { type: 'table-cell', id: index, ...nodeAttributes },
        children
      );

    case 'P':
      return jsx('element', { type: 'paragraph' }, children);

    default:
      return children;
  }
};

export const buildPastedHTMLtoSlateNodes = (
  el: SlateNode,
  editor: Editor,
  nodeAttributes?: Descendant
): SlateNode[] => {
  return Array.from(el?.childNodes)
    .map((node, i) => deserializeOnPaste(node, editor, nodeAttributes, i))
    .flat()
    .filter((node: SlateNode) => node !== null);
};

export function handlePasteHTMLDeserialization(
  clipboardData: DataTransfer,
  editor: Editor
): void {
  // All other content is handle as HTML
  const pastedHTML = clipboardData.getData('text/html');
  const pastedDocument = new DOMParser().parseFromString(
    pastedHTML,
    'text/html'
  );
  const deserializedPastedDoc = buildPastedHTMLtoSlateNodes(
    pastedDocument.body,
    editor
  );
  const onlyBuiltTextNodes = deserializedPastedDoc.every(
    (obj) => !obj.type && typeof obj.text === 'string'
  );

  if (onlyBuiltTextNodes) {
    Transforms.insertFragment(editor, deserializedPastedDoc);
    return;
  }

  // Code here is meant to more handle for conditions when pasted html just looks like:
  // <body>SOME CONTENT</body> which happens frequently from our PDFs.
  // Need better handling for empty text nodes copied from word, etc.
  const handleTopLevelNodeCleanupFromPDF = deserializedPastedDoc.reduce(
    (ns: SlateNode[], n: SlateNode) => {
      if (!n.type && n.text === '') {
        return ns;
      }

      if (!n.type && n.text !== '') {
        return [
          ...ns,
          {
            type: CustomSlateNodeTypes.Paragraph,
            children: [{ text: n.text }],
          },
        ];
      }
      return [...ns, n];
    },
    []
  );

  // TODO Consider adding better functionality here to insert text instead of just adding entirely
  // new nodes.
  Transforms.insertFragment(editor, handleTopLevelNodeCleanupFromPDF);
}

import React, { useCallback } from 'react';
import { Editor } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import ClearColour from '../../../assets/RichTeIcons/icon-highlight-clear.svg';
import { toolBarColors } from '../ToolBarStyles';
import { ColorPaletteItem, RemoveColor } from './ColorMenu';

interface ClearColorButtonProps {
  type: 'textColor' | 'highlightColor';
}

export default ({ type }: ClearColorButtonProps): JSX.Element => {
  const editor: Editor = useSlate();
  const marks = Editor.marks(editor);
  const aColorIsActive = Object.values(toolBarColors[type]).some(
    (color: string) => Boolean(marks && marks[type] && marks[type] === color)
  );

  const handleToggleColour = useCallback(
    (event): void => {
      event.preventDefault();
      if (marks && marks[type]) {
        Editor.removeMark(editor, type);
      }
      ReactEditor.focus(editor);
    },
    [editor, marks, type]
  );

  return (
    <ColorPaletteItem
      type="button"
      onMouseDown={handleToggleColour}
      color="transparent"
      $isActive={!aColorIsActive}
    >
      <RemoveColor src={ClearColour} alt="mark button" />
    </ColorPaletteItem>
  );
};

import React, {
  createContext,
  ReactNode,
  useReducer,
  useContext,
  ReactElement,
} from 'react';

type Action =
  | { type: 'toggle-user-disabled' }
  | { type: 'enable-all' }
  | { type: 'disable-all' };
type Dispatch = (action: Action) => void;
type State = { userDisabled: boolean };
type RTEContextProviderProps = { children: ReactNode; isDisabled: boolean };
type RTEContextType = { state: State; dispatch: Dispatch };

// User extremelely generic name until context state needs grow into needing something more granular.
export const RTEContext = createContext<RTEContextType>(
  undefined as unknown as RTEContextType
);

function RTEContextReducer(state: State, action: Action) {
  const { type = null } = action;

  switch (type) {
    case 'toggle-user-disabled': {
      return { userDisabled: !state.userDisabled };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

function RTEContextProvider({
  isDisabled,
  children,
}: RTEContextProviderProps): ReactElement {
  const [state, dispatch] = useReducer(RTEContextReducer, {
    userDisabled: isDisabled,
  });
  // NOTE: could memoize this value
  // http://kcd.im/optimize-context
  const value = { state, dispatch };
  return <RTEContext.Provider value={value}>{children}</RTEContext.Provider>;
}

function useRTEContext(): RTEContextType {
  const context = useContext(RTEContext);

  if (context === undefined) {
    throw new Error('useRTEContext must be used within a RTEContextProvider');
  }
  return context;
}

export { RTEContextProvider, useRTEContext };

import React, {
  Dispatch,
  RefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import Portal from '../../utils/Portal';
import portalMenuVariants from './portalMenuVariants';
import type { MenuItem } from './ThreeDotMenu';
import {
  Menu,
  MenuItemButton,
  MenuItemIcon,
  MenuItemLabel,
} from './ThreeDotMenuStyles';

const MenuHeaderIcon = styled.img<{ iconSize?: number }>`
  height: 26px;
  margin-right: 5px;
  width: ${({ iconSize = 26 }): string => `${iconSize}px`};
`;

const MenuHeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  padding: 10px;

  .menu-header-title {
    color: var(--black);
    font-weight: 500;
  }
`;
interface MenuHeaderProps {
  icon: string;
  title: string;
  iconSize?: number;
}
export const MenuHeader = ({
  icon,
  title,
  iconSize,
}: MenuHeaderProps): JSX.Element => {
  return (
    <MenuHeaderWrapper>
      <MenuHeaderIcon iconSize={iconSize} src={icon} alt="" />
      <span className="menu-header-title">{title}</span>
    </MenuHeaderWrapper>
  );
};

type PortalMenuProps = {
  hasRoundedStyle?: boolean;
  menuHeader?: MenuHeaderProps;
  menuItems: MenuItem[];
  parentRef: RefObject<HTMLButtonElement>;
  tableVariant?: boolean;
  setMouseIsInsideMenu: Dispatch<React.SetStateAction<boolean>>;
  setIsMenuOpen: Dispatch<React.SetStateAction<boolean>>;
  setActiveItem: Dispatch<React.SetStateAction<number | null | undefined>>;
  activeItem: number | null | undefined;
};

export const PortalMenu: React.FC<PortalMenuProps> = ({
  hasRoundedStyle = false,
  menuHeader,
  menuItems,
  parentRef,
  tableVariant = false,
  setMouseIsInsideMenu,
  setIsMenuOpen,
  activeItem,
  setActiveItem,
}) => {
  const [top, setTop] = useState<number>();
  const [left, setLeft] = useState<number>();
  const [right, setRight] = useState<number>();
  const [isMoreOnTheRight, setIsMoreOnTheRight] = useState<boolean>();
  const calculatePosition = useCallback(() => {
    if (parentRef && parentRef.current) {
      const rect = parentRef.current.getBoundingClientRect();
      setIsMoreOnTheRight(rect.left >= rect.right / 2);
      setTop(rect.top + window.scrollY + rect.height);
      setLeft(rect.left + window.scrollX);
      setRight(rect.right + window.scrollX);
    }
  }, [parentRef]);

  useEffect(() => {
    calculatePosition();
  }, [calculatePosition]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      calculatePosition();
    });
  }, [calculatePosition]);

  return (
    <Portal>
      <Menu
        variants={portalMenuVariants}
        initial="initial"
        animate="enter"
        exit="exit"
        data-testid="menu"
        top={top}
        left={left}
        right={right}
        isMoreOnTheRight={isMoreOnTheRight}
        tableVariant={tableVariant}
        onMouseEnter={() => {
          setMouseIsInsideMenu(true);
        }}
        onMouseLeave={() => {
          setMouseIsInsideMenu(false);
        }}
        hasRoundedStyle={hasRoundedStyle}
      >
        {menuHeader && (
          <MenuHeader icon={menuHeader.icon} title={menuHeader.title} />
        )}
        {menuItems.map((menuItem, i) => {
          const isStringMenuItem = typeof menuItem.label === 'string';
          const menuItemKey = isStringMenuItem ? menuItem.label : i;

          return (
            <MenuItemButton
              isJsxLabel={React.isValidElement(menuItem.label)}
              isActive={activeItem === i}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setIsMenuOpen(false);
                menuItem.onSelect(event);
              }}
              onMouseLeave={() => {
                setActiveItem(null);
              }}
              onMouseMove={() => {
                if (activeItem !== i) {
                  setActiveItem(i);
                }
              }}
              tableVariant={tableVariant}
              destructive={Boolean(menuItem.destructive)}
              data-testid={`portalMenuItemButton-${i}`}
              key={`portalMenuItemButton-${menuItemKey}`}
              hasRoundedStyle={hasRoundedStyle}
            >
              <MenuItemLabel data-testid={`portalMenuItemLabel-${i}`}>
                {menuItem.icon && (
                  <MenuItemIcon
                    src={menuItem.icon}
                    data-testid={`portalMenuItemIcon-${i}`}
                  />
                )}
                {menuItem.label}
              </MenuItemLabel>
            </MenuItemButton>
          );
        })}
      </Menu>
    </Portal>
  );
};

export default PortalMenu;

import React from 'react';
import styled from 'styled-components';
import IconFileNavigationSvg from '../../assets/icon-file-navigation.svg';

const StyledPreviousButton = styled.button`
  position: absolute;
  top: 50vh;
  left: 20px;
  padding-left: 0px;
  border: none;
  background: transparent;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

type PreviousButtonProps = {
  previousSlide: () => void;
};

export default ({ previousSlide }: PreviousButtonProps): JSX.Element => (
  <StyledPreviousButton
    onClick={previousSlide}
    type="button"
    data-testid="PreviousButton"
  >
    <img src={IconFileNavigationSvg} alt="previousButton" />
  </StyledPreviousButton>
);

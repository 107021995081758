import { AnimatePresence, motion } from 'framer-motion';
import { wrap } from 'popmotion';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { usePrevious } from '../../utils/utils';
import CloseButton from './CloseButton';
import DeleteButton from './DeleteButton';
import DownloadButton from './DownloadButton';
import NextButton from './NextButton';
import PreviousButton from './PreviousButton';

const ImageCarouselWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--dark-navy);
  z-index: 20;
  opacity: 1;
  transition: all 0.5s;
`;

const ContentWrapper = styled.div`
  color: white;
  display: flex;
  height: 100%;
`;

const FileViewColumn = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 30%;
  height: 100%;
  &:first-of-type {
    padding-left: 20px;
    align-items: flex-start;
  }
  &:last-of-type {
    padding-right: 20px;
    align-items: flex-end;
  }
  flex-direction: column;
`;

const ImageTitle = styled.div`
  color: var(--white);
  font-size: 14px;
  font-weight: normal;
  padding-top: 10px;
`;

const FileControlsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 10px;
`;

const ImageColumn = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 30%;
  height: 100%;
  align-items: center;
  flex-direction: column;
`;

const CurrentImageWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const CurrentImage = styled(motion.img)`
  width: 100%;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;

type Image = {
  id: string;
  fileName: string;
  url: string;
};

const appearVariants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: 0.01,
    },
  },
  exit: {
    opacity: 0,
  },
};

const slideVariants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

export type ImageCarouselProps = {
  isOpen?: boolean;
  images: Image[];
  imageIndex?: number;
  onDownload: (image: Image) => void;
  onDelete: (image: Image) => void;
  onClose?: () => void;
  onImageIndexChange?: (index: number) => void;
};

export const ImageCarousel: React.FC<ImageCarouselProps> = ({
  isOpen = false,
  imageIndex: imageIndexProp = 0,
  images,
  onClose,
  onDelete,
  onDownload,
  onImageIndexChange,
}) => {
  const [isCarouselOpen, setIsCarouselOpen] = useState<boolean>(isOpen);
  useEffect(() => {
    setIsCarouselOpen(isOpen);
  }, [isOpen, isCarouselOpen]);

  const [[page, direction], setPage] = useState([imageIndexProp, 0]);
  useEffect(() => {
    if (imageIndexProp < images.length) {
      setPage([imageIndexProp, 0]);
    } else {
      setPage([images.length - 1, 0]);
    }
  }, [imageIndexProp, images.length]);

  const imageIndex = wrap(0, images.length, page);
  const prevPage = usePrevious(page);
  useEffect(() => {
    if (page !== prevPage && onImageIndexChange) {
      onImageIndexChange(imageIndex);
    }
  });
  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };
  return (
    <AnimatePresence>
      {isCarouselOpen && (
        <ImageCarouselWrapper
          data-testid="ImageCarouselWrapper"
          initial="initial"
          animate="enter"
          exit="exit"
          variants={appearVariants}
        >
          <ContentWrapper>
            <FileViewColumn>
              <ImageTitle data-testid="ImageTitle">
                {images[imageIndex].fileName}
              </ImageTitle>
              <FileControlsRow>
                <DownloadButton
                  onClick={() => {
                    onDownload(images[imageIndex]);
                  }}
                />
                <DeleteButton
                  onClick={() => {
                    onDelete(images[imageIndex]);
                  }}
                />
              </FileControlsRow>
              <PreviousButton
                previousSlide={() => {
                  paginate(-1);
                }}
              />
            </FileViewColumn>
            <ImageColumn>
              <AnimatePresence initial={false} custom={direction}>
                <CurrentImageWrapper>
                  <CurrentImage
                    key={page}
                    src={images[imageIndex].url}
                    custom={direction}
                    variants={slideVariants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                      x: { type: 'spring', stiffness: 300, damping: 40 },
                      opacity: { duration: 0.2 },
                    }}
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={1}
                    onDragEnd={(_e, { offset, velocity }) => {
                      const swipe = swipePower(offset.x, velocity.x);

                      if (swipe < -swipeConfidenceThreshold) {
                        paginate(1);
                      } else if (swipe > swipeConfidenceThreshold) {
                        paginate(-1);
                      }
                    }}
                    data-testid="CurrentImage"
                  />
                </CurrentImageWrapper>
              </AnimatePresence>
            </ImageColumn>
            <FileViewColumn>
              <CloseButton
                closeFileView={() => {
                  setIsCarouselOpen(false);
                  if (onClose) {
                    onClose();
                  }
                }}
              />
              <NextButton
                nextSlide={() => {
                  paginate(1);
                }}
              />
            </FileViewColumn>
          </ContentWrapper>
        </ImageCarouselWrapper>
      )}
    </AnimatePresence>
  );
};

export default ImageCarousel;

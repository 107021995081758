import React, { useCallback, useState, useEffect, useRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useMiniSearch } from 'react-minisearch';
import type { Editor } from 'slate';
import { Transforms } from 'slate';
import { MenuHeader } from '../../ThreeDotMenu/PortalMenu';
import {
  MenuSearchInputBox,
  SelectMenuItemContainer,
} from '../../Select/selectStyles';
import Portal from '../../../utils/Portal';
import { MenuPopUpOuterWrapper, MenuPopUpWrapper } from '../ToolBarStyles';
import LinkIcon from '../../../assets/RichTeIcons/icon-link.svg';

import { EmptySearchResults } from './EmptySearchResults';
import { LinkItem } from './LinkItem';
import type { LinkMenuItem } from './LinkMenu';

const miniSearchOptions = {
  fields: ['text'],
  searchOptions: {
    fuzzy: 0.2,
    prefix: true,
    fields: ['text'],
    combineWith: 'OR',
  },
};

export const LinkPopUpMenu = ({
  editor,
  links,
  menuToggle,
  toggleOtherMenus: { siblingColourMenu, tableMenu },
  otherMenuStates,
}: {
  editor: Editor;
  links: LinkMenuItem[];
  menuToggle(fn: (menuState: boolean) => boolean): void;
  toggleOtherMenus: {
    siblingColourMenu: (arg0: boolean) => void;
    tableMenu: (arg0: boolean) => void;
  };
  otherMenuStates: boolean[];
}): JSX.Element => {
  const parentRef = useRef<HTMLDivElement>(null);
  const [left, setLeft] = useState<number>(0);
  const [top, setTop] = useState<number>(0);

  const newLinks = links.map((link) => {
    return {
      ...link,
      id: link.linkId,
    };
  });
  const [searchInput, setSearchInput] = useState('');
  const { search, searchResults } = useMiniSearch(newLinks, miniSearchOptions);
  const selection = editor?.selection;
  const focus = selection?.focus;
  const searchResultsLength = searchResults?.length || 0;

  useEffect(() => {
    if (parentRef.current) {
      const rect = parentRef.current.getBoundingClientRect();
      setTop(rect.top + window.scrollY + rect.height);
      setLeft(rect.left + window.scrollX);
    }
  }, []);

  return (
    <MenuPopUpOuterWrapper ref={parentRef}>
      <Portal>
        <OutsideClickHandler
          onOutsideClick={() => {
            menuToggle((menuState) => !menuState);
          }}
        >
          <MenuPopUpWrapper top={top} left={left} style={{ width: '320px' }}>
            <MenuHeader title="Link" icon={LinkIcon} iconSize={28} />
            <SelectMenuItemContainer
              // Need to add this to the menu to offset from the search input/title spacing
              style={{
                padding: '0px 8px 6px 8px',
              }}
            >
              <MenuSearchInputBox
                placeholder="Search"
                value={searchInput}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;

                  setSearchInput(value);
                  search(value);
                }}
              />
            </SelectMenuItemContainer>

            <ul
              style={{
                padding: '0 0 5px 0',
                margin: 0,
                listStyle: 'none',
                overflowY: 'scroll',
              }}
            >
              {(!searchResults || searchResultsLength === 0) &&
                searchInput !== '' && (
                  <div style={{ padding: '15px' }}>
                    <EmptySearchResults />
                  </div>
                )}
              {searchResults &&
                searchResultsLength !== 0 &&
                searchResults?.map((link) => (
                  <LinkItem
                    link={link}
                    key={`${link.linkId}`}
                    editor={editor}
                    focus={focus}
                    menuToggle={menuToggle}
                  />
                ))}
              {/* Show all the links if there is no search text inputted */}
              {(!searchResults || searchResultsLength === 0) &&
                searchInput === '' &&
                newLinks?.map((link) => (
                  <LinkItem
                    link={link}
                    key={`${link.linkId}`}
                    editor={editor}
                    focus={focus}
                    menuToggle={menuToggle}
                  />
                ))}
            </ul>
          </MenuPopUpWrapper>
        </OutsideClickHandler>
      </Portal>
    </MenuPopUpOuterWrapper>
  );
};

import React from 'react';
import styled from 'styled-components';
import IconFileNavigationSvg from '../../assets/icon-file-navigation.svg';

const StyledNextButton = styled.button`
  position: absolute;
  top: 50vh;
  border: none;
  background: transparent;
  width: 30px;
  line-height: 10px;
  height: 30px;
  cursor: pointer;
  padding-right: 24px;
  img {
    transform: rotate(180deg);
  }
`;

type NextButtonProps = {
  nextSlide: () => void;
};

export default ({ nextSlide }: NextButtonProps): JSX.Element => (
  <StyledNextButton onClick={nextSlide} type="button" data-testid="NextButton">
    <img src={IconFileNavigationSvg} alt="nextButton" />
  </StyledNextButton>
);

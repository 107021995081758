import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Tab from './Tab';

const ComponentWrapper = styled.div`
  width: 100%;
  display: flex;
  background-color: ${({ theme }): string => theme.colors.softGrey};
`;

export type MenuItem = {
  label: string;
  value: string;
  count?: number;
};

export type PageTabsProps = {
  items: MenuItem[];
  selectedItem?: MenuItem;
  onSelect: (item: MenuItem) => void;
};

export const PageTabs = ({
  items: itemsProp,
  selectedItem: selectedItemProp,
  onSelect,
}: PageTabsProps): JSX.Element => {
  const [selectedItem, setSelectedItem] = useState(
    selectedItemProp || itemsProp[0]
  );
  const [items, setItems] = useState(itemsProp);

  useEffect(() => {
    if (itemsProp) {
      setItems(itemsProp);
    }
  }, [itemsProp]);

  useEffect(() => {
    if (selectedItemProp) {
      setSelectedItem(selectedItemProp);
    }
  }, [selectedItemProp]);

  return (
    <ComponentWrapper>
      {items.map((item) => {
        return (
          <Tab
            isSelected={selectedItem === item}
            onClick={() => {
              setSelectedItem(item);
              onSelect(item);
            }}
            item={item}
          />
        );
      })}
    </ComponentWrapper>
  );
};

export default PageTabs;

import React, { FC } from 'react';
import BasicSelect from './BasicSelect';
import { MultiSelect as MultiSelectComponent } from './MultiSelect';
import SearchableSelect from './SearchableSelect';
import { ComponentWrapper } from './selectStyles';

export type MenuItemDefinition = {
  label: string;
  value: string;
};

interface CommonSelectProps {
  'data-testid'?: string;
  items: MenuItemDefinition[];
  placeholder?: string;
}

export type MultiSelectHandler = (item: MenuItemDefinition[]) => void | null;
export type SingleSelectHandler = (
  item: MenuItemDefinition | null | undefined
) => void | null;

export interface SelectProps extends CommonSelectProps {
  controlled?: boolean;
  isSearchable?: boolean;
  onSelect: SingleSelectHandler;
  value?: MenuItemDefinition;
}

export interface MultiSelectProps extends CommonSelectProps {
  hasError?: boolean;
  onSelect: MultiSelectHandler;
  searchPlaceholder?: string;
  values: MenuItemDefinition[];
  controlled?: boolean;
}

export const MultiSelect: FC<MultiSelectProps> = ({
  'data-testid': dataTestId = 'Multi-Select',
  items,
  onSelect,
  placeholder,
  searchPlaceholder,
  values: initialValue,
  hasError = false,
  controlled = false,
}) => {
  return (
    <MultiSelectComponent
      data-testid={dataTestId}
      hasError={hasError}
      items={items}
      onSelect={onSelect}
      placeholder={placeholder}
      searchPlaceholder={searchPlaceholder}
      values={initialValue}
      controlled={controlled}
    />
  );
};

export const Select: FC<SelectProps> = ({
  controlled = false,
  'data-testid': dataTestId = 'Select',
  isSearchable = false,
  items,
  onSelect,
  placeholder,
  value: initialValue,
}) => {
  return (
    <ComponentWrapper data-testid={dataTestId}>
      <>
        {isSearchable ? (
          <SearchableSelect
            items={items}
            onSelect={onSelect}
            placeholder={placeholder}
            value={initialValue}
          />
        ) : (
          <BasicSelect
            items={items}
            onSelect={onSelect}
            placeholder={placeholder}
            value={initialValue}
            controlled={controlled}
          />
        )}
      </>
    </ComponentWrapper>
  );
};

export default Select;

import _ from 'lodash';
import { Editor, Transforms, Element, Node, BasePoint } from 'slate';
import { ReactEditor } from 'slate-react';

type TableArgs = {
  numOfRows: number;
  numOfColumns: number;
  editor: Editor;
};

interface RichTextTable {
  numOfRows: number;
  numOfColumns: number;
  editor: Editor;
  currentDimensions: Record<string, number>;
  startIndex: number;
  rowSizeDiff: number;
  path: number[];
  setPath: () => void;
  rowsShouldBeIncreased: () => boolean;
  rowsShouldBeDecreased: () => boolean;
  colsShouldBeIncreased: () => boolean;
  colsShouldBeDecreased: () => boolean;
  setStartIndex: () => void;
  makeRows: ({
    addFirstRow,
    editMode,
  }: {
    addFirstRow?: boolean;
    editMode?: boolean;
  }) => RichTableRow[];
  getRowSizeDifference: () => number;
  makeNewTable: () => void;
  addColumns: () => void;
  removeColumns: () => void;
  addRows: () => void;
  removeRows: () => void;
  calculateIDsToRemove: (sizeDiff: number, nodeLength: number) => number[];
  updateTableSizeProperties: () => void;
}

type RichTableRow = {
  type: string;
  id: number;
  children: Element[];
};

export default ({
  numOfRows,
  numOfColumns,
  editor,
}: TableArgs): RichTextTable => ({
  numOfRows,
  numOfColumns,
  editor,
  currentDimensions: {},
  startIndex: 0,
  rowSizeDiff: 0,
  path: [],

  setPath(): void {
    this.path = (this.editor &&
      this.editor.selection &&
      this.editor.selection.anchor &&
      this.editor.selection.anchor.path &&
      this.editor.selection.anchor.path.slice(0, 1)) || [0];
  },

  rowsShouldBeIncreased(): boolean {
    return (
      this.numOfRows > (this.currentDimensions && this.currentDimensions.rows)
    );
  },

  rowsShouldBeDecreased(): boolean {
    return (
      this.numOfRows < (this.currentDimensions && this.currentDimensions.rows)
    );
  },

  colsShouldBeIncreased(): boolean {
    return (
      this.numOfColumns >
      (this.currentDimensions && this.currentDimensions.cols)
    );
  },

  colsShouldBeDecreased(): boolean {
    return (
      this.numOfColumns <
      (this.currentDimensions && this.currentDimensions.cols)
    );
  },

  setStartIndex(): void {
    this.startIndex =
      this.numOfRows - (this.numOfRows - this.currentDimensions.rows);
  },

  makeRows({ addFirstRow = false, editMode = false }): RichTableRow[] {
    const row: RichTableRow = { type: 'table-row', id: 0, children: [] };
    const firstRow = _.cloneDeep(row);
    const rows = [];

    const requiredCols = editMode
      ? this.currentDimensions.cols
      : this.numOfColumns;

    for (
      let rowIndex = this.startIndex;
      rowIndex < this.numOfRows;
      rowIndex += 1
    ) {
      const thisIsTheFirstRow = addFirstRow && rowIndex === 0;
      const currentRow = _.cloneDeep(row);

      for (let colIndex = 0; colIndex < requiredCols; colIndex += 1) {
        if (thisIsTheFirstRow) {
          firstRow.id = rowIndex;
          firstRow.children.push({
            type: 'table-cell',
            id: colIndex,
            children: [
              {
                type: 'paragraph',
                children: [{ text: '' }],
              },
            ],
          });
        } else {
          currentRow.id = rowIndex;
          currentRow.children.push({
            type: 'table-cell',
            id: colIndex,
            children: [{ type: 'paragraph', children: [{ text: '' }] }],
          });
        }
      }

      if (thisIsTheFirstRow) {
        rows.push(_.cloneDeep(firstRow));
      } else {
        rows.push(currentRow);
      }
    }

    return rows;
  },

  getRowSizeDifference(): number {
    return this.currentDimensions.cols - this.numOfColumns;
  },

  makeNewTable(): void {
    const table = {
      type: 'table',
      children: this.makeRows({ addFirstRow: true }),
      rows: this.numOfRows,
      cols: this.numOfColumns,
    };

    const selection = editor?.selection;
    const focus = selection?.focus;
    const path = focus?.path || [];
    const pathRootIndex = path[0];

    const isInsideNoInsertTableNode = (): boolean => {
      const childrenOfSelectionRootNode = editor.children[pathRootIndex];
      const { type: selectRootType = '' } = childrenOfSelectionRootNode;

      // TODO March 15, 2023.check if table is being inserted into table cell.
      if (selectRootType.includes('list')) {
        return true;
      }
      return false;
    };

    // TOOD need to fix for when table is inside insert tags -- cannot assume table is sitting at a root level node.
    try {
      if (!focus) {
        Transforms.insertNodes(
          editor,
          [
            {
              type: 'paragraph',
              children: [{ text: '' }],
            },
            table,
            {
              type: 'paragraph',
              children: [{ text: '' }],
            },
          ],
          {
            at: {
              offset: 0,
              path: [0],
            },
          }
        );
        ReactEditor.focus(editor);
        Transforms.select(editor, {
          anchor: Editor.start(editor, [2, 0, 0, 0, 0]),
          focus: Editor.start(editor, [2, 0, 0, 0, 0]),
        });
      } else {
        Transforms.insertNodes(
          editor,
          [
            {
              type: 'paragraph',
              children: [{ text: '' }],
            },
            table,
            {
              type: 'paragraph',
              children: [{ text: '' }],
            },
          ],
          {
            at: !isInsideNoInsertTableNode() ? focus : [pathRootIndex + 1],
          }
        );
        ReactEditor.focus(editor);
        Transforms.select(editor, {
          anchor: Editor.start(editor, [pathRootIndex + 2, 0, 0, 0, 0]),
          focus: Editor.start(editor, [pathRootIndex + 2, 0, 0, 0, 0]),
        });
      }
    } catch (e) {
      console.error(e);
    }
  },

  addColumns(): void {
    let increaseColumnsBy = this.numOfColumns - this.currentDimensions.cols;
    let columnIndex = this.currentDimensions.cols;

    const newColumns = [];
    const firstColumns = [];

    for (increaseColumnsBy; increaseColumnsBy > 0; increaseColumnsBy -= 1) {
      columnIndex += 1;

      firstColumns.push({
        type: 'table-cell',
        id: columnIndex - 1,
        children: [{ type: 'paragraph', children: [{ text: '' }] }],
      });

      newColumns.push({
        type: 'table-cell',
        id: columnIndex - 1,
        children: [{ text: '' }],
      });
    }

    try {
      for (
        let rowIndex = 0;
        rowIndex < this.numOfRows - increaseColumnsBy;
        rowIndex += 1
      ) {
        if (rowIndex === 0) {
          Transforms.insertNodes(editor, _.cloneDeep(firstColumns), {
            at: [this.path[0], rowIndex, this.currentDimensions.cols],
          });
        } else {
          Transforms.insertNodes(editor, _.cloneDeep(newColumns), {
            at: [this.path[0], rowIndex, this.currentDimensions.cols],
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  },

  removeColumns(): void {
    const colSizeDiff = this.currentDimensions.cols - this.numOfColumns;
    const columnIDs = this.calculateIDsToRemove(
      colSizeDiff,
      this.currentDimensions.cols
    );
    try {
      columnIDs.forEach((id) => {
        Transforms.removeNodes(editor, {
          at: this.path,
          match: (n: Node) =>
            (n as Element).type === 'table-cell' && (n as Element).id === id,
        });
      });
    } catch (e) {
      console.error(e);
    }
  },

  addRows(): void {
    const newRows = this.makeRows({ editMode: true });
    try {
      Transforms.insertNodes(editor, newRows, {
        at: [this.path[0], this.currentDimensions.rows],
      });
    } catch (e) {
      console.error(e);
    }
  },

  removeRows(): void {
    const rowSizeDiff = this.currentDimensions.rows - this.numOfRows;
    const rowIDs = this.calculateIDsToRemove(
      rowSizeDiff,
      this.currentDimensions.rows
    );

    try {
      rowIDs.forEach((id: number) => {
        Transforms.removeNodes(editor, {
          at: this.path,
          match: (n: Node) =>
            (n as Element).type === 'table-row' && (n as Element).id === id,
        });
      });
    } catch (e) {
      console.error(e);
    }
  },

  calculateIDsToRemove(sizeDiff: number, nodeLength: number): number[] {
    const indices = [];
    let localNodeLength = nodeLength;

    for (let i = sizeDiff; i > 0; i -= 1) {
      localNodeLength -= 1;
      indices.push(localNodeLength);
    }

    return indices;
  },

  updateTableSizeProperties(): void {
    try {
      Transforms.setNodes(
        editor,
        { cols: numOfColumns, rows: numOfRows },
        {
          at: this.path,
        }
      );
    } catch (e) {
      console.error(e);
    }
  },
});

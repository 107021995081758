import _, { some } from 'lodash';
import React, { useEffect, useState } from 'react';
import type { Descendant, Editor, Node } from 'slate';

import Bold from '../../assets/RichTeIcons/icon-bold.svg';
import Underline from '../../assets/RichTeIcons/icon-editor-underline.svg';
import HighlightColour from '../../assets/RichTeIcons/icon-highlight-blank.svg';

import Italic from '../../assets/RichTeIcons/icon-italic.svg';
import TextColourLetterBlack from '../../assets/RichTeIcons/icon-text-colour-black.svg';
import ExclaimIcon from '../../assets/icon-exclaim-error.svg';
import AddImageButton from './AddImage/AddImageButton';
import BlockButtons from './BlockButtons';
import ClearColorButton from './ColorMenu/ClearColorButton';
import ColorButton from './ColorMenu/ColorButton';
import ColorMenu from './ColorMenu/ColorMenu';
import { LinkMenu, LinkMenuItem } from './Links/LinkMenu';
import IndentationButtons from './IndentationButtons';
import MarkButton from './MarkButton';
import RedoButton from './RedoButton';
import type { FileToAddInformation, ImageMetadata } from './AddImage/Image';
import SanitiseTool from './SanitiseTool';
import TableButton from './TableButton';
import {
  toolBarColors,
  Divider,
  NoAutoSavetext,
  ToolBarWrapper,
  UndoRedoWrapper,
  NoAutoSaveContainer,
} from './ToolBarStyles';
import UndoButton from './UndoButton';
import ShowDiffButton from './Diff/ShowDiffButton';

import AddImageDirect from './AddImage/AddImageDirect';
import { DragAndDropTool } from './DragAndDropTool';

export type RichTextToolbarProps = {
  allowUploadImages?: boolean;
  fixedToolbar: boolean;
  editor: Editor;
  handleDiffDisplaySwitch: () => void;
  handleImageUpload?: (fileToUpload: FileToAddInformation) => void;
  images: ImageMetadata[] | undefined;
  insertableLinks?: LinkMenuItem[];
  onBlur: (overrideFocus: boolean) => void;
  onSanitiseChange: (value: boolean) => void;
  resetMenus: boolean;
  showDiff: boolean;
  showDiffButton: boolean;
  value: Node[] | Descendant[];
  withImageReferences: boolean;
  enableDragAndDrop: boolean; // Add this line
  allowDragAndDrop: boolean;
  handleToggleDragAndDrop: () => void;
};

export default ({
  allowUploadImages = false,
  editor,
  showDiffButton = false,
  fixedToolbar = false,
  handleDiffDisplaySwitch,
  handleImageUpload,
  images,
  insertableLinks,
  onBlur,
  onSanitiseChange,
  resetMenus,
  showDiff = false,
  value,
  withImageReferences,
  enableDragAndDrop,
  allowDragAndDrop = false,
  handleToggleDragAndDrop,
}: RichTextToolbarProps): JSX.Element => {
  const [highlightMenu, toggleHighlightMenu] = useState(false);
  const [showLinkMenu, toggleShowLinkMenu] = useState(false);
  const [textMenu, toggleTextMenu] = useState(false);
  const [tableMenu, createAndEditTableMenu] = useState(false);

  useEffect(() => {
    toggleHighlightMenu(false);
    toggleTextMenu(false);
    createAndEditTableMenu(false);
  }, [resetMenus]);

  const hasInsertAbleLinks = some(insertableLinks);

  return (
    <ToolBarWrapper data-testid="ToolBar" $fixedToolbar={fixedToolbar}>
      <MarkButton format="bold" icon={Bold} data-testid="BoldButton" />
      <MarkButton format="italic" icon={Italic} data-testid="ItalicButton" />
      <MarkButton
        format="underline"
        icon={Underline}
        data-testid="UnderlineButton"
      />
      <Divider />
      <ColorMenu
        showMenu={textMenu}
        menuToggle={toggleTextMenu}
        toggleOtherMenus={{
          siblingColourMenu: toggleHighlightMenu,
          tableMenu: createAndEditTableMenu,
        }}
        otherMenuStates={[highlightMenu, tableMenu, showLinkMenu]}
        type="textColor"
        label="Text color"
        icon={TextColourLetterBlack}
      >
        {Object.values(toolBarColors.textColor).map((color) => (
          <ColorButton
            type="textColor"
            color={color}
            key={`textColorButton-${color}`}
          />
        ))}
      </ColorMenu>
      <ColorMenu
        type="highlightColor"
        showMenu={highlightMenu}
        menuToggle={toggleHighlightMenu}
        toggleOtherMenus={{
          siblingColourMenu: toggleTextMenu,
          tableMenu: createAndEditTableMenu,
        }}
        otherMenuStates={[textMenu, tableMenu]}
        label="Highlight color"
        icon={HighlightColour}
      >
        <>
          {Object.values(toolBarColors.highlightColor).map((color) => (
            <ColorButton
              type="highlightColor"
              color={color}
              key={`highlightColorButton-${color}`}
            />
          ))}
          <ClearColorButton type="highlightColor" />
        </>
      </ColorMenu>
      <Divider />
      {some(images) && !allowUploadImages && (
        <>
          <AddImageButton
            images={images}
            withImageReferences={withImageReferences}
          />
        </>
      )}
      {allowUploadImages && handleImageUpload && (
        <AddImageDirect handleImageUpload={handleImageUpload} />
      )}
      {hasInsertAbleLinks && (
        <LinkMenu
          showMenu={showLinkMenu}
          menuToggle={toggleShowLinkMenu}
          toggleOtherMenus={{
            siblingColourMenu: toggleTextMenu,
            tableMenu: createAndEditTableMenu,
          }}
          otherMenuStates={[textMenu, tableMenu, highlightMenu]}
          links={insertableLinks as LinkMenuItem[]}
        />
      )}
      {(allowUploadImages || hasInsertAbleLinks) && <Divider />}
      <BlockButtons />
      <Divider />
      <TableButton
        showMenu={tableMenu}
        toggleTextMenu={toggleTextMenu}
        toggleHighlightMenu={toggleHighlightMenu}
        otherMenuStates={[textMenu, highlightMenu]}
        menuToggle={createAndEditTableMenu}
        onBlur={onBlur}
      />
      <Divider />
      <IndentationButtons />
      <Divider />
      <SanitiseTool
        onChange={(sanitiseValue) => {
          onSanitiseChange(sanitiseValue);
        }}
      />
      <Divider />
      {showDiffButton && (
        <>
          <ShowDiffButton
            isDiffDisplayed={showDiff}
            switchDisplayHandler={handleDiffDisplaySwitch}
          />
          {showDiff && (
            <NoAutoSaveContainer>
              <NoAutoSavetext>
                <img
                  style={{
                    position: 'relative',
                    top: '4px',
                    marginRight: '4px',
                  }}
                  src={ExclaimIcon}
                  alt="autosave-exclaim-icon"
                  // onMouseEnter={() => {
                  //   setAutoSaveTooltipIsVisible(true);
                  // }}
                  // onMouseLeave={() => {
                  //   setAutoSaveTooltipIsVisible(false);
                  // }}
                />
                Editing Disabled
              </NoAutoSavetext>
              {/* <AutoSaveToolTipContainer>
                <ToolTip
                  text="To enable auto-saving, turn off the comparison control."
                  $isVisible={autoSaveTooltipIsVisible}
                  controlTipWidth={160}
                  topOffset={26}
                >
                  <img
                    style={{ position: 'relative', top: '1px' }}
                    src={InfoIcon}
                    alt="autosave-tooltip"
                    onMouseEnter={() => {
                      setAutoSaveTooltipIsVisible(true);
                    }}
                    onMouseLeave={() => {
                      setAutoSaveTooltipIsVisible(false);
                    }}
                  />
                </ToolTip>
              </AutoSaveToolTipContainer> */}
            </NoAutoSaveContainer>
          )}
          <Divider />
        </>
      )}
      <DragAndDropTool
        enableDragAndDrop={enableDragAndDrop}
        allowDragAndDrop={allowDragAndDrop}
        handleToggleDragAndDrop={handleToggleDragAndDrop}
      />
      {/* TODO: Add when designs are finalised */}
      {/* <MoreOptionsButton /> */}
      <UndoRedoWrapper>
        <>
          <UndoButton editor={editor} value={value} />
          <RedoButton editor={editor} value={value} />
        </>
      </UndoRedoWrapper>
    </ToolBarWrapper>
  );
};

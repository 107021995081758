import type { DiffOperationType } from '@trustflight/tf-slate';
import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import type { Element } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';

import { Divider } from '../../ToolBarStyles';
import IconDeleteCol from '../assets/icon-editor-delete-column.svg';
import IconInsertColLeft from '../assets/icon-editor-insert-column-left.svg';
import IconInsertColRight from '../assets/icon-editor-insert-column-right.svg';
import IconDeleteRow from '../assets/icon-editor-delete-row.svg';
import IconInsertRowAbove from '../assets/icon-editor-insert-row-above.svg';
import IconInsertRowBelow from '../assets/icon-editor-insert-row-below.svg';
import IconDeleteTable from '../assets/icon-editor-delete-table.svg';
import { useRTEContext } from '../../RTEContext';
import CellMenuButton from './CellMenuButton';
import { CellActionButtonTypes } from './utils';

import {
  RichTextCell,
  CellMenu,
  CellMenuButtonContainer,
  RichTextCellStyleContainer,
} from './CellStyles';

interface TableCellProps {
  $diffOperationType: DiffOperationType;
  children: JSX.Element | JSX.Element[];
  element: Element;
  activeCell: number[];
  setActiveCell: Dispatch<SetStateAction<number[]>>;
}

export const TableCell = ({
  activeCell,
  element,
  $diffOperationType,
  children,
  setActiveCell,
}: TableCellProps): ReactElement => {
  const [menuShowStateOnCell, setMenuShowStateOnCell] = useState(false);
  const [menuShowStateOnMenuContainer, setMenuShowStateOnMenuContainer] =
    useState(false);
  const editor = useSlate();
  const cellPath = ReactEditor.findPath(editor, element);
  const fullPathCheckAgainstCellPath = editor?.selection?.anchor?.path
    ? [...editor?.selection?.anchor?.path].slice(0, cellPath.length)
    : [];

  const handleCellActionClick = (cellPos: number[]) => {
    setActiveCell(cellPos);
    setMenuShowStateOnCell(false);
    setMenuShowStateOnMenuContainer(false);
  };

  const { state: RTEState } = useRTEContext();
  const { userDisabled: editDisabled } = RTEState;

  return (
    <RichTextCell
      data-testid={`Table-Cell-${cellPath.join('')}`}
      onMouseEnter={() => {
        setMenuShowStateOnCell(true);
      }}
      onMouseLeave={() => {
        if (menuShowStateOnMenuContainer) {
          setTimeout(() => {
            setMenuShowStateOnCell(false);
          }, 50);
        } else {
          setMenuShowStateOnCell(false);
        }
      }}
      onClick={() => {
        if (!editDisabled) {
          setActiveCell(cellPath);
        }
      }}
    >
      <RichTextCellStyleContainer
        $diffOperationType={$diffOperationType}
        className={`
          ${menuShowStateOnCell || menuShowStateOnMenuContainer ? 'hover ' : ''}
          ${
            fullPathCheckAgainstCellPath.toString() === cellPath.toString()
              ? 'selected'
              : ''
          }
        `}
      >
        {children}
      </RichTextCellStyleContainer>

      {(menuShowStateOnCell || menuShowStateOnMenuContainer) &&
        activeCell.toString() === cellPath.toString() &&
        !editDisabled && (
          <CellMenu
            data-testid="TableCellPopUpMenu"
            onMouseEnter={() => {
              setMenuShowStateOnMenuContainer(true);
            }}
            onMouseLeave={(e) => {
              setMenuShowStateOnMenuContainer(false);
            }}
            contentEditable={false}
          >
            <CellMenuButtonContainer>
              <CellMenuButton
                colMenuButtonType={CellActionButtonTypes.DeleteCol}
                toolTipText="Delete Column"
                buttonIcon={IconDeleteCol}
                element={element}
                handleCellActionClick={handleCellActionClick}
              />
              <CellMenuButton
                colMenuButtonType={CellActionButtonTypes.InsertColLeft}
                toolTipText="Insert Column To Left"
                buttonIcon={IconInsertColLeft}
                element={element}
                handleCellActionClick={handleCellActionClick}
              />
              <CellMenuButton
                colMenuButtonType={CellActionButtonTypes.InsertColRight}
                toolTipText="Insert Column To Right"
                buttonIcon={IconInsertColRight}
                element={element}
                handleCellActionClick={handleCellActionClick}
              />
              <Divider />
              <CellMenuButton
                colMenuButtonType={CellActionButtonTypes.DeleteRow}
                toolTipText="Delete Row"
                buttonIcon={IconDeleteRow}
                element={element}
                handleCellActionClick={handleCellActionClick}
              />
              <CellMenuButton
                colMenuButtonType={CellActionButtonTypes.InsertRowAbove}
                toolTipText="Insert Row Above"
                buttonIcon={IconInsertRowAbove}
                element={element}
                handleCellActionClick={handleCellActionClick}
              />
              <CellMenuButton
                colMenuButtonType={CellActionButtonTypes.InsertRowBelow}
                toolTipText="Insert Row Below"
                buttonIcon={IconInsertRowBelow}
                element={element}
                handleCellActionClick={handleCellActionClick}
              />
              <Divider />
              <CellMenuButton
                colMenuButtonType={CellActionButtonTypes.DeleteTable}
                toolTipText="Delete Table"
                buttonIcon={IconDeleteTable}
                element={element}
                handleCellActionClick={handleCellActionClick}
              />
            </CellMenuButtonContainer>
          </CellMenu>
        )}
    </RichTextCell>
  );
};

export default TableCell;

import { AnimatePresence, motion } from 'framer-motion';
import type { Variants } from 'framer-motion';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TickSvg from '../../assets/tick.svg';
import DotsAnimation from './DotsAnimation';
import ErrorAnimation from './ErrorAnimation';
import TickAnimation from './TickAnimation';

const Wrapper = styled.div`
  display: flex;
`;

const TickIcon = styled.img`
  width: 14px;
  margin-top: 10px;
  margin-left: 6px;
  margin-bottom: 9px;
  margin-right: 9px;
`;

const StatusWrapper = styled(AnimatePresence)`
  position: relative;
`;

const StatusText = styled(motion.div)<{
  $isSaving?: boolean;
  $isError?: boolean;
}>`
  font-size: 14px;
  font-weight: 400;
  color: ${({ $isError }): string =>
    $isError ? 'var(--red)' : 'var(--brand-blue-70)'};
  padding-top: 6px;
`;

export enum SaveState {
  IDLE = 'IDLE',
  SAVING = 'SAVING',
  ERROR = 'ERROR',
}

export type SavingIndicatorProps = {
  state: SaveState;
  statusText?: string | JSX.Element;
  'data-testid'?: string;
};

export const SaveTextBody: React.FC<
  SavingIndicatorProps & {
    savedComponent: JSX.Element;
  }
> = ({ 'data-testid': dataTestId, statusText, state, savedComponent }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div>
        {state === SaveState.SAVING && (
          <DotsAnimation data-testid={`${dataTestId}_DotsAnimation`} />
        )}
        {state === SaveState.IDLE && savedComponent}
        {state === SaveState.ERROR && <ErrorAnimation />}
      </div>
      <div>{statusText}</div>
    </div>
  );
};

export const SaveIndicatorText: React.FC<
  SavingIndicatorProps & {
    hasSaved: boolean;
    variants: Variants;
    savedComponent: JSX.Element;
  }
> = ({
  'data-testid': dataTestId,
  state,
  statusText,
  hasSaved,
  variants,
  savedComponent,
}) => {
  return (
    <>
      {state === SaveState.SAVING && statusText && (
        <StatusText
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
          $isSaving
          data-testid={`${dataTestId}_SavingText`}
        >
          <SaveTextBody
            data-test-id={dataTestId}
            statusText={statusText}
            state={state}
            savedComponent={savedComponent}
          />
        </StatusText>
      )}
      {state === SaveState.IDLE && statusText && (
        <StatusText
          variants={variants}
          initial={hasSaved ? 'initial' : 'enter'}
          animate="enter"
          exit="exit"
          data-testid={`${dataTestId}_IdleText`}
        >
          <SaveTextBody
            data-testid={dataTestId}
            statusText={statusText}
            state={state}
            savedComponent={savedComponent}
          />
        </StatusText>
      )}
      {state === SaveState.ERROR && statusText && (
        <StatusText
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
          data-testid={`${dataTestId}_ErrorText`}
          $isError
        >
          <SaveTextBody
            data-testid={dataTestId}
            statusText={statusText}
            state={state}
            savedComponent={savedComponent}
          />
        </StatusText>
      )}
    </>
  );
};

export const SavingIndicator: React.FC<SavingIndicatorProps> = ({
  state,
  statusText,
  'data-testid': dataTestId = 'SavingIndicator',
}) => {
  const [hasSaved, setHasSaved] = useState<boolean>(false);

  const variants = {
    initial: {
      opacity: 0,
      x: 50,
    },
    enter: () => {
      return {
        x: 0,
        opacity: 1,
        transition: {
          type: 'spring',
          stiffness: 500,
          damping: 60,
          opacity: { duration: 0.2 },
        },
      };
    },
    exit: () => {
      return {
        x: 50,
        opacity: 0,
        transition: {
          opacity: {
            duration: 0.2,
          },
        },
      };
    },
  };
  useEffect(() => {
    if (state === SaveState.SAVING && !hasSaved) {
      setHasSaved(true);
    }
  }, [hasSaved, state]);
  const savedComponent = hasSaved ? (
    <TickAnimation data-testid={`${dataTestId}_TickAnimation`} />
  ) : (
    <TickIcon src={TickSvg} data-testid={`${dataTestId}_TickIcon`} />
  );

  return (
    <Wrapper data-testid={dataTestId}>
      <StatusWrapper>
        <SaveIndicatorText
          data-testid={dataTestId}
          state={state}
          statusText={statusText}
          hasSaved={hasSaved}
          variants={variants}
          savedComponent={savedComponent}
        />
      </StatusWrapper>
    </Wrapper>
  );
};

export default SavingIndicator;

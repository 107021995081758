import { useSelect } from 'downshift';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { usePrevious } from '../../utils/utils';
import DropdownIconSvg from '../../assets/dropdown-icon.svg';
import Portal from '../../utils/Portal';
import {
  PageDropdownWrapper,
  PageDropdownTextandBoxWrapper,
  PageDropdownText,
  PageSizeBox,
  PageSizeLabel,
  DropdownIconWrapper,
  DropdownIcon,
  PageDropdownAnchorPoint,
  PageSizeMenuWrapper,
  PageSizeMenuItem,
} from './TableStyling';

type PaginationDropdownProps = {
  pageSize: number;
  setPageSize: (size: number) => void;
};

export const PaginationDropdown = ({
  pageSize,
  setPageSize,
}: PaginationDropdownProps): JSX.Element => {
  const optionsArray: number[] = [10, 25, 50, 100];
  const {
    isOpen,
    selectedItem,
    highlightedIndex,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items: optionsArray,
    onSelectedItemChange: (changes) => {
      setPageSize(changes.selectedItem as number);
    },
  });

  const anchorPointRef = useRef<HTMLDivElement>(null);
  const pageSizeTextRef = useRef<HTMLDivElement>(null);
  const [pageSizeMenuY, setPageSizeMenuY] = useState<number>(0);
  const [pageSizeMenuX, setPageSizeMenuX] = useState<number>(0);

  const prevIsOpen = usePrevious(isOpen);
  const prevPageSize = usePrevious(pageSize);

  const calculatePosition = useCallback(() => {
    if (
      (prevPageSize !== pageSize || prevIsOpen !== isOpen) &&
      anchorPointRef &&
      anchorPointRef.current &&
      pageSizeTextRef &&
      pageSizeTextRef.current
    ) {
      const textWidth = pageSizeTextRef.current.getBoundingClientRect().width;
      const rect = anchorPointRef?.current?.getBoundingClientRect();
      setPageSizeMenuX(rect.left + window.scrollX + textWidth);
      setPageSizeMenuY(rect.top + window.scrollY);
    }
  }, [isOpen, pageSize, prevIsOpen, prevPageSize]);

  useEffect(() => {
    calculatePosition();
  }, [calculatePosition]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (isOpen) {
        calculatePosition();
      }
    });
  }, [calculatePosition, isOpen]);
  return (
    <PageDropdownWrapper data-testid="PageDropdownWrapper">
      <PageDropdownTextandBoxWrapper>
        <PageDropdownText data-testid="pageDropdownText" ref={pageSizeTextRef}>
          Items per page:
        </PageDropdownText>
        <PageSizeBox {...getToggleButtonProps()} data-testid="PageSizeBox">
          <PageSizeLabel data-testid="PageSizeLabel">{pageSize}</PageSizeLabel>
          <DropdownIconWrapper data-testid="DropdownIconWrapper">
            <DropdownIcon src={DropdownIconSvg} isFocused={isOpen} />
          </DropdownIconWrapper>
        </PageSizeBox>
      </PageDropdownTextandBoxWrapper>
      <PageDropdownAnchorPoint
        ref={anchorPointRef}
        data-testid="PageDropdownAnchorPoint"
      />
      <Portal>
        <PageSizeMenuWrapper
          {...getMenuProps()}
          isOpen={isOpen}
          left={pageSizeMenuX}
          top={pageSizeMenuY}
        >
          {isOpen &&
            optionsArray.map((value: number, index) => {
              return (
                <PageSizeMenuItem
                  key={`pageSizeMenuItem-${value}`}
                  {...getItemProps({ item: value, index })}
                  isSelected={selectedItem === value}
                  isHighlighted={highlightedIndex === index}
                  data-testid={`PageSizeMenuItem-${index}`}
                >
                  {value}
                </PageSizeMenuItem>
              );
            })}
        </PageSizeMenuWrapper>
      </Portal>
    </PageDropdownWrapper>
  );
};

export default PaginationDropdown;

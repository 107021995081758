import React from 'react';
import styled from 'styled-components';
import CloseIconSvg from '../../assets/close-icon-mobile-white.svg';

const StyledCloseButton = styled.button`
  padding-top: 21px;
  cursor: pointer;
  background: transparent;
  border: none;
`;

const CloseIcon = styled.img`
  width: 15px;
  color: var(--white);
  cursor: pointer;
`;

type CloseButtonProps = {
  closeFileView: () => void;
};

export default ({ closeFileView }: CloseButtonProps): JSX.Element => (
  <StyledCloseButton
    onClick={closeFileView}
    type="button"
    data-testid="CloseButton"
  >
    <CloseIcon src={CloseIconSvg} alt="close" />
  </StyledCloseButton>
);

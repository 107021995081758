import React, { RefObject, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { usePrevious } from '../../utils/utils';
import type { MenuItem } from './ContextTabs';

const OptionButton = styled.div<{
  isSelected: boolean;
  secondaryStyle?: boolean;
}>`
  width: fit-content;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  padding-left: var(--spacing-24);
  padding-right: var(--spacing-24);
  padding-bottom: 10px;
  padding-top: 10px;
  box-shadow: ${({ secondaryStyle, isSelected }): string =>
    secondaryStyle && isSelected ? '0px 0px 6px rgba(0, 0, 0, 0.15)' : 'none'};
  background-color: ${({ secondaryStyle, isSelected, theme }): string =>
    secondaryStyle && isSelected ? theme.colors.white : 'transparent'};
  margin-top: ${({ secondaryStyle, isSelected }) => {
    if (secondaryStyle) {
      if (isSelected) {
        return '3px';
      }
      return '4px';
    }
    return '0px';
  }};
  border-radius: ${({ secondaryStyle }): string =>
    secondaryStyle ? '4px 4px 0px 0px' : '0px'};
  color: ${({ isSelected, theme }): string =>
    isSelected ? theme.colors.brandBlue : theme.colors.black50Alpha};
  cursor: pointer;
  border-top: solid 1px transparent;
  border-bottom: solid 2px
    ${({ isSelected, theme }): string =>
      isSelected ? theme.colors.brandBlue : 'transparent'};
  &:hover {
    transition: color 0.4s;
    transition: border-bottom 0.4s;
    cursor: pointer;
    color: ${({ isSelected, theme }): string =>
      !isSelected ? theme.colors.brandBlue80Alpha : theme.colors.brandBlue};
    border-bottom: solid 2px
      ${({ isSelected, theme }): string =>
        isSelected ? theme.colors.brandBlue : theme.colors.brandBlue80Alpha};
  }
  &:focus {
    outline: none;
    border: solid 1px ${({ theme }): string => theme.colors.brandBlue};
    ${({ isSelected, theme }): string =>
      isSelected ? `border-bottom: solid 2px ${theme.colors.brandBlue};` : ''};
    padding-left: 24px;
    padding-right: 24px;
  }
  &:active {
    border: solid 1px transparent;
    border-left: none;
    border-right: none;
    border-bottom: solid 2px;
    ${({ isSelected, theme }): string =>
      isSelected ? theme.colors.brandBlue : 'transparent'};
  }
`;

export type TabProps = {
  item: MenuItem;
  isSelected: boolean;
  onClick: () => void;
  parentRef: RefObject<HTMLDivElement>;
  secondaryStyle?: boolean;
};

export const Tab: React.FC<TabProps> = ({
  item,
  isSelected,
  onClick,
  parentRef,
  secondaryStyle,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const prevIsSelected = usePrevious(isSelected);

  useEffect(() => {
    if (
      isSelected &&
      isSelected !== prevIsSelected &&
      ref.current &&
      parentRef.current
    ) {
      const rect = ref.current.getBoundingClientRect();
      parentRef.current.scrollTo({ left: rect.left, behavior: 'smooth' });
    }
  });
  return (
    <OptionButton
      key={`OptionButton-${item.value}`}
      data-testid={`OptionButton-${item.value}`}
      isSelected={isSelected}
      onClick={() => {
        onClick();
        if (ref.current) {
          ref.current.blur();
        }
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onClick();
        }
      }}
      tabIndex={0}
      ref={ref}
      secondaryStyle={secondaryStyle}
    >
      {item.label}
    </OptionButton>
  );
};

export default Tab;

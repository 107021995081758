import React, { useRef } from 'react';
import type { MenuItem } from './SideNav';
import { CollapsedSubListItem } from './SideNavStyles';

export type CollapsedSubItemProps = {
  expanded: boolean;
  selected: boolean;
  selectItem: (item: MenuItem) => void;
  item: MenuItem;
  subItem: MenuItem;
};

export const CollapsedSubItem: React.FC<CollapsedSubItemProps> = ({
  expanded,
  selected,
  selectItem,
  item,
  subItem,
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  const handleSubItemClick = (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.KeyboardEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault();
    selectItem(subItem);
  };
  return (
    <CollapsedSubListItem
      type="button"
      onClick={(event) => {
        handleSubItemClick(event);
        if (ref.current) {
          ref.current.blur();
        }
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleSubItemClick(event);
        }
      }}
      expanded={expanded}
      selected={selected}
      tabIndex={expanded ? 0 : -1}
      data-testid={`subItemButton-${item.value}`}
      key={`collapsedSubListItemButton-${item.value}-${subItem.value}`}
      ref={ref}
    >
      <span>{subItem.label}</span>
    </CollapsedSubListItem>
  );
};

export default CollapsedSubItem;

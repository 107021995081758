import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const ImageReferenceWrapper = styled.span`
  color: var(--brand-blue);
  font-size: 0.8em;
`;

interface ImageReferenceProps {
  children: JSX.Element | JSX.Element[];
}

const ImageReference: FunctionComponent<ImageReferenceProps> = ({
  children,
}) => {
  return <ImageReferenceWrapper>{children}</ImageReferenceWrapper>;
};

export default ImageReference;

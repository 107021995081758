import React, { useCallback, useEffect } from 'react';
import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import AIPurple from '../../assets/ai-purple.svg';
import AIGreen from '../../assets/ai-green.svg';
import ToolTip from './ToolTip';
import { ToolButton, ToolButtonWrapper } from './ToolBarStyles';
import { verifySlateDocShape } from './utils/verify-slate-shape';
import { cleanUpNodesBeforeSave } from './utils/utils';

type DragAndDropToolProps = {
  enableDragAndDrop: boolean;
  allowDragAndDrop: boolean;
  handleToggleDragAndDrop: () => void;
};

export const DragAndDropTool: React.FC<DragAndDropToolProps> = ({
  enableDragAndDrop,
  allowDragAndDrop,
  handleToggleDragAndDrop,
}) => {
  const [aiTooltipIsVisible, setAITooltipIsVisible] = React.useState(false);

  if (!allowDragAndDrop) {
    return null;
  }

  return (
    <ToolButtonWrapper>
      <ToolTip
        text={
          enableDragAndDrop
            ? 'AI Drag and Drop (Beta) Enabled.'
            : 'Activate to drag images with AI formatting in the editor.'
        }
        $isVisible={aiTooltipIsVisible}
      >
        <ToolButton
          data-testid="ToggleAIDragDrop"
          onClick={() => handleToggleDragAndDrop()}
          onMouseEnter={() => {
            setAITooltipIsVisible(true);
          }}
          onMouseLeave={() => {
            setAITooltipIsVisible(false);
          }}
          type="button"
        >
          <img
            style={{
              width: '20px',
              height: '20px',
            }}
            src={enableDragAndDrop ? AIGreen : AIPurple}
            alt="Toggle AI Feature"
          />
        </ToolButton>
      </ToolTip>
    </ToolButtonWrapper>
  );
};

async function uploadImage(
  file: File,
  setIsImageUploading: (isLoading: boolean) => void
): Promise<Node[]> {
  setIsImageUploading(true);

  const formData = new FormData();
  formData.append('format', 'slate');
  formData.append('image', file);

  try {
    const response = await fetch(
      'https://p01--server--drgxflw9s4r9.code.run/extract-text',
      {
        method: 'POST',
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error('Failed to upload image');
    }

    const jsonResponseString = await response.json();
    const nodes: Node[] = jsonResponseString as Node[];

    return nodes;
  } finally {
    setIsImageUploading(false);
  }
}

export const useDragAndDrop = (
  editor: Editor,
  enableDragAndDrop: boolean,
  setIsImageUploading: (isLoading: boolean) => void,
  onChange: ((newValue: Node[]) => void) | undefined
): void => {
  const handleDrop = useCallback(
    async (e: DragEvent) => {
      if (!enableDragAndDrop) return;
      e.preventDefault();
      const { files } = e.dataTransfer as DataTransfer;

      if (files.length > 0 && editor) {
        const file = files[0];
        try {
          const uploadedImageData: Node[] = await uploadImage(
            file,
            setIsImageUploading
          );
          Transforms.insertNodes(editor, uploadedImageData, {
            at: Editor.end(editor, []),
          });
          if (onChange) {
            const cleanedValueToSave = cleanUpNodesBeforeSave(editor.children);
            if (verifySlateDocShape(cleanedValueToSave)) {
              onChange(cleanedValueToSave);
            }
          }
        } catch (error) {
          console.error('Failed to upload image:', error);
        }
      }
    },
    [editor, enableDragAndDrop, setIsImageUploading, onChange]
  );

  useEffect(() => {
    const editorEl = ReactEditor.toDOMNode(editor, editor);

    editorEl.addEventListener('drop', handleDrop);

    return () => {
      editorEl.removeEventListener('drop', handleDrop);
    };
  }, [editor, handleDrop]);
};

import { DiffOperationType } from '@trustflight/tf-slate';
import styled from 'styled-components';
import { diffColorStyles } from '../Diff/DiffElements';

export const AddImageMenuOuterWrapper = styled.div`
  position: fixed;
  z-index: 1;
`;

export const AddImageMenuWrapper = styled.div`
  background: var(--white);
  border: 1px solid var(--white);
  border-radius: 4px;
  box-shadow: 0 0 10px 0 var(--black-10);
  font-size: 13px;
  margin: 0 0 0 10px;
`;

export const ImageWrapper = styled.span<{
  $diffOperationType?: DiffOperationType;
}>`
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 0;
  max-width: 600px;
  min-width: 300px;
  width: fit-content;
  margin: 16px 0 10px 0;
  border: ${({ $diffOperationType, theme }) => {
    if (!$diffOperationType) {
      return 'none';
    }

    return diffColorStyles(
      theme,
      $diffOperationType === DiffOperationType.Delete ? 'red' : 'green',
      true
    );
  }};
`;

export const EditorImage = styled.img<{ $isSelected?: boolean }>`
  display: block;
  max-width: 100%;
  max-height: 20em;
  position: relative;
  box-shadow: ${({ $isSelected }): string =>
    $isSelected ? '0 0 0 3px #B4D5FF' : 'none'};
`;

export const ImageCaption = styled.span`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;

export const ImageCaptionTextArea = styled.textarea`
  background-color: #f3f7fc;
  border-style: none;
  font-size: 14px;
  font: inherit;
  line-height: 16px;
  margin-top: ${({ theme }): string => theme.spacing.space4};
  margin-bottom: ${({ theme }): string => theme.spacing.space4};
  overflow: hidden;
  padding: ${({ theme }): string =>
    `${theme.spacing.space8} ${theme.spacing.space16}`};
  text-align: center;
  resize: none;
  width: 100%;
  &:focus-visible {
    border: none;
    background-color: white;
    outline: 1px solid blue;
  }
`;

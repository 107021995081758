import React from 'react';
import styled from 'styled-components';

export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export interface ButtonProps {
  ariaDisabled?: boolean;
  dataTestId?: string | undefined;
  primary?: boolean;
  onClick: (e: React.MouseEvent) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  square?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  size?: ButtonSize;
  padding?: string;
  height?: string;
}

const ButtonWrapper = styled.button<{
  primary: boolean;
  square?: boolean;
  size?: ButtonSize;
  padding?: string;
  height?: string;
}>`
  border-radius: ${({ square }): string => (square ? '' : '16px')};
  padding: ${({ padding }): string => (padding ? `${padding}` : '0 16px')};
  opacity: ${({ disabled }): string => (disabled ? '0.4;' : '1;')};
  height: ${({ size, height }): string =>
    height
      ? `${height}`
      : (size === 'large' && '38px') ||
        (size === 'medium' && '30px') ||
        (size === 'small' && '14px') ||
        'unset'};
  background: ${({ primary, theme }): string =>
    primary ? theme.colors.brandBlue : theme.colors.white};
  border: ${({ primary, theme }): string =>
    primary
      ? `1px solid ${theme.colors.brandBlue}`
      : `1px solid ${theme.colors.black10Alpha}`};
  color: ${({ primary, theme }): string =>
    primary ? theme.colors.white : theme.colors.brandBlue};
  font-size: 14px;
  font-weight: 500;
  flex-shrink: 0;
  cursor: ${({ disabled }): string => (disabled ? 'default;' : 'pointer;')};
  transition: background 150ms ease-in-out, border 150ms ease-in-out,
    transform 100ms ease;

  &:hover {
    background: ${({ disabled, primary, theme }): string =>
      primary && !disabled ? theme.colors.sapphire : ''};
    background: ${({ disabled, primary, theme }): string =>
      !primary && !disabled ? theme.colors.white : ''};
    border: ${({ disabled, primary, theme }): string =>
      !primary && !disabled
        ? `1px solid ${theme.colors.brandBlue20Alpha}`
        : ''};
  }
  &:active {
    background: ${({ primary, theme }): string =>
      primary ? `${theme.colors.brandBlue}` : '#fafafa'};
    border: ${({ primary, theme }): string =>
      primary ? `` : `1px solid ${theme.colors.brandBlue}`};
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px rgba(126, 187, 239);
  }
`;

export const Button: React.FC<ButtonProps> = ({
  ariaDisabled = false,
  dataTestId = undefined,
  primary = true,
  onClick,
  onMouseEnter,
  onMouseLeave,
  children,
  square,
  disabled = false,
  size,
  padding,
  height,
}) => {
  return (
    <ButtonWrapper
      aria-disabled={ariaDisabled ? 'true' : 'false'}
      data-testid={dataTestId}
      primary={primary}
      square={square}
      type="button"
      onClick={onClick}
      disabled={disabled}
      size={size}
      padding={padding}
      height={height}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </ButtonWrapper>
  );
};

import { DiffOperationType as DiffType } from '@trustflight/tf-slate';
import styled, { DefaultTheme, css } from 'styled-components';

type DiffOperationType = DiffType.Insert | DiffType.Delete;

export const getElInsertStyles = (theme: DefaultTheme): any => css`
  background: ${theme.colors.green20Alpha};
  border: 1px solid ${theme.colors.green80Alpha};
`;

export const getElDeleteStyles = (theme: DefaultTheme): any => css`
  background: ${theme.colors.red20Alpha};
  border: 1px solid ${theme.colors.red80Alpha};
`;

const baseInlineStyles = css`
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
`;

export const diffColorStyles = (
  theme: DefaultTheme,
  color: string,
  withBorder?: boolean
): ReturnType<typeof css> => css`
  background: ${theme.colors[`${color}20Alpha`]};
  ${withBorder
    ? `border: 2px solid ${theme.colors[`${color}80Alpha`]};`
    : `box-shadow: inset 0 -2px 0 ${
        theme.colors[`${color}80Alpha`]
      }, inset 0 2px 0 ${theme.colors[`${color}80Alpha`]};`}
`;

export const getDiffStyles = (
  theme: DefaultTheme,
  $diffOperationType?: DiffOperationType
): ReturnType<typeof css> => {
  switch ($diffOperationType) {
    case DiffType.Insert:
      return css`
        ${diffColorStyles(theme, 'green')}
      `;
    case DiffType.Delete:
      return css`
        ${diffColorStyles(theme, 'red')}
      `;
    default:
      return css``;
  }
};

export const DeleteInlineBlock = styled.span`
  ${baseInlineStyles}
  ${({ theme }) => diffColorStyles(theme, 'red')}
`;

export const InsertInlineBlock = styled.span`
  ${baseInlineStyles}
  ${({ theme }) => diffColorStyles(theme, 'green')}
`;

import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

type TruncateLongStringProps = {
  maxWidth?: string;
  className?: string;
};

const TruncateStringContent = styled.div.attrs(({ className }) => ({
  className,
}))<{ className: string; $maxWidth: string }>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: ${({ $maxWidth }) => `${$maxWidth};`};
`;

const TruncateString: FunctionComponent<TruncateLongStringProps> = ({
  maxWidth = '325px',
  children,
  className = '',
}) => {
  return (
    <TruncateStringContent className={className} $maxWidth={maxWidth}>
      {children}
    </TruncateStringContent>
  );
};

export default TruncateString;

import React, { useEffect, useState } from 'react';
// import mime from 'mime-types';
import { useSlate } from 'slate-react';
import { Editor, Transforms } from 'slate';

import inactiveIcon from '../../../assets/RichTeIcons/icon-add-image.svg';
import {
  ToolButtonWrapper,
  ToolButton,
  ToolButtonIcon,
} from '../ToolBarStyles';
import ToolTip from '../ToolTip';
import { useRTEContext } from '../RTEContext';
import type { FileToAddInformation } from './Image';

const AddImageDirect = ({
  handleImageUpload,
}: {
  handleImageUpload: (fileToUpload: FileToAddInformation) => void;
}): JSX.Element => {
  const [tooltipIsVisible, setTooltipIsVisible] = useState<boolean>(false);
  const { state: RTEState } = useRTEContext();
  const { userDisabled: editDisabled } = RTEState;
  const editor: Editor = useSlate();
  const [fileToUpload, setFileToUpload] = useState<FileToAddInformation | null>(
    null
  );

  useEffect(() => {
    if (fileToUpload?.file) {
      const selection = editor?.selection;
      const focus = selection?.focus;

      Transforms.insertNodes(
        editor,
        [
          {
            type: 'image-reference-with-display',
            description: '',
            // This is filterd out of Slate obj to save if user tries to save a loading text node
            loading: true,
            loadingReferenceId: fileToUpload?.file?.name,
            alt: '',
            mountFunction: () => {
              if (handleImageUpload) {
                handleImageUpload(fileToUpload);
              }
            },
            children: [
              {
                text: '',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [{ text: '' }],
          },
        ],
        {
          at: focus,
        }
      );

      setFileToUpload(null);
    }
  }, [editor, fileToUpload, handleImageUpload]);

  return (
    <ToolButtonWrapper>
      <ToolTip text="Select Image to Add" $isVisible={tooltipIsVisible}>
        <ToolButton
          data-testid="AddImageButton"
          disabled={editDisabled}
          $isDisabled={editDisabled}
          onClick={() => {
            const input = document.createElement('input');

            input.type = 'file';
            input.accept = 'image/png, image/jpeg';
            input.onchange = (e?: any) => {
              const file: File = e.target.files[0];
              const { name: fileName, size } = file;
              const idxDot = fileName.lastIndexOf('.') + 1;
              const extFile = fileName
                .substr(idxDot, fileName.length)
                .toLowerCase();

              if (size) {
                if (
                  extFile === 'png' ||
                  extFile === 'jpg' ||
                  extFile === 'jpeg'
                ) {
                  setFileToUpload({
                    file,
                    loadingReferenceId: fileName,
                  });
                } else {
                  // eslint-disable-next-line no-alert
                  alert('Only jpg, jpeg and png files are allowed.');
                }
              } else {
                // eslint-disable-next-line no-alert
                alert(
                  'Unable to upload the attachment.\nPlease ensure the attachment is larger than 0 bytes.'
                );
              }
            };

            input.click();
          }}
          onMouseEnter={() => {
            setTooltipIsVisible(true);
          }}
          onMouseLeave={() => {
            setTooltipIsVisible(false);
          }}
        >
          <ToolButtonIcon
            src={inactiveIcon}
            $isActive={false}
            $isDisabled={editDisabled}
          />
        </ToolButton>
      </ToolTip>
    </ToolButtonWrapper>
  );
};

export default AddImageDirect;

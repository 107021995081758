import React, { FunctionComponent } from 'react';
import ContentLoader from 'react-content-loader';
import { LoadingWrapper } from './TopNavStyles';

const TopNavLoading: FunctionComponent = () => {
  return (
    <LoadingWrapper data-testid="loadingWrapper">
      <ContentLoader viewBox="0 0 177 70" height={70} width={177}>
        <circle cx="40" cy="35" r="15" />
        <rect x="70" y="20" width="100" height="10" />
        <rect x="70" y="40" width="100" height="10" />
      </ContentLoader>
    </LoadingWrapper>
  );
};

export default TopNavLoading;

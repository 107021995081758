export default {
  initial: {
    opacity: 0,
    rotateX: '-0.2turn',
    transitionEnd: {
      display: 'none',
    },
  },
  enter: {
    opacity: 1,
    rotateX: '0turn',
    transition: {
      duration: 0.3,
      delay: 0.1,
    },
    display: 'block',
  },
  exit: {
    opacity: 0,
    rotateX: '-0.2turn',
    transition: {
      duration: 0.2,
    },
    transitionEnd: {
      display: 'none',
    },
  },
};

import React, { FunctionComponent, memo } from 'react';
import styled from 'styled-components';
import TruncateString from '../../../utils/TruncateString';

interface ImageMenuOptionProps {
  fileName: string;
  src: string;
}

const ImageOptionWrapper = styled.div`
  align-items: center;
  display: flex;
  padding: 10px;
  .image-option-thumbnail {
    object-fit: cover;
    max-height: 37px;
    width: auto;
  }
  .thumbnail-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    overflow: hidden;
    width: 40px;
  }
`;

const ImageMenuOption: FunctionComponent<ImageMenuOptionProps> = memo(
  ({ fileName, src }) => {
    return (
      <ImageOptionWrapper data-testid={`ImageOptionWrapper__${fileName}`}>
        <div className="thumbnail-wrapper">
          <img className="image-option-thumbnail" src={src} alt={fileName} />
        </div>
        <TruncateString maxWidth="150px">{fileName}</TruncateString>
      </ImageOptionWrapper>
    );
  }
);

export default ImageMenuOption;

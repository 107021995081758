import React, { FunctionComponent } from 'react';
import { useSlate } from 'slate-react';
import { isInsideAlertBox } from '../utils/utils';
import {
  CautionBoxOuterWrapper,
  CautionBoxWrapper,
  KeystrokeInstructions,
} from './alertBoxStyles';

interface CautionBoxProps {
  attributes: {
    'data-slate-node': 'element';
    'data-slate-inline'?: true | undefined;
    'data-slate-void'?: true | undefined;
    dir?: 'rtl' | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: any;
  };
  isCautionBox?: boolean;
}

const AlertBox: FunctionComponent<CautionBoxProps> = ({
  attributes,
  children,
  isCautionBox,
}) => {
  const preventSelection = (event: React.DragEvent) => {
    event.preventDefault();
  };
  const editor = useSlate();

  return (
    <CautionBoxOuterWrapper>
      <CautionBoxWrapper isCautionBox={isCautionBox} {...attributes}>
        <div className="header-wrapper unselectable" contentEditable={false}>
          <span>{isCautionBox ? 'Caution' : 'Warning'}</span>
        </div>
        <div className="content-wrapper">{children}</div>
      </CautionBoxWrapper>
      {isInsideAlertBox(editor) && (
        <KeystrokeInstructions contentEditable={false}>
          <div onDrag={preventSelection} className="instruction unselectable">
            <span className="instruction--emphasis">Enter</span> to start new
            paragraph
          </div>
          <div className="instruction unselectable">
            <span className="instruction--emphasis">Shift + Down Arrow</span> to
            exit box
          </div>
        </KeystrokeInstructions>
      )}
    </CautionBoxOuterWrapper>
  );
};

export default AlertBox;

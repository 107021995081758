import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import SortingArrowSvg from '../../assets/icon-sort.svg';
import { usePrevious } from '../../utils/utils';

const Wrapper = styled.div``;

const NumberInputWrapper = styled.div<{ focus: boolean; showTotal?: boolean }>`
  display: flex;
  border: 1px solid
    ${({ theme, focus }): string =>
      !focus ? theme.colors.black10Alpha : theme.colors.brandBlue};
  height: 32px;
  margin-right: ${({ showTotal = true }): string =>
    !showTotal ? '0px' : '5px'};
  &:hover {
    border: 1px solid
      ${({ theme, focus }): string =>
        !focus ? theme.colors.brandBlue20Alpha : theme.colors.brandBlue};
  }
`;

const NumberInput = styled.input`
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  text-align: right;
  line-height: normal;
  padding-top: 0px;
  padding-bottom: 0px;
  width: 100%;
  line-height: 32px;
  border: 0;
  padding-right: 30px;
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &:focus {
    outline: none;
  }
`;

const ButtonsOuterWrapper = styled.div`
  position: relative;
`;

const CounterButtonWrapper = styled.div`
  display: grid;
  position: absolute;
  height: 32px;
  right: 0;
`;

const CounterButton = styled.button<{ bottomDivider?: true }>`
  background: transparent;
  border: 1px solid ${({ theme }): string => theme.colors.black10Alpha};
  border-top: none;
  border-right: none;
  height: 16px;
  user-select: none;
  touch-action: none;
  border-bottom: ${({ bottomDivider, theme }): string =>
    bottomDivider ? `border: 1px solid ${theme.colors.black10Alpha}` : 'none;'};
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`;
const CounterIcon = styled.img<{ isDown?: boolean }>`
  width: 8px;
  color: ${({ theme }): string => theme.colors.brandBlue};
  ${({ isDown }): string => (isDown ? 'transform: rotate(180deg);' : '')};
`;

export interface NumberStepperProps {
  count?: number | null;
  min?: number;
  max?: number;
  placeholder?: string;
  onChange?: (value: number | null) => void;
  onBlur?: (value: number | null) => void;
  'data-testid'?: string;
  showTotal?: boolean;
}

export const NumberStepper: React.FC<NumberStepperProps> = ({
  count,
  min,
  max,
  placeholder,
  onBlur,
  onChange,
  'data-testid': dataTestId = 'NumberStepper',
  showTotal,
}) => {
  const initCout = count || count === 0 ? count : null;
  const [value, setValue] = useState<number | null>(initCout);
  const prevValue = usePrevious<number | null>(value);
  const inputRef = useRef<HTMLInputElement>(null);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    // count should never be undefined -- only null when not a number
    const newValue = count !== undefined && !Number.isNaN(count) ? count : null;

    setValue(newValue);
  }, [count]);

  useEffect(() => {
    // prevValue only undefined on first mount
    if (onChange && prevValue !== undefined && prevValue !== value) {
      onChange(value);
    }
  }, [value, prevValue, onChange]);

  return (
    <Wrapper data-testid={dataTestId}>
      <NumberInputWrapper
        onBlur={(e) => {
          if (!e.currentTarget.contains(e.relatedTarget as Node)) {
            setFocus(false);
            if (onBlur) {
              onBlur(value);
            }
          }
        }}
        onFocus={() => {
          setFocus(true);
        }}
        focus={focus}
        data-testid={`${dataTestId}_Wrapper`}
        showTotal={showTotal}
      >
        <NumberInput
          role="spinbutton"
          type="number"
          min={min}
          max={max}
          value={value || value === 0 ? value : ''}
          placeholder={placeholder}
          ref={inputRef}
          onKeyDown={(event) => {
            const prohibitedKeys = ['+', 'e', '.'];
            if (prohibitedKeys.includes(event.key)) {
              event.preventDefault();
              return;
            }
            if (event.key === '-') {
              const newValue = parseInt(`-${value || '1'}`, 10);
              if (
                (!min && min !== 0) ||
                ((min || min === 0) && newValue >= min)
              ) {
                setValue(newValue);
              }
              event.preventDefault();
            }
          }}
          onChange={(event) => {
            const targetValue = event.target.value;

            if (targetValue === '') {
              setValue(null);
            } else {
              const newValue = parseInt(targetValue, 10);

              if ((max || max === 0) && newValue > max) {
                setValue(max);
                event.preventDefault();
              } else if ((min || min === 0) && newValue < min) {
                setValue(min);
                event.preventDefault();
              } else {
                setValue(newValue);
              }
            }
          }}
          onPaste={(event) => {
            event.preventDefault();
          }}
          data-testid={`${dataTestId}_Input`}
        />
        <ButtonsOuterWrapper>
          <CounterButtonWrapper>
            <CounterButton
              type="button"
              onClick={(e): void => {
                e.stopPropagation();

                if (!value && value !== 0 && (min || min === 0)) {
                  setValue(min);
                } else if ((!value && value !== 0) || Number.isNaN(value)) {
                  setValue(1);
                } else if (max === undefined || value < max) {
                  setValue(value + 1);
                }
                inputRef.current?.focus();
              }}
              bottomDivider
              data-testid={`${dataTestId}_UpButton`}
              tabIndex={-1}
            >
              <CounterIcon src={SortingArrowSvg} />
            </CounterButton>
            <CounterButton
              type="button"
              onClick={(e): void => {
                e.stopPropagation();

                const currentValue = value || 0;
                if (!value && value !== 0 && (min || min === 0)) {
                  setValue(min);
                } else if ((!value && value !== 0) || Number.isNaN(value)) {
                  setValue(max ? max - 1 : 0);
                } else if (min === undefined || currentValue > min) {
                  setValue(currentValue - 1);
                }
                inputRef.current?.focus();
              }}
              data-testid={`${dataTestId}_DownButton`}
              tabIndex={-1}
            >
              <CounterIcon src={SortingArrowSvg} isDown />
            </CounterButton>
          </CounterButtonWrapper>
        </ButtonsOuterWrapper>
      </NumberInputWrapper>
    </Wrapper>
  );
};

export default NumberStepper;

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styled from 'styled-components';
import IconRemoveSvg from '../../assets/icon-close.svg';
import type { ChildFilterDefinition, FilterDefinition } from './FilterSideBar';

const FilterRow = styled.div`
  margin: 0.2rem 0;
  display: flex;
  align-items: center;
  width: 100%;
`;

const RemoveIcon = styled.img`
  height: 8px;
  position: absolute;
  margin-left: -12px;
`;

const Label = styled.span<StyledProps>`
  color: ${({ isChecked }) => (isChecked ? 'white;' : 'inherit;')};
  font-weight: normal;
  font-size: 12px;
  line-height: 2;
  padding-left: 12px;
`;

type StyledProps = {
  isChecked: boolean;
};

const LabelWrapper = styled.label<StyledProps>`
  padding-left: 12px;
  display: flex;
  align-items: center;
  padding-left: 26px;
  border-radius: 1.5rem;
  width: 180px;
  cursor: pointer;
  svg {
    flex-shrink: 0;
  }
  color: ${({ theme }) => theme.colors.black70Alpha};
  ${({ isChecked, theme }) =>
    isChecked
      ? ` background: ${theme.colors.brandBlue};
  color: white;`
      : null}
  transition: all 400ms;
  &:hover {
    ${({ isChecked, theme }) =>
      isChecked
        ? `color: white;
          background: ${theme.colors.brandBlue70Alpha};`
        : null};
  }
  .badge-wrapper {
    margin-right: 8px;
  }
`;

type ChildFilterButtonProps<T> = {
  childFilter: ChildFilterDefinition;
  updateFilterState: (options: {
    filter: FilterDefinition<T>;
    filterState: boolean;
  }) => void;
};

const ChildFilterButton = <T,>({
  childFilter,
  updateFilterState,
}: ChildFilterButtonProps<T>): JSX.Element => {
  const [isChecked, setIsChecked] = useState(false);

  const handleUpdateFilterState = () => {
    updateFilterState({
      filterState: !isChecked,
      filter: childFilter as FilterDefinition<T>,
    });
    setIsChecked(!isChecked);
  };

  return (
    <FilterRow onClick={handleUpdateFilterState}>
      <LabelWrapper isChecked={isChecked}>
        {isChecked && <RemoveIcon src={IconRemoveSvg} />}
        <Label isChecked={isChecked}>{childFilter.label}</Label>
      </LabelWrapper>
    </FilterRow>
  );
};

export default ChildFilterButton;

import { Editor, Transforms } from 'slate';
import {
  CreateImageNodeArgs,
  ImageElement,
  isImageUrl,
} from '../AddImage/AddImageButton';

const createImageNode = ({
  alt = 'Image',
  description,
  src,
  children,
}: CreateImageNodeArgs): ImageElement[] => {
  return [
    {
      alt,
      description,
      src,
      type: 'image',
      children: [children],
    },
  ];
};

const withImages = (editor: Editor): Editor => {
  const { insertData } = editor;

  // eslint-disable-next-line no-param-reassign
  editor.isVoid = (element) =>
    element.type === 'image' || element.type === 'image-reference-with-display';

  // eslint-disable-next-line no-param-reassign
  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    const image = createImageNode({
      src: text,
      alt: text,
      children: [{ text: '' }],
    });

    if (isImageUrl(text)) {
      Transforms.insertNodes(editor, image);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export default withImages;

import {
  Ancestor,
  Descendant,
  Editor,
  Element as SlateElement,
  Node,
  NodeEntry,
  Path,
  Text,
  Transforms,
} from 'slate';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - Imports will be there from the upstream patch
import { createHyperscript, createText } from 'slate-hyperscript';
import tableFactory from '../Table/tableFactory';

export const CustomSlateNodeTypes = {
  BulletedList: 'bulleted-list',
  NumberedList: 'numbered-list',
  ListItem: 'list-item',

  Table: 'table',
  TableRow: 'table-row',
  TableCell: 'table-cell',

  Paragraph: 'paragraph',
  P: 'p',
  Indentation: 'indentation',

  Link: 'link',

  Image: 'image',
  ImageReference: 'image-reference',
  ImageReferenceWithDisplay: 'image-reference-with-display',

  Insert: 'insert',
  Delete: 'delete',

  CautionBox: 'caution-box',
  WarningBox: 'warning-box',
};

const LIST_TYPES = [
  CustomSlateNodeTypes.NumberedList,
  CustomSlateNodeTypes.BulletedList,
];

export const paragraphNode = {
  type: CustomSlateNodeTypes.Paragraph,
  children: [{ text: '' }],
};

export const colorMatch = new RegExp(/#\w+/);

export const colors: Record<string, Record<string, string>> = {
  text: {
    black: '#000000',
    blue: '#1600ff',
    yellow: '#ffb84c',
  },
  highlight: {
    pink: '#fe02fe',
    flYellow: '#fef202',
    green: '#00ff00',
  },
};

export function insertIf(condition: boolean, ...elements: Node): Node[] {
  return condition ? elements : [];
}

// used in tests only - key tags are used in hyperscript when map here to types for slate
export const jsx = createHyperscript({
  elements: {
    p: { type: CustomSlateNodeTypes.Paragraph },
    paragraph: { type: CustomSlateNodeTypes.Paragraph },
    'bulleted-list': { type: CustomSlateNodeTypes.BulletedList },
    'list-item': { type: CustomSlateNodeTypes.ListItem },
    indentation: { type: CustomSlateNodeTypes.Indentation },
    table: { type: CustomSlateNodeTypes.Table },
    link: { type: CustomSlateNodeTypes.Link },
    'table-row': { type: CustomSlateNodeTypes.TableRow },
    'table-cell': { type: CustomSlateNodeTypes.TableCell },
    insert: { type: CustomSlateNodeTypes.Insert },
    delete: { type: CustomSlateNodeTypes.Delete },
    image: { type: CustomSlateNodeTypes.Image },
    'image-reference-with-display': {
      type: CustomSlateNodeTypes.ImageReferenceWithDisplay,
    },
  },
  creators: {
    text: createText,
  },
});

export const withListNormalization = (editor: Editor): Editor => {
  const { normalizeNode } = editor;

  // eslint-disable-next-line no-param-reassign
  editor.normalizeNode = ([node, path]) => {
    if (SlateElement.isElement(node) && node.type) {
      // Normalize nested list types, do not allow lists in lists (they will break in Latex/PDF gen)
      if (LIST_TYPES.includes(node.type)) {
        for (const [child, childPath] of Node.children(editor, path)) {
          if (
            SlateElement.isElement(child) &&
            child.type &&
            LIST_TYPES.includes(child.type)
          ) {
            // Unwrap the nested list to move its items to the current list level
            Transforms.unwrapNodes(editor, { at: childPath });
          } else if (
            SlateElement.isElement(child) &&
            child.type &&
            !LIST_TYPES.includes(child.type) &&
            child.type !== CustomSlateNodeTypes.ListItem
          ) {
            // Wrap non-list-item children in a list-item if parent is a list
            Transforms.wrapNodes(
              editor,
              {
                type: CustomSlateNodeTypes.ListItem,
                children: [],
              },
              { at: childPath }
            );
          }
        }
      }

      // Remove indentations within list items and lists
      if (
        node.type === CustomSlateNodeTypes.ListItem ||
        LIST_TYPES.includes(node.type)
      ) {
        for (const [child, childPath] of Node.children(editor, path)) {
          if (
            SlateElement.isElement(child) &&
            child.type === CustomSlateNodeTypes.Indentation
          ) {
            // Keep the content of the indentation but remove the indentation itself
            Transforms.unwrapNodes(editor, { at: childPath });
          }
        }
      }
    }

    // Call the original normalizeNode to handle other normalization rules
    normalizeNode([node, path]);
  };

  return editor;
};

// Cleanup value to save -- remove nodes that have failed, loading attrs -- shouldn't exist on saved data.
// They will ideally be removed by effects in relevant components, but if user saves while image is
// uploading, of if they save after an error on image upload has happened, we need to make sure to clean up these nodes
// so they don't pollute. User will have to try again after an error.
export function cleanUpNodesBeforeSave(node: Descendant): Descendant {
  return node.reduce((acc: Descendant[], ac: Descendant) => {
    const { children, loading, error, text, ...acRest } = ac;

    // nodes that that have err or loading attr should never be saved.
    if (loading || error) {
      return acc;
    }

    return [
      ...acc,
      {
        ...acRest,
        ...((text || text === '') && { text }),
        ...(children && {
          children: cleanUpNodesBeforeSave(children),
        }),
      },
    ];
  }, []);
}

export const isBlockActive = (editor: Editor, format: string): boolean => {
  try {
    const [match] = Editor.nodes(editor, {
      match: (n: Node) => (n as SlateElement).type === format,
    });

    return !!match;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const isMarkActive = (editor: Editor, format: string): boolean => {
  try {
    const marks: Record<string, boolean> = Editor.marks(editor) as Record<
      string,
      boolean
    >;
    return marks ? marks[format] === true : false;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const toggleBlock = (editor: Editor, format: string): void => {
  try {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    // TODO think about counting the indentations in the exist content, wrap AOURND the new content insert.
    // right now, this just resets any indentaions essentially.
    Transforms.unwrapNodes(editor, {
      match: (n: Node) =>
        [...LIST_TYPES, CustomSlateNodeTypes.Indentation].includes(
          (n as SlateElement).type as string
        ),
      split: true,
      mode: 'all',
    });

    let type: string;
    if (isActive) {
      type = CustomSlateNodeTypes.Paragraph;
    } else if (isList) {
      type = CustomSlateNodeTypes.ListItem;
    } else {
      type = format;
    }

    Transforms.setNodes(editor, {
      type,
    });

    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  } catch (e) {
    console.error(e);
  }
};

export const toggleMark = (
  editor: Editor,
  format: 'bold' | 'italic' | 'underline'
): void => {
  try {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  } catch (e) {
    console.error(e);
  }
};

export const getTableDimension = (
  editor: Editor,
  type: string
): Record<string, number> => {
  const path = editor.selection
    ? editor.selection.anchor.path.slice(0, 1)[0]
    : 0;
  const { children } = editor;
  const selectedTable: Record<string, number> = children[
    path
  ] as unknown as Record<string, number>;

  if (type === 'both') {
    return { rows: selectedTable.rows, cols: selectedTable.cols };
  }

  return selectedTable;
};

type CreateAndEditTableProps = {
  editor: Editor;
  numOfColumns: number;
  numOfRows: number;
  deleteTable: boolean;
  onBlur: (overrideFocus: boolean) => void;
};

export const createAndEditTable = ({
  editor,
  numOfColumns,
  numOfRows,
  deleteTable = false,
  onBlur,
}: CreateAndEditTableProps): void => {
  const tableIsSelected = isBlockActive(editor, CustomSlateNodeTypes.TableRow);
  const table = tableFactory({ editor, numOfColumns, numOfRows });
  if (deleteTable) {
    try {
      Transforms.removeNodes(editor, {
        match: (n: Node) =>
          (n as SlateElement).type === CustomSlateNodeTypes.Table,
      });
    } catch (e) {
      console.error(e);
    }
    onBlur(true);
  }

  if (!tableIsSelected) {
    // if a table isn't currently selected create a new one
    table.makeNewTable();
  }

  if (tableIsSelected) {
    try {
      // edit currently selected table
      table.setPath();
      table.currentDimensions = getTableDimension(editor, 'both');

      if (table.rowsShouldBeIncreased()) {
        table.setStartIndex();
        table.addRows();
      } else if (table.rowsShouldBeDecreased()) {
        table.removeRows();
      }

      if (table.colsShouldBeIncreased()) {
        table.addColumns();
      } else if (table.colsShouldBeDecreased()) {
        table.removeColumns();
      }
      // once editing is complete, update the tables cols and rows helper properties
      table.updateTableSizeProperties();
      onBlur(true);
    } catch (e) {
      console.error(e);
    }
  }
};

export const ensureSelection = (editor: Editor): void => {
  if (!editor.selection) {
    Transforms.select(editor, {
      anchor: { path: [0, 0], offset: 0 },
      focus: { path: [0, 0], offset: 0 },
    });
  }
};

export const doesNodeContainTextOrInline = (
  editor: Editor,
  node: Node
): boolean => {
  if (node.children) {
    for (const child of node.children) {
      if (doesNodeContainTextOrInline(editor, child)) {
        return true;
      }
    }
  }

  if (
    (Text.isText(node) && node.text.length > 0) ||
    (SlateElement.isElement(node) && editor.isInline(node))
  ) {
    return true;
  }

  return false;
};

export const isInsideEmptyTableCell = (editor: Editor): boolean => {
  const [parentTableCell]: Generator<
    NodeEntry<Node>,
    void,
    undefined
  > = Editor.nodes(editor, {
    match: (n: Node) =>
      (n as SlateElement).type === CustomSlateNodeTypes.TableCell,
  });

  return (
    !!parentTableCell &&
    !doesNodeContainTextOrInline(editor, parentTableCell[0])
  );
};

export const isInsideEmptyListItem = (editor: Editor): boolean => {
  const point = editor?.selection?.focus || {
    path: [0],
    offset: 0,
  };
  const path = editor?.selection?.focus?.path || [0];
  const [emptyListItem]: Generator<
    NodeEntry<Node>,
    void,
    undefined
  > = Editor.nodes(editor, {
    match: (n: Node) => {
      return (
        (n as SlateElement).type === CustomSlateNodeTypes.ListItem &&
        Editor.isStart(editor, point, path)
      );
    },
  });
  return Boolean(emptyListItem);
};

export const isInsideEmptyNode = (
  editor: Editor,
  nodeType: string
): boolean => {
  const point = editor?.selection?.focus || {
    path: [0],
    offset: 0,
  };
  const path = editor?.selection?.focus?.path || [0];
  const [cellType]: Generator<NodeEntry<Node>, void, undefined> = Editor.nodes(
    editor,
    {
      match: (n: Node) =>
        (n as SlateElement).type === nodeType &&
        Editor.isStart(editor, point, path),
    }
  );

  return Boolean(cellType);
};

export const isInsideNode = (editor: Editor, nodeType: string): boolean => {
  const [match]: Generator<NodeEntry<Node>, void, undefined> = Editor.nodes(
    editor,
    {
      match: (n: Node) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === nodeType,
    }
  );

  return !!match;
};

export const isInsideAlertBox = (editor: Editor): boolean =>
  isInsideNode(editor, CustomSlateNodeTypes.CautionBox) ||
  isInsideNode(editor, CustomSlateNodeTypes.WarningBox);

export const isInsideEmptyAlertBox = (editor: Editor): boolean =>
  isInsideEmptyNode(editor, CustomSlateNodeTypes.CautionBox) ||
  isInsideEmptyNode(editor, CustomSlateNodeTypes.WarningBox);

export const sanitiseText = (value: string): string => {
  let text = value.replace(/(\n|\r)/gm, ' ');
  // Replace multiple spaces, then trim to remove leading/trailing spaces
  text = text.replace(/\s+/gm, ' ').trim();
  // Find space-single_char-bracket-space combos; replace the first space with a newline
  text = text.replace(/\s([a-z]{1}\)\s)/gm, '\n$1');
  // replace spaces after NOTE: with newline
  text = text.replace(/((NOTE|NOTES|WARNING|CAUTION)\s?[1-9]?:)/gm, '\n\n$1');
  return text;
};

interface AddNewLineInsideAlertBoxArgs {
  editor: Editor;
  event: React.KeyboardEvent<HTMLDivElement>;
  isExitingBox?: boolean;
}
export const handleKeyStrokesInAlertBox = ({
  editor,
  event,
  isExitingBox,
}: AddNewLineInsideAlertBoxArgs): void => {
  const [alertBox]: Generator<NodeEntry<Node>, void, undefined> = Editor.nodes(
    editor,
    {
      match: (n: Node) =>
        (n as SlateElement).type === CustomSlateNodeTypes.CautionBox ||
        (n as SlateElement).type === CustomSlateNodeTypes.WarningBox,
    }
  );

  if (alertBox) {
    event.preventDefault();

    if (isExitingBox) {
      Transforms.insertNodes(editor, paragraphNode);
    } else {
      Transforms.insertText(editor, '\n\n');
    }
  }
};

export const handleKeyInsideImageRef = (
  editor: Editor,
  event: React.KeyboardEvent<HTMLDivElement>
): void => {
  if (editor.selection) {
    event.preventDefault();

    const { key = '' } = event;
    const imageReferenceNode = Editor.parent(
      editor,
      editor.selection.focus.path,
      {
        edge: 'start',
      }
    );

    const newPath = imageReferenceNode[1].map((p, i) => {
      if (i + 1 === imageReferenceNode[1].length) {
        return p + 1;
      }
      return p;
    });

    Transforms.insertNodes(
      editor,
      { text: key !== 'Enter' ? ` ${key}` : ' ' },
      {
        at: {
          offset: 0,
          path: newPath,
        },
      }
    );

    Transforms.select(editor, {
      anchor: {
        offset: key !== 'Enter' ? 2 : 1,
        path: newPath,
      },
      focus: {
        offset: key !== 'Enter' ? 2 : 1,
        path: newPath,
      },
    });
  }
};

export const handleEnterInsideImageReference = (
  editor: Editor,
  event: React.KeyboardEvent<HTMLDivElement>
): void => {
  const [imageReference]: Generator<
    NodeEntry<Node>,
    void,
    undefined
  > = Editor.nodes(editor, {
    match: (n: Node) =>
      (n as SlateElement).type === CustomSlateNodeTypes.ImageReference,
  });

  if (imageReference) {
    event.preventDefault();
    Transforms.insertNodes(editor, paragraphNode);
  }
};

interface ImageReferenceBackspaceArgs {
  editor: Editor;
}
export const handleDeleteInsideImageReference = ({
  editor,
}: ImageReferenceBackspaceArgs): void => {
  try {
    Transforms.removeNodes(editor, {
      match: (n: Node) =>
        (n as SlateElement).type === CustomSlateNodeTypes.ImageReference,
    });
  } catch (e) {
    console.error(e);
  }
};

export const handleBackspaceInsideAlertBox = (editor: Editor): void => {
  // We want to remove empty alert boxes on backspace/delete
  Transforms.setNodes(editor, paragraphNode);
};

export const handleBackspaceInsideList = (
  editor: Editor,
  event: React.KeyboardEvent<HTMLDivElement>
): void => {
  const numberedListIsActive = isBlockActive(
    editor,
    CustomSlateNodeTypes.NumberedList
  );
  event.preventDefault();
  if (numberedListIsActive) {
    toggleBlock(editor, CustomSlateNodeTypes.NumberedList);
  } else {
    toggleBlock(editor, CustomSlateNodeTypes.BulletedList);
  }
};

export const handleBackspaceInTableCell = (
  editor: Editor,
  event: React.KeyboardEvent<HTMLDivElement>
): void => {
  // we want to prevent cell deletion from tables
  if (editor.selection) {
    const parent = Editor.parent(editor, editor.selection.focus.path, {
      edge: 'start',
    });
    const grandParent: Ancestor = Editor.parent(editor, parent[1])[0];
    if (
      (grandParent as SlateElement).type === CustomSlateNodeTypes.TableCell &&
      grandParent.children.length < 2
    ) {
      event.preventDefault();
    }
  }
};

export function isInsideDeleteOrInsertNode(editor: Editor): boolean {
  const isDiffNode =
    isInsideNode(editor, 'insert') || isInsideNode(editor, 'delete');

  return isDiffNode;
}

export function isInsideInsertNode(editor: Editor): boolean {
  return isInsideNode(editor, 'insert');
}

function setSelection(editor: Editor, path: number[], insert?: boolean): void {
  // Calculate path that points to the position immediately after the table
  const bumpOneTablePath = [...path, 0];

  if (insert) {
    let pathToInsert = bumpOneTablePath;

    // If node does not exist at bumpOneTablePath, try to insert at parent's path
    // If node does not exist at bumpOneTablePath, try to insert at parent's path
    if (!Node.has(editor, bumpOneTablePath)) {
      if (pathToInsert.length > 1) {
        pathToInsert = Path.parent(pathToInsert);
      } else {
        // handle edge case when pathToInsert is already at the root level
        // you might need to adjust this to fit your specific use case
        pathToInsert = [0];
      }
    }

    // Inserts new paragraph line if exiting table and line doesn't exist
    Transforms.insertNodes(
      editor,
      { type: CustomSlateNodeTypes.Paragraph, children: [{ text: '' }] },
      { at: pathToInsert }
    );

    // After inserting a new node, the selection should be placed at the start of this new node
    const targetRange = {
      anchor: Editor.start(editor, pathToInsert),
      focus: Editor.start(editor, pathToInsert),
    };

    // Sets selection to target range
    Transforms.setSelection(editor, targetRange);
  } else {
    // Sets target range to desired table location
    const targetRange = {
      anchor: Editor.start(editor, path),
      focus: Editor.end(editor, path),
    };

    // Sets selection to target range
    Transforms.setSelection(editor, targetRange);
  }
}

// Function handles arrow key presses within tables
export function handleArrowKeysInTableCell(
  editor: Editor,
  keyPressed: string
): void {
  if (!editor.selection) return;
  // Get current table cell
  const [cell] = Editor.nodes(editor, {
    match: (n) => n.type === CustomSlateNodeTypes.TableCell,
    mode: 'lowest',
  });
  if (!cell) return;
  // Gets cell path
  const [, cellPath] = cell;
  // Get parent row
  const [, rowPath] = Editor.parent(editor, cellPath);

  // Get parent table
  const [, tablePath] = Editor.parent(editor, rowPath);

  // Get next and previous cells, rows, and tables
  const nextCell = Editor.next(editor, { at: cellPath });
  const previousCell = Editor.previous(editor, { at: cellPath });

  const nextRow = Editor.next(editor, { at: rowPath });
  const previousRow = Editor.previous(editor, { at: rowPath });

  const nextBlock = Editor.next(editor, { at: tablePath });
  const previousBlock = Editor.previous(editor, { at: tablePath });

  switch (keyPressed) {
    // User wants to go up one row
    case 'ArrowUp':
      // If previous row exists, go to cell directly above in previous row
      if (previousRow) {
        const [, previousRowPath] = previousRow;
        const targetPath = [...previousRowPath, cellPath[cellPath.length - 1]];
        setSelection(editor, targetPath);

        // If previous row doesn't exist, check for previous block (eg. space to move cursor to)
      } else if (previousBlock) {
        const [, previousBlockPath] = previousBlock;
        setSelection(editor, previousBlockPath);
      }
      break;

    // User wants to go down one row
    case 'ArrowDown':
      // If next row exists, go to cell directly below in next row
      if (nextRow) {
        const [, nextRowPath] = nextRow;
        const targetPath = [...nextRowPath, cellPath[cellPath.length - 1]];
        setSelection(editor, targetPath);
        // If next row doesn't exist, check for next block (eg. space to move cursor to)
      } else if (nextBlock) {
        const [, nextBlockPath] = nextBlock;
        setSelection(editor, nextBlockPath);
        // If next block doesn't exist, insert new paragraph line and move cursor to it
      } else {
        const newBlockPath = [
          ...tablePath.slice(0, -1),
          tablePath[tablePath.length - 1] + 1,
        ];
        setSelection(editor, newBlockPath, true);
      }
      break;

    // User wants to go left one cell
    case 'ArrowLeft':
      // If previous cell (in same row) exists, go to it
      if (previousCell) {
        const [, previousCellPath] = previousCell;
        setSelection(editor, previousCellPath);
        // If previous cell (in same row) doesn't exist, check for previous row
      } else if (previousRow) {
        const [previousRowNode, previousRowPath] = previousRow;
        // Set target path to last cell in previous row
        const targetPath = [
          ...previousRowPath,
          (previousRowNode as Node).children.length - 1,
        ];
        setSelection(editor, targetPath);
        // If previous row doesn't exist, check for previous block (eg. space to move cursor to)
      } else if (previousBlock) {
        const [, previousBlockPath] = previousBlock;
        setSelection(editor, previousBlockPath);
      }
      break;

    // User wants to go right one cell
    case 'ArrowRight':
      // If next cell (in same row) exists, go to it
      if (nextCell) {
        const [, nextCellPath] = nextCell;
        setSelection(editor, nextCellPath);
        // If next cell (in same row) doesn't exist, check for next row
      } else if (nextRow) {
        const [, nextRowPath] = nextRow;
        // Set target path to first cell in next row
        const targetPath = [...nextRowPath, 0];
        setSelection(editor, targetPath);
        // If next row doesn't exist, check for next block (eg. space to move cursor to)
      } else if (nextBlock) {
        const [, nextBlockPath] = nextBlock;
        setSelection(editor, nextBlockPath);
        // If next block doesn't exist, insert new paragraph line and move cursor to it
      } else {
        const newBlockPath = [
          ...tablePath.slice(0, -1),
          tablePath[tablePath.length - 1] + 1,
        ];
        setSelection(editor, newBlockPath, true);
      }
      break;
    default:
      break;
  }
}

import React, { useEffect, useRef, useState } from 'react';
import type { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import iconExpandAccordion from '../../assets/icon-expand-accordion.svg';
import iconCollapseAccordion from '../../assets/icon-collapse-accordion.svg';

const Wrapper = styled.div<{ $dash: boolean }>`
  ${({ $dash }): string =>
    $dash ? 'box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41)' : ''};
`;

const DASHCSS = css<{ $isExpanded: boolean }>`
  display: flex;
  align-items: center;
  padding: 6px 20px;
  border-radius: 2px;
  border: solid 2px #fff;
  background-color: #fcfdff;
  ${({ $isExpanded }): string =>
    $isExpanded
      ? 'border-bottom-left-radius: 0px; border-bottom-right-radius: 0px; border-bottom: none'
      : ''};
  min-height: 40px;
  cursor: pointer;
  overflow-x: hidden;
  overflow-y: visible;
  &:focus {
    outline: none;
    border: solid 2px ${({ theme }): string => theme.colors.brandBlue};
  }
  &:active {
    border: solid 2px ${({ theme }): string => theme.colors.fog};
  }
`;

const MELCSS = css<{ $isExpanded: boolean }>`
  display: flex;
  width: 100%;
  border: solid 1px ${({ theme }): string => theme.colors.fog};
  border-radius: 4px;
  ${({ $isExpanded }): string =>
    $isExpanded
      ? 'border-bottom-left-radius: 0px; border-bottom-right-radius:0px'
      : ''};
  min-height: 40px;
  cursor: pointer;
  overflow-x: hidden;
  overflow-y: visible;
  &:focus {
    outline: none;
    border: solid 1px ${({ theme }): string => theme.colors.brandBlue};
  }
  &:active {
    border: solid 1px ${({ theme }): string => theme.colors.fog};
  }
`;

const HeaderBox = styled.div<{ $isExpanded: boolean; $dash: boolean }>`
  ${({ $dash }) => ($dash ? DASHCSS : MELCSS)}
`;

const HeaderContent = styled.div`
  width: 100%;
  display: flex;
`;

const HeaderContentWrapper = styled.div`
  display: flex;
  width: calc(100%);
`;

const ExpansionWrapper = styled.div`
  position: relative;
  height: inherit;
`;

const ExpansionIcon = styled.img<{ $dash: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${({ $dash }): string => ($dash ? '0' : 'calc(100% - 20px)')};
  right: ${({ $dash }): string => ($dash ? '0' : 'unset')};
  margin: auto;
`;

const ContentAnimationWrapper = styled(AnimatePresence)`
  ::-webkit-scrollbar {
    display: none;
  }
`;

const MELCONTENTCSS = css`
  height: 400px;
  width: 100%;
  border: solid 1px ${({ theme }): string => theme.colors.fog};
  border-top: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const DASHCONTENTCSS = css`
  height: 400px;
  width: 100%;
  border: solid 2px ${({ theme }): string => theme.colors.white};
  background-color: #fcfdff;
  border-top: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ContentWrapper = styled(motion.section)<{
  $dash: boolean;
}>`
  ${({ $dash }) => ($dash ? DASHCONTENTCSS : MELCONTENTCSS)}
`;

const Content = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
`;

export type AccordionProps = {
  headerContent: string | ReactNode;
  children?: ReactNode | ReactNode[];
  isExpanded?: boolean;
  dash?: boolean;
  add?: boolean;
  onExpansionChange?: React.Dispatch<React.SetStateAction<boolean>>;
  onLayoutAnimationComplete?: () => void;
  'data-testid'?: string;
};

export const Accordion: React.FC<AccordionProps> = ({
  children,
  headerContent,
  isExpanded: contentIsExpanded = false,
  dash = false,
  add = false,
  onExpansionChange = undefined,
  onLayoutAnimationComplete,
  'data-testid': dataTestId = 'Accordion',
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(contentIsExpanded);
  const [initialAdd, setInitialAdd] = useState<boolean>(add);
  useEffect(() => {
    setIsExpanded(contentIsExpanded);
  }, [contentIsExpanded]);
  useEffect(() => {
    if (onExpansionChange) {
      onExpansionChange(isExpanded);
    }
  }, [isExpanded, onExpansionChange]);
  return (
    <Wrapper $dash={dash} data-testid={dataTestId}>
      <HeaderBox
        onClick={() => {
          setIsExpanded(!isExpanded);
          setInitialAdd(false);
          if (headerRef && headerRef.current) {
            headerRef.current.blur();
          }
        }}
        $dash={dash}
        $isExpanded={isExpanded}
        data-testid={`${dataTestId}_HeaderBox`}
        tabIndex={0}
        ref={headerRef}
        onKeyDown={(event) => {
          if (
            !isExpanded &&
            (event.key === 'ArrowDown' || event.key === 'Enter')
          ) {
            setIsExpanded(!isExpanded);
            if (headerRef && headerRef.current) {
              headerRef.current.blur();
            }
          } else if (
            isExpanded &&
            (event.key === 'ArrowUp' || event.key === 'Enter')
          ) {
            setIsExpanded(!isExpanded);
          }
        }}
      >
        <HeaderContentWrapper
          data-testid={`${dataTestId}_HeaderContentWrapper`}
        >
          <HeaderContent data-testid={`${dataTestId}_HeaderContent`}>
            {headerContent}
          </HeaderContent>
          <ExpansionWrapper data-testid={`${dataTestId}_ExpansionWrapper`}>
            <ExpansionIcon
              src={isExpanded ? iconCollapseAccordion : iconExpandAccordion}
              $dash={dash}
            />
          </ExpansionWrapper>
        </HeaderContentWrapper>
      </HeaderBox>
      <ContentAnimationWrapper>
        {isExpanded && (
          <ContentWrapper
            initial={{
              opacity: initialAdd ? 1 : 0,
              height: initialAdd ? 'auto' : '0',
            }}
            animate={{
              height: 'auto',
              overflow: 'auto',
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              height: '0',
              opacity: 0,
              transition: {
                duration: 0.3,
              },
            }}
            onLayoutAnimationComplete={onLayoutAnimationComplete}
            $dash={dash}
          >
            <Content data-testid={`${dataTestId}_Content`}>{children}</Content>
          </ContentWrapper>
        )}
      </ContentAnimationWrapper>
    </Wrapper>
  );
};

export default Accordion;

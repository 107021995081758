import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import styled from 'styled-components';
import Dots from './dots.json';

const Wrapper = styled.div`
  background: transparent;
`;

const LottieWrapper = styled.div`
  display: flex;
`;

const Animation = styled(Player)`
  height: 30px;
  width: 30px;
`;

export const DotsAnimation: React.FC = () => {
  return (
    <Wrapper data-testid="DotsAnimation">
      <LottieWrapper>
        <Animation src={Dots} autoplay loop />
      </LottieWrapper>
    </Wrapper>
  );
};

export default DotsAnimation;

import React from 'react';
import { BasePoint, Editor, Transforms } from 'slate';
import { MenuItem } from '../../Select/selectStyles';
import type { LinkMenuItem } from './LinkMenu';

export const LinkItem = ({
  link,
  editor,
  focus,
  menuToggle,
}: {
  link: LinkMenuItem;
  editor: Editor;
  focus: BasePoint | undefined;
  menuToggle(fn: (menuState: boolean) => boolean): void;
}): JSX.Element => {
  return (
    <li>
      <MenuItem
        data-testid={`AddLinkItemButton-${link?.linkId}`}
        onClick={() => {
          Transforms.insertNodes(
            editor,
            [
              { text: '' },
              {
                type: 'link',
                linkId: link.linkId,
                children: [
                  {
                    text: '',
                  },
                ],
              },
              { text: '' },
            ],
            {
              at: focus,
            }
          );
          menuToggle((menuState) => !menuState);
        }}
      >
        {link.text}
      </MenuItem>
    </li>
  );
};

import { useSelect } from 'downshift';
import React, { useEffect, useState } from 'react';
import dropdownIconSvg from '../../assets/dropdown-icon.svg';
import {
  DropdownIcon,
  DropdownWrapper,
  MenuItem,
  MenuOuterWrapper,
  MenuWrapper,
  SelectBox,
  SelectedLabel,
} from './selectStyles';

export type MenuItemDefinition = {
  value: string;
  label: string;
};

export type BasicSelectProps = {
  items: MenuItemDefinition[];
  onSelect: (item: MenuItemDefinition | null | undefined) => void | null;
  placeholder?: string;
  value?: MenuItemDefinition;
  'data-testid'?: string;
  controlled?: boolean;
};

export const BasicSelect = ({
  items,
  onSelect,
  placeholder = 'Please select...',
  value: initialValue,
  'data-testid': dataTestId = 'BasicSelect',
  controlled = false,
}: BasicSelectProps): JSX.Element => {
  const [value, setValue] = useState<MenuItemDefinition | undefined | null>(
    initialValue
  );

  const {
    isOpen,
    highlightedIndex,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items,
    onSelectedItemChange: (changes) => {
      setValue(changes.selectedItem);
      onSelect(changes.selectedItem);
    },
    initialSelectedItem: value,
  });

  useEffect(() => {
    if (initialValue?.value !== value?.value && controlled) {
      setValue(initialValue);
    }
  }, [controlled, initialValue, setValue, value]);

  return (
    <>
      <SelectBox
        {...getToggleButtonProps()}
        tabIndex={0}
        itemIsSelected={Boolean(value)}
        menuIsOpen={isOpen}
        data-testid={`${dataTestId}_SelectBox`}
      >
        <SelectedLabel data-testid={`${dataTestId}_SelectLabel`}>
          {value ? value.label : placeholder}
        </SelectedLabel>
        <DropdownWrapper
          menuIsOpen={isOpen}
          data-testid={`${dataTestId}_DropdownWrapper`}
        >
          <DropdownIcon src={dropdownIconSvg} />
        </DropdownWrapper>
      </SelectBox>
      <MenuOuterWrapper>
        <MenuWrapper
          {...getMenuProps()}
          isOpen={isOpen}
          data-testid={`${dataTestId}_MenuWrapper`}
        >
          {isOpen &&
            items.map((item, index) => (
              <MenuItem
                key={`${item.value}`}
                {...getItemProps({ item, index })}
                isSelected={value?.value === item.value}
                isHighlighted={highlightedIndex === index}
                data-testid={`${dataTestId}_MenuItem-${index}`}
              >
                {item.label}
              </MenuItem>
            ))}
        </MenuWrapper>
      </MenuOuterWrapper>
    </>
  );
};

export default BasicSelect;

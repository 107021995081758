import React, { useCallback, useState, memo } from 'react';
import type { Editor } from 'slate';
import { useSlate } from 'slate-react';
import { useRTEContext } from './RTEContext';
import { ToolButton, ToolButtonIcon, ToolButtonWrapper } from './ToolBarStyles';
import ToolTip from './ToolTip';
import { ensureSelection, isMarkActive, toggleMark } from './utils/utils';

interface MarkButtonProps {
  format: 'bold' | 'italic' | 'underline';
  icon: string;
}

export default memo(({ format, icon }: MarkButtonProps): JSX.Element => {
  const editor: Editor = useSlate();
  const [tooltipIsVisible, setTooltipIsVisible] = useState<boolean>(false);

  const handleToggleMark = useCallback(
    (event) => {
      event.preventDefault();
      ensureSelection(editor);
      toggleMark(editor, format);
    },
    [editor, format]
  );

  const { state: RTEState } = useRTEContext();
  const { userDisabled: editDisabled } = RTEState;

  return (
    <ToolButtonWrapper>
      <ToolTip text={format} $isVisible={tooltipIsVisible}>
        <ToolButton
          $isDisabled={editDisabled}
          disabled={editDisabled}
          onClick={handleToggleMark}
          onMouseEnter={() => {
            setTooltipIsVisible(true);
          }}
          onMouseLeave={() => {
            setTooltipIsVisible(false);
          }}
        >
          <ToolButtonIcon
            $isDisabled={editDisabled}
            $isActive={isMarkActive(editor, format)}
            src={icon}
          />
        </ToolButton>
      </ToolTip>
    </ToolButtonWrapper>
  );
});

import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ComponentWrapper = styled.div``;

export const MoreOptionsButton = styled.button<{ isMenuOpen: boolean }>`
  border: none;
  height: 24px;
  width: 24px;
  border-radius: 50px;
  background-color: ${({ isMenuOpen, theme }) =>
    isMenuOpen ? theme.colors.brandBlue15Alpha : 'transparent;'};
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.brandBlue05Alpha};
  }
  &:focus {
    outline: none;
    border: solid 1px ${({ theme }) => theme.colors.brandBlue};
  }
`;

export const MoreOptionsIcon = styled.img`
  height: 12px;
  padding-top: 3px;
`;

export const Menu = styled(motion.div)<{
  top?: number;
  left?: number;
  right?: number;
  isMoreOnTheRight?: boolean;
  hasRoundedStyle?: boolean;
  tableVariant: boolean;
}>`
  border-radius: ${({ hasRoundedStyle }) => (hasRoundedStyle ? '4px' : '0')};
  position: absolute;
  z-index: 999;
  display: grid;
  margin-top: 4px;
  line-height: normal;
  ${({ top, isMoreOnTheRight, left, right }) => {
    if (isMoreOnTheRight) {
      return `right: calc(100vw - ${right}px); top: ${top}px;`;
    }
    return `left: ${left}px; top: ${top}px;`;
  }};
  background: white;
  box-shadow: ${({ hasRoundedStyle, theme }) =>
    hasRoundedStyle
      ? `0 0 10px 5px ${theme.colors.frostedSteel}`
      : `0 0 10px 0 ${theme.colors.black10Alpha}`};
  width: min-content;
`;

export const MenuItemButton = styled.button<{
  destructive: boolean;
  isActive: boolean;
  isJsxLabel?: boolean;
  hasRoundedStyle?: boolean;
  tableVariant: boolean;
}>`
  background-color: white;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  height: ${({ isJsxLabel }) => (isJsxLabel ? 'auto' : 'auto')};
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
  padding-left: ${({ isJsxLabel, tableVariant }) => {
    if (isJsxLabel) return '0px;';

    return tableVariant ? '10px;' : '16px;';
  }};
  padding-right: ${({ isJsxLabel, tableVariant }) => {
    if (isJsxLabel) return '0px';

    return tableVariant ? '10px' : '16px';
  }};
  padding-top: ${({ isJsxLabel, tableVariant }) => {
    if (isJsxLabel) return '0px;';
    return tableVariant ? '10px;' : '8px;';
  }};
  padding-bottom: ${({ isJsxLabel, tableVariant }) => {
    if (isJsxLabel) return '0px;';
    return tableVariant ? '28px;' : '8px;';
  }};
  ${({ destructive, isActive, theme }): string => {
    if (!destructive) {
      return `color: ${
        isActive ? theme.colors.brandBlue : theme.colors.black80Alpha
      }; border: ${
        isActive
          ? `solid 1px ${theme.colors.brandBlue}`
          : 'solid 1px transparent'
      };`;
    }
    if (destructive) {
      return `color: ${theme.colors.red}; border: ${
        isActive ? `solid 1px ${theme.colors.red}` : 'solid 1px transparent'
      };`;
    }
    return '';
  }};
  &:first-of-type {
    border-top-left-radius: ${({ hasRoundedStyle }) =>
      hasRoundedStyle ? '4px' : '0'};
    border-top-right-radius: ${({ hasRoundedStyle }) =>
      hasRoundedStyle ? '4px' : '0'};
    padding-top: ${({ isJsxLabel, tableVariant }) => {
      if (isJsxLabel) return '0px;';
      return tableVariant ? '10px;' : '16px;';
    }};
  }
  &:last-of-type {
    border-bottom-left-radius: ${({ hasRoundedStyle }) =>
      hasRoundedStyle ? '4px' : '0'};
    border-bottom-right-radius: ${({ hasRoundedStyle }) =>
      hasRoundedStyle ? '4px' : '0'};
    padding-bottom: ${({ isJsxLabel, tableVariant }) => {
      if (isJsxLabel) return '0px;';
      return tableVariant ? '28px;' : '16px;';
    }};
  }
  &:hover {
    cursor: pointer;
    border: solid 1px transparent;
    ${({ destructive, hasRoundedStyle, tableVariant, theme }): string => {
      if (hasRoundedStyle || tableVariant) {
        return `background-color: ${
          destructive ? theme.colors.red5Alpha : theme.colors.brandBlue05Alpha
        };`;
      }
      return '';
    }};
  }
  &:focus {
    outline: none;
    ${({ destructive, hasRoundedStyle, tableVariant, theme }): string => {
      if (tableVariant || hasRoundedStyle) {
        return `background-color: ${
          destructive ? theme.colors.red10Alpha : theme.colors.brandBlue10Alpha
        };`;
      }
      return `color: ${theme.colors.brandBlue80Alpha};`;
    }};
  }
`;

export const MenuItemLabel = styled.span`
  white-space: nowrap;
`;

export const MenuItemIcon = styled.img`
  vertical-align: bottom;
  height: 16px;
  margin-right: 8px;
`;

export const PortalMenuWrapper = styled(motion.div)`
  position: absolute;
  z-index: 1;
`;

import React, { useCallback, useState } from 'react';
import { useSlate } from 'slate-react';
import { Editor, Transforms } from 'slate';

import styled from 'styled-components';
import LinkIcon from '../../../assets/RichTeIcons/icon-link.svg';

import {
  ToolButton,
  ToolButtonIcon,
  ToolButtonWrapper,
} from '../ToolBarStyles';

import ToolTip from '../ToolTip';
import { useRTEContext } from '../RTEContext';
import { LinkPopUpMenu } from './LinkPopUpMenu';

export enum LinkType {
  // linkId based
  Internal = 'internal',

  // needs hrefs, etc, October 31, 2023 -- not implemented yet
  External = 'external',
}

export type LinkMenuItem = {
  text: string;
  linkId: string;
  type: LinkType;
  // TODO October 31, 2023: considering extending, adding optional properites for external url based links,
  // link types, etc..
};

interface LinkMenuProps {
  showMenu: boolean;
  menuToggle(fn: (menuState: boolean) => boolean): void;
  toggleOtherMenus: {
    siblingColourMenu: (arg0: boolean) => void;
    tableMenu: (arg0: boolean) => void;
  };
  otherMenuStates: boolean[];
  links: LinkMenuItem[];
}

export const TextColorPicker = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LinkMenu = ({
  showMenu,
  menuToggle,
  toggleOtherMenus: { siblingColourMenu, tableMenu },
  otherMenuStates,
  links,
}: LinkMenuProps): JSX.Element => {
  const editor: Editor = useSlate();
  const [tooltipIsVisible, setTooltipIsVisible] = useState<boolean>(false);
  const { state: RTEState } = useRTEContext();
  const { userDisabled: editDisabled } = RTEState;

  const handleMenuToggle = useCallback(() => {
    if (otherMenuStates.includes(true)) {
      siblingColourMenu(false);
      tableMenu(false);
    }
    if (!editor.selection) {
      Transforms.select(editor, {
        anchor: { path: [0, 0], offset: 0 },
        focus: { path: [0, 0], offset: 0 },
      });
    }
    menuToggle((menuState) => !menuState);
  }, [otherMenuStates, editor, siblingColourMenu, tableMenu, menuToggle]);

  return (
    <>
      <ToolButtonWrapper>
        <ToolTip text="Link" $isVisible={tooltipIsVisible && !showMenu}>
          <ToolButton
            data-testid="AddLinkButton"
            disabled={editDisabled}
            type="button"
            onMouseDown={handleMenuToggle}
            onMouseEnter={() => {
              setTooltipIsVisible(true);
            }}
            onMouseLeave={() => {
              setTooltipIsVisible(false);
            }}
            $isDisabled={editDisabled}
          >
            <ToolButtonIcon
              $isDisabled={editDisabled}
              $isActive={false}
              src={LinkIcon}
            />
          </ToolButton>
        </ToolTip>
      </ToolButtonWrapper>

      {showMenu && (
        <LinkPopUpMenu
          editor={editor}
          links={links}
          menuToggle={menuToggle}
          toggleOtherMenus={{ siblingColourMenu, tableMenu }}
          otherMenuStates={otherMenuStates}
        />
      )}
    </>
  );
};

import styled from 'styled-components';

export const OptionButtonsWrapper = styled.fieldset`
  border: 0;
  padding: 0;
  display: flex;
`;

export const VisibleOption = styled.span<{ label: string }>`
  display: inline-block;
  position: relative;
  box-shadow: 0 0 0 1px var(--black-10);
  background: var(--black-2);
  color: var(--black-70);
  font-size: 14px;
  padding: 8px 15px;
  left: 0;
  border-radius: 2px;
  vertical-align: middle;
  transition: all 200ms;
  &:before {
    align-items: center;
    content: '${({ label }): string => label}';
    display: flex;
    justify-content: center;
    height: 100%;
  }
`;

export const OptionWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-right: 5px;
  &:focus {
    ${VisibleOption} {
      box-shadow: 0 0 0 1px var(--jordy);
      opacity: 1;
      transition: all 200ms;
    }
  }
  &.checked {
    ${VisibleOption} {
      box-shadow: 0 0 0 1px var(--brand-blue-30);
      background: var(--brand-blue-10);
      color: var(--brand-blue);
      opacity: 1;
      transition: all 200ms;
    }
  }
  &:hover {
    ${VisibleOption} {
      background: var(--brand-blue-5);
      box-shadow: 0 0 0 1px var(--brand-blue-30);
      color: var(--brand-blue);
      opacity: 1;
      transition: all 200ms;
    }
  }
`;

export const Input = styled.input`
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
`;
